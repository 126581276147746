import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { KeyValuePair } from '../generalInterfaces';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ChangeCaseTypeService {
	constructor(private http: EupHttpHandler, private eupRoutesService: EupRoutesService) {}

	getChangeCaseTypeOptions(orderId: number): Observable<KeyValuePair[]> {
		const params = new HttpParams().set('orderId', orderId.toString());
		return this.http.get(this.eupRoutesService.orders.changeCaseTypeOptionsUrl, { params: params });
	}

	changeCaseTypeForOrder(orderId: number, toCaseTypeId: number) {
		/*ItemId is CaseTypeId at this point, will be replaced in OrderService to real ItemId*/
		const params = new HttpParams().set('orderId', orderId.toString()).set('toItemId', toCaseTypeId.toString());
		return this.http.get(this.eupRoutesService.orders.changeCaseTypeForOrderUrl, { params: params });
	}
}
