<div *ngIf="(files$ | async)?.length > 0">
    <div class="component-container" [ngClass]="{'pressed': listFlag}" (click)="onToggle()">
        <div class="download-icon-container">
            <svg class="svg-downloadIcon34 svg-downloadIcon34-dims"></svg>
        </div>
        <div class="download-text-container" *ngIf="(files$ | async)?.length > 1">
            {{'DownloadNotification.MessageX' | translate:{'count': (files$ | async)?.length} }}
        </div>
        <div class="download-text-container" *ngIf="(files$ | async)?.length === 1">
            {{'DownloadNotification.Message' | translate }}
        </div>
        <div class="download-progressbar-container">
            <div class="progressbar" [style.width]="calcProgress() + '%'"></div>
        </div>
    </div>
    <eup-export-downloads-progress-list
        [hidden]="!listFlag"
        (close)="onClose()">
    </eup-export-downloads-progress-list>
</div>
