import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'eupDefaultText'
})
export class EupDefaultText implements PipeTransform {
	transform(value: any, defaultTest: string): any {
		if (value) {
			return value;
		} else {
			return defaultTest;
		}
	}
}
