export default {
	Avenir: {
		normal: 'Avenir-Medium.ttf',
		bold: 'Avenir-Heavy.ttf',
		italics: 'Avenir-Medium.ttf',
		bolditalics: 'Avenir-Heavy.ttf',
	},
	Nunito: {
		normal: 'Nunito-Regular.ttf',
		bold: 'Nunito-Bold.ttf',
		italics: 'Nunito-Italic.ttf',
		bolditalics: 'Nunito-BoldItalic.ttf',
	},
	YaHei: {
		normal: 'YaHei-Regular.ttf',
		bold: 'YaHei-Bold.ttf',
		italics: 'YaHei-Regular.ttf',
		bolditalics: 'YaHei-Bold.ttf',
	},
	YuGothic: {
		normal: 'YuGothic-Regular.ttf',
		bold: 'YuGothic-Bold.ttf',
		italics: 'YuGothic-Regular.ttf',
		bolditalics: 'YuGothic-Bold.ttf',
	},
	Malgun: {
		normal: 'Malgun-Regular.ttf',
		bold: 'Malgun-Bold.ttf',
		italics: 'Malgun-Regular.ttf',
		bolditalics: 'Malgun-Bold.ttf',
	},
	Tahoma: {
		normal: 'Tahoma-Regular.ttf',
		bold: 'Tahoma-Bold.ttf',
		italics: 'Tahoma-Regular.ttf',
		bolditalics: 'Tahoma-Bold.ttf',
	}
};