export const initContextMock = {
  session: {
    sessionId: '11608d87-d264-4992-9122-b33c7d704583',
    sessionType: 'web',
  },
  user: {
    loggedInUserId: 10748400,
    roleType: 0,
  },
  device: {
    scannerBaseUnitType: 'ElementPanelPC',
    systemType: 'Evx',
    scannerVersion: 'HBB3.1.15.17',
    scannerSerial: 'YUCJCVSW01A011',
    scannerResGuid: '048d3807-f76c-4a16-b861-12419a83d8a1',
    wandSerial: 'WSN244646702960',
  },
  bizCtx: {
    contactId: 10748400,
    companyId: 91805,
    region: 'US',
    patientId: 'ba5799b6-7e23-4359-9d89-90b22aa3ddea',
    orderId: 12345,
    rxId: 'e6621a61-f66f-4087-8d9e-b96568baac4a',
  },
  UI: {
    dateFormat: 'DD/MM/YYYY',
    language: 'en-US',
  },
  security: {
    accessToken:
      'eyJ4NXQiOiJOMkl5TnpJeU9UY3daV05sTUdGbFlURmtaVEUzT1RsaE16SmtOemhpWVdaaE9UUmpOakJtWXciLCJraWQiOiJOMlZtWlRFM1kyVXpNbVk0T0dJNFpqWmlPRGRsWWpZME9ETTNPR1JrTkRreE9UZzRZelZpTldNM01USTRNV1F5Tm1VMllXSTNaRGcxWldSbE9ETTVZUV9SUzI1NiIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJNb3Jkb2NAZ21haWwuY29tIiwiYXV0IjoiQVBQTElDQVRJT05fVVNFUiIsInJvbGUiOlsiR3Vlc3QiLCJPcnRob2RvbnRpc3QiLCJFeHRlcm5hbCBVc2VyIDEiLCJEZW50aXN0IiwiSW50ZXJuYWxcL2V2ZXJ5b25lIl0sImlzcyI6Imh0dHBzOlwvXC9pZGVudGl0eS1jdXMuc3FhMS5hbGlnbnRlY2guY29tIiwiZXh0ZXJuYWxfaWQiOiI0MjY2OSIsInVzZXJfZGF0YSI6IkNvbnRhY3RJRDpTOjA6NToiLCJhdWQiOlsidVlEeXN1NGcxSXBVYVgyT1BIVjRCMTJfSEJzYSIsImh0dHBzOlwvXC9pZGVudGl0eS1jdXMuc3FhMS5hbGlnbnRlY2guY29tXC9vYXV0aDJcL3Rva2VuIiwiQXBwbGljYXRpb25OYW1lOml0ZXJvX3dlYl9hdXRoX21zX3VzZXJ-aXRlcm9fd2ViX2F1dGhfbXMiXSwibmJmIjoxNzEwNDk2MDU1LCJhenAiOiJ1WUR5c3U0ZzFJcFVhWDJPUEhWNEIxMl9IQnNhIiwic2NvcGUiOiJkZWZhdWx0IG9wZW5pZCIsImV4cCI6MTcxMDQ5ODQ1NSwiaWF0IjoxNzEwNDk2MDU1LCJqdGkiOiIxNGNiNDk2Zi03MWFlLTQ4NDItOWU3ZS04NTc5NTEwOWRhZGUifQ.coK5GH3V6B6f4PMZ5eKeAwgaPQIDZA8zGBiEau_W5sCi_58Cb1wVEaf8Ngt2DKtE3HY5OYCTuUmL8S6oK1PPF1xmSbrlSAjCoyt0KMwGfhHUasXBF1Yivp6yuyAME6YytFb0wY4XcPP_6JugII_wMGyPxrIwvbv_BCeQKh1jIzWbckLY-4Xh5wThe2JuRjZ_ar_HkySPeo9D9dLilhcLrn94eQIdF0h7AhtU42npNeTxNQGiy6KodZD8NirQla3UffoazzjOIHuyiTnHKL22Vg7MZq0ejyA3iAK7aUIWUEfwLdeIxzVZaB-_zIkVzAHuwKMjTK0dMjK_f6iJhiFrJQ',
  },
  platformServices: [
    {
      name: 'SessionManagementRefresh',
      url: 'https://sessionmng-us-west-1-ppr.iterocloud.com/api/v1/auth/session/refresh',
    },
  ],
};
