import { Component, Output, EventEmitter } from '@angular/core';
import { DownloadNotificationService } from './downloadNotification.service';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, tap, map } from 'rxjs/operators';
import { FileInfo } from './fileInfo.model';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggle } from '@shared/enums';
import { BaseDestroyable } from '@core/base-destroyable';
import { FeatureToggleSettings } from 'app/featuresToggleSettings/featuresToggleSettings.model';

@Component({
	selector: 'eup-download-notification',
	templateUrl: './downloadNotification.component.html',
	styleUrls: [ './downloadNotification.component.scss' ]
})
export class DownloadNotificationComponent extends BaseDestroyable {
	files$: BehaviorSubject<FileInfo[]>;
	listFlag = false;
	@Output() open: EventEmitter<any> = new EventEmitter();

	constructor(private downloadNotificationService: DownloadNotificationService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService) {
			super();
			this.featuresToggleSettingsService.getAll().pipe(
				takeUntil(this.componentAlive$),
				map((res: FeatureToggleSettings[]) => res.find(f => f.id === FeatureToggle.SubscribeExportFilesOnlyOnce)?.isActive === true),
				tap(subscribeExportFilesOnlyOnce => {
					if (subscribeExportFilesOnlyOnce) {
						this.files$ = downloadNotificationService.getFiles();
					} else {
						this.files$ = downloadNotificationService.getObservable();
					}
				})).subscribe();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	calcProgress(): number {
		const files = this.files$.value;
		const length = files.length;
		const arr = files.map((file) => file.progress);
		const sum = arr.reduce((previous, current) => (current += previous), 0);
		return sum / length;
	}

	onToggle() {
		this.listFlag = !this.listFlag;
		if (this.listFlag === true) {
			this.open.emit(null);
		}
	}

	onClose() {
		this.listFlag = false;
	}
}
