import { RoleTypeEnum, RxNote, UserRoleMapper } from './notes.model';
import { LegacyRxNote, LegacyRxNoteRole } from './notes.legacy';

class NotesParser {
	static readonly NOTE_DELIMITER = '\r\n---------------------------------\r\n';

	static isLegacyNote(text: string): boolean {
		const noteRegex = /created_id::/gm;
		const isLegacy = !noteRegex.exec(text);

		return isLegacy;
	}

	createNote(params: { content: string; createdById?: string; createdByName?: string; dateCreated?: string; role?: string }): RxNote {
		const { content = null, createdById = null, createdByName = null, dateCreated = null, role = null } = params;

		const note = {
			content,
			createdById: createdById && +createdById,
			createdByName,
			dateCreated: dateCreated && this.getDateObjFromString(dateCreated).toISOString(),
			role: role && this.getRoleName(role),
		};

		return note;
	}

	createLegacyNote(params: {
		canEdit?: boolean;
		content: string;
		dateCreated?: Date | string;
		createdByName?: string;
		role?: LegacyRxNoteRole;
	}): LegacyRxNote {
		const { dateCreated, content, role = null, createdByName = null, canEdit = false } = params;
		const legacyNote = new LegacyRxNote(dateCreated, content, role, createdByName, canEdit);

		return legacyNote;
	}

	/**
	 * Parses raw text into notes.
	 * @param text Raw text
	 * @returns Array of notes
	 */
	parse(text: string): RxNote[] {
		if (!text) {
			return [];
		}

		const rawNotes = text.split(NotesParser.NOTE_DELIMITER);

		const notes = rawNotes.map((rawNote) => {
			const isLegacy = NotesParser.isLegacyNote(rawNote);
			const noteTokens = isLegacy ? this.parseLegacyNoteTokens(rawNote) : this.parseNoteTokens(rawNote);

			const note = noteTokens
				? this.createNote({
						content: noteTokens.content,
						createdById: noteTokens.createdById,
						createdByName: noteTokens.createdByName,
						dateCreated: noteTokens.dateCreated,
						role: noteTokens.role,
				  })
				: this.createNote({ content: rawNote });

			return note;
		});

		return notes;
	}

	/**
	 * Parses raw text into legacy notes.
	 * @param text Raw text
	 * @returns Array of legacy notes
	 */
	parseToLegacy(text: string): LegacyRxNote[] {
		if (!text) {
			return [];
		}

		const rawNotes = text.split(NotesParser.NOTE_DELIMITER);

		const legacyNotes = rawNotes.map((rawNote) => {
			const isLegacy = NotesParser.isLegacyNote(rawNote);
			const noteTokens = isLegacy ? this.parseLegacyNoteTokens(rawNote) : this.parseNoteTokens(rawNote);

			const legacyNote = noteTokens
				? this.createLegacyNote({
						content: noteTokens.content,
						createdByName: noteTokens.createdByName,
						dateCreated: noteTokens.dateCreated,
						role: noteTokens.role,
				  })
				: this.createLegacyNote({ content: rawNote });

			return legacyNote;
		});

		return legacyNotes;
	}

	/**
	 * Parses raw text into human-readable notes.
	 * @param text Raw text
	 * @returns Human-readable notes.
	 */
	parseToFormattedNotes(text: string): RxNote[] {
		const notes = this.parse(text);
		const formattedNotes = notes.map((note) => ({ ...note, dateCreated: this.fromISODateString(note.dateCreated) }));

		return formattedNotes;
	}

	/**
	 * Maps notes to legacy notes.
	 * @param notes An array of notes
	 * @param canEdit If `true`, notes can be edited
	 * @returns An array of legacy notes
	 */
	notesToLegacy(notes: RxNote[], canEdit = false): LegacyRxNote[] {
		const legacyNotes = notes.map((note) =>
			this.createLegacyNote({
				canEdit,
				content: note.content,
				dateCreated: note.dateCreated,
				createdByName: note.createdByName,
				role: note.role,
			})
		);

		return legacyNotes;
	}

	serializeNotes(notes: RxNote[]): string {
		if (!notes.length) {
			return '';
		}

		return notes
			.map((note: RxNote) => {
				if (note.role === null && note.createdByName === null && note.dateCreated === null) {
					// handling doctor old format
					return note.content;
				}

				return `role:: ${this.getRoleAsChar(note.role)}% created_id:: ${note.createdById}% created_name:: ${
					note.createdByName
				}% created_date:: ${this.fromISODateString(note.dateCreated)}% content:: ${note.content}`;
			})
			.join(NotesParser.NOTE_DELIMITER);
	}

	getRoleAsChar(role: number): string {
		return UserRoleMapper[RoleTypeEnum[role]];
	}

	private parseNoteTokens(rawNote: string): NoteTokens {
		const noteRegex =
			/(\w\W+)?role:: (\w+)% created_id:: ([\w\W]+)% created_name:: ([\w\W]+)% created_date:: ([\s\S]+)% content::([\w\W]+)?/gm;
		const matches = noteRegex.exec(rawNote);

		if (!matches) {
			return null;
		}

		const tokens = {
			role: matches[2],
			createdById: matches[3],
			createdByName: matches[4],
			dateCreated: matches[5],
			content: matches[6],
		};

		return tokens;
	}

	private parseLegacyNoteTokens(rawNote: string): NoteTokens {
		const legacyNoteRegex = /\((D|T|L)?\)([\w\W]*)?\(([\s\S]*)?\)\n([\s\S]*)?/;
		const matches = legacyNoteRegex.exec(rawNote);

		if (!matches) {
			return null;
		}

		const tokens = {
			role: matches[1],
			createdByName: matches[2],
			dateCreated: matches[3],
			content: matches[4],
		};

		return tokens;
	}

	private getRoleName(role: string): RoleTypeEnum {
		const currentRole = Object.keys(UserRoleMapper).find((key) => UserRoleMapper[key] === role);

		return RoleTypeEnum[currentRole];
	}

	/**
	 * getting a MM/DD/YYYY | hh:mm:ss AM/PM string and converting to Date object
	 */
	private getDateObjFromString(dateStr: string): Date {
		try {
			if (Date.parse(dateStr)) {
				return new Date(dateStr)
			}
			const timeRegex = new RegExp('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))');
			const dateSection = dateStr.split('|')[0].trim();
			const year = parseInt(dateSection.split('/')[2], 10);
			const month = parseInt(dateSection.split('/')[0], 10) - 1;
			const date = parseInt(dateSection.split('/')[1], 10);

			const amPm = dateStr.split(' ').pop().trim();

			const isAmOrTimeFormat = amPm === 'AM' || timeRegex.test(amPm);

			const spacesAndAmPmRegex =  new RegExp(`\\s|${amPm}+`, 'g');
			const timeSection = dateStr
				.split('|')[1]
				.replace(spacesAndAmPmRegex, '')
				
			const hours = isAmOrTimeFormat ? parseInt(timeSection.split(':')[0], 10) : parseInt(timeSection.split(':')[0], 10) + 12;

			const minutes = parseInt(timeSection.split(':')[1], 10);
			const seconds = parseInt(timeSection.split(':')[2], 10);

			return new Date(year, month, date, hours, minutes, seconds);
		} catch (error) {
			return new Date();
		}
	}

	/**
	 * returns a date | time string
	 * TODO: this should be deprecated when using only new NotesArray in notes with server
	 */
	private fromISODateString(dateStr: string): string {
		let dateObj = new Date(dateStr);

		if (dateObj.toString() === 'Invalid Date') {
			dateObj = this.getDateObjFromString(dateStr);
		}

		const dateSection = `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
		return `${dateSection} | ${dateObj.toLocaleTimeString()}`;
	}
}

export { NotesParser };

interface NoteTokens {
	role?: string;
	createdById?: string;
	createdByName?: string;
	dateCreated?: string;
	content?: string;
}
