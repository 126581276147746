import { Injectable, Injector } from '@angular/core';
import { RealTimeDesktopNotificationsService } from '../real-time-desktop-notifications/real-time-desktop-notifications.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { AuthService } from 'app/services/authentication/auth.service';
import { of, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { TimberService } from '../../logging/timber.service';
import { RealTimeUpdateSimulationService } from '../real-time-update-simulation/real-time-update-simulation.service';

@Injectable({
	providedIn: 'root',
})
export class RealTimeUpdateInitializerService {
	private trackLogInSubscription: Subscription;
	private trackLogOutSubscription: Subscription;
	private realTimeUpdateSimulationService: RealTimeUpdateSimulationService;

	constructor(
		private desktopNotificationsService: RealTimeDesktopNotificationsService,
		private globalSettingsService: GlobalSettingsService,
		private injector: Injector,
		private authService: AuthService,
		private timberService: TimberService
	) {}

	trackAuthentication(): void {
		this.trackLogIn();
		this.trackLogOut();
	}

	private trackLogIn(): void {
		if (this.trackLogInSubscription) {
			return;
		}
		this.trackLogInSubscription = this.authService.authStatus$
			.pipe(
				filter((status) => status.isAuthenticated),
				switchMap((_) => {
					const settingsExist = !!this.globalSettingsService.get();
					return settingsExist ? of(undefined) : this.globalSettingsService.initializeCalled$.pipe(take(1));
				}),
				filter((_) => !!this.globalSettingsService.get().jwtAccessToken),
				tap((_) => {
					this.desktopNotificationsService.checkPermissionAndStartNotifications();
					this.realTimeUpdateSimulationService = this.injector.get(RealTimeUpdateSimulationService);
					this.realTimeUpdateSimulationService.checkPermissionsAndStartRealUpdateSimulation();
				})
			)
			.subscribe(
				(_) => {},
				(error) => this.logErrorWithMessage('unhandled error in notification initializer: ', error)
			);
	}

	private trackLogOut(): void {
		if (this.trackLogOutSubscription) {
			return;
		}

		this.trackLogOutSubscription = this.authService.authStatus$
			.pipe(
				filter((status) => !status.isAuthenticated && !status.isUnauthenticatedByTimeout),
				switchMap(async (_) => {
					await this.desktopNotificationsService.stopNotifications();
					await this.realTimeUpdateSimulationService?.stopNotifications();
				})
			)
			.subscribe();
	}

	private logErrorWithMessage(message: string, error?: Error) {
		if (this.timberService.loggerTimberInitialized)
			this.timberService.error(message, { error, module: 'DesktopNotificationsInitializerService' });
	}
}
