import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { StickyHeaderService } from '../../../services/stickyHeaderService/stickyHeader.service';

@Injectable({providedIn: 'root'})
export class ClearTitle implements ICommunicationEvent {
	constructor(private stickyHeaderService: StickyHeaderService) {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.ClearTitle;
	}

	async handleEvent(event: any) {
		this.stickyHeaderService.titleChanged$.next({title: '', logo: '', logoFallback: ''});
	}
}