import { Injectable } from '@angular/core';
import { TimberService } from '@logging/timber.service';
import { PostMessageService } from '@shared/post-message.service';
import { AppConfigService } from '../appConfig/appConfigService';
import { IShellEvent } from './events/IShellEvent';
import { ShellErrorEvent } from './events/shell-error-event';
import { ShellTokenExpiredEvent } from './events/shell-token-expired-event';
import { ShellGetContextEvent } from './events/shell-get-context-event';
import { ShellLanguageChangedEvent } from './events/shell-language-changed-event';
import { LogLevel } from '@itero/timber/enums';
import { ShellCompanyChangedEvent } from './events/shell-company-changed-event';
import { CommunicationService } from '../client-communication/communication.service';
import { PushNavigationStateEvent } from './events/shell-push-navigation-state';
import { BackButtonClickedEvent } from './events/shell-back-button-clicked-event';
import { CloseMeEvent } from './events/shell-close-me-event';
import { CommunicationChannels } from '@shared/generalInterfaces';
import { PrepareToCloseEvent } from './events/shell-prepare-to-close-event';
import { OpenApplicationEvent } from './events/shell-open-application-event';

@Injectable({ providedIn: 'root' })
export class ShellCommunicationService {
	private readonly oldShellEvents: IShellEvent[];
	private readonly applicationChannelEvents: IShellEvent[];
	private readonly platformChannelEvents: IShellEvent[];

	constructor(private timberService: TimberService,
				private postMessageService: PostMessageService,
				private appConfigService: AppConfigService,
				private communicationService: CommunicationService,
				private shellErrorEvent: ShellErrorEvent,
				private shellTokenExpiredEvent: ShellTokenExpiredEvent,
				private shellGetContextEvent: ShellGetContextEvent,
				private shellLanguageChangedEvent: ShellLanguageChangedEvent,
				private shellCompanyChangedEvent: ShellCompanyChangedEvent,
				private pushNavigationStateEvent: PushNavigationStateEvent,
				private backButtonClickedEvent: BackButtonClickedEvent,
				private closeMeEvent: CloseMeEvent,
				private prepareToCloseEvent: PrepareToCloseEvent,
				private openApplicationEvent: OpenApplicationEvent) {

		this.oldShellEvents = [
			shellErrorEvent,
			shellTokenExpiredEvent,
			shellGetContextEvent,
			shellLanguageChangedEvent,
			shellCompanyChangedEvent
		];

		this.applicationChannelEvents = [
			pushNavigationStateEvent,
			closeMeEvent,
			prepareToCloseEvent
		];

		this.platformChannelEvents = [
			openApplicationEvent,
			backButtonClickedEvent
		];
	}

	public subscribeToApplicationChannel() {
        this.communicationService.subscribeToChannel(CommunicationChannels.ApplicationChannel, 'app-shell', (event) => this.handleEvent(event, CommunicationChannels.ApplicationChannel));
    }

	public subscribeToPlatformChannel() {
        this.communicationService.subscribeToChannel(CommunicationChannels.PlatformChannel, 'app-shell', (event) => this.handleEvent(event, CommunicationChannels.PlatformChannel));
    }

	initEventListeners(element: EventTarget) {
		for (const shellEvent of this.oldShellEvents) {
			element.addEventListener(shellEvent.eventName, shellEvent.handleCustomEvent.bind(shellEvent));
		}
	}

	removeEventListeners(element: EventTarget) {
		for (const shellEvent of this.oldShellEvents) {
			element.removeAllListeners(shellEvent.eventName);
		}
	}

	handlePostMessageEvent(event: any) {
		if (!event || !event.appName) {
			this.logEventError('Illegal event published to shell');
			return;
		}

		this.logEventInfo(`${event.eventName} event sent from ${event.appName} with payload ${JSON.stringify(event.payload)} and caught by the shell.`);

		const shellEvent = this.getShellEvent(event.eventName, event.appName, this.oldShellEvents);
		shellEvent?.handlePostMessage(event, this.publishPostMessageEvent.bind(this));
	}

	private logEventInfo(logMessage: string) {
		this.writeToConsole(logMessage, LogLevel.Info);
		this.timberService.info(logMessage, {module: 'ShellCommunication'});
	}

	private logEventError(logMessage: string) {
		this.writeToConsole(logMessage, LogLevel.Error);
		this.timberService.error(logMessage, {module: 'ShellCommunication'});
	}

	private getShellEvent(eventName: string, publisher: string, shellEvents: IShellEvent[], channelName?: string) {
		const shellEvent = shellEvents.find(e => e.eventName === eventName);
		if (!shellEvent) {
			let message = channelName ? `Event ${eventName} from ${publisher} in ${channelName} is not handled by the app-shell` : `Event ${eventName} from ${publisher} is not handled by the app-shell`;
			this.timberService.warn(message, {module: 'ShellCommunication'});
			return null;
		}
		return shellEvent;
	}

	private publishPostMessageEvent(appName: string, message: any) {
		const iframeId = appName + '-iframe';
		this.postMessageService.send(iframeId, message);
		const logMessage = `Shell published ${message.eventName} event.`;
		this.timberService.info(logMessage, { module: 'ShellCommunication' });
	}

	private writeToConsole(logMessage: string, logLevel: LogLevel) {
		const isProd = this.appConfigService?.appSettings?.isProduction;

		if (isProd)
			return;

		switch (logLevel) {
			case LogLevel.Info:
				console.info(logMessage);
				break;
			case LogLevel.Error:
			default:
				console.error(logMessage);
				break;
		}
	}

	private handleEvent(event: any, channelName: string) {
		let channelEvents = channelName === CommunicationChannels.ApplicationChannel ? this.applicationChannelEvents : this.platformChannelEvents;
        this.timberService.info(`Event ${event.eventHeader.eventId} ${event.eventHeader.eventName} arrived successfully from ${event.eventHeader.publisher} to app-shell on ${channelName}`, { module: 'ShellCommunicationService',  extendedParameters: { eventId: event.eventHeader.eventId, sessionId: event.systemHeader.sessionId } });
        let shellEvent = this.getShellEvent(event.eventHeader.eventName, event.eventHeader.publisher, channelEvents, channelName);
        shellEvent?.handleCustomEvent(event, this.publishEvent.bind(this));
    }

	async publishEvent(eventName: string, targetApplications: Array<string>, eventPayload?: any) {
		let channelName = '';
		if (this.applicationChannelEvents.findIndex(x => x.eventName === eventName) > -1) {
            channelName = CommunicationChannels.ApplicationChannel;
        } else if (this.platformChannelEvents.findIndex(x => x.eventName === eventName) > -1) {
            channelName = CommunicationChannels.PlatformChannel;
        } else {
            this.timberService.error(`Illegal ${eventName} event failed to published from app-shell`, { module: 'ShellCommunicationService' });
            return;
        }

		await this.communicationService.publishEvent(channelName, 'app-shell', eventName, targetApplications, eventPayload);
    }
}
