import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { Observable, of, Subject } from 'rxjs';
import { IdsPairingData, OAuthToken, IdsPairingStatus } from '@shared/generalInterfaces';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Utils } from '@shared/utils.service';
import { WindowsApplicationService } from '../WindowsApplication/windows-application.service';
import { WindowsApplicationQuery } from 'app/settings/state/windows-application.query';

@Injectable({
	providedIn: 'root',
})
export class DoctorPairingService implements OnDestroy {
	public iosimAuthenticationSuccess = new Subject<boolean>();
	pollingInterval: any;
	constructor(
		private eupRoutesService: EupRoutesService,
		private eupHttpHandler: EupHttpHandler,
		private globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private windowsApplicationService: WindowsApplicationService,
		private windowsApplicationQuery: WindowsApplicationQuery
	) {}

	getIdsPairingData(): Observable<IdsPairingData> {
		const url = this.eupRoutesService.idsPairing.getIdsPairingData();
		return this.eupHttpHandler.get(url, undefined, true, false);
	}

	getIOSimLinkWithOAuth(companyId: number, orderId: number): Observable<string> {
		const url = this.eupRoutesService.idsPairing.getIOSimLinkWithOAuth(companyId, orderId);
		return this.eupHttpHandler.get(url, undefined, true, false);
	}

	openIdsLoginWindow(): void {
		const langCode = this.globalSettingsService.get().selectedLanguage.code;
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		const url = this.eupRoutesService.idsPairing.getIdsLoginUrl(langCode, companyId);
		this.eupHttpHandler
			.get(url, { observe: 'response' })
			.pipe(
				map(({ body }) => this.openPopupWindow(body)),
				tap((loginWindow) => this.setPollingInterval(loginWindow))
			)
			.subscribe();
	}

	openPopupWindow(url: string): Window {
		const height = 600;
		const width = 800;
		const top = window.outerHeight / 2 + window.screenY - height / 2;
		const left = window.outerWidth / 2 + window.screenX - width / 2;

		return window.open(`${url}${this.windowsApplicationQuery.promptLogin ? '&prompt=login' : ''}`, 'iosim-login-page', `width=${width},height=${height},top=${top},left=${left}`);
	}

	getOAuthToken(): Observable<OAuthToken> {
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		const url = this.eupRoutesService.idsPairing.getOAuthToken(companyId);
		return this.eupHttpHandler.get(url, undefined, true, false);
	}

	pairedStatus(): Observable<IdsPairingStatus> {
		let res = new IdsPairingStatus();
		return this.getIdsPairingData().pipe(
			mergeMap((idsPairingData) => {
				if (!idsPairingData || !idsPairingData.refreshToken) {
					res.isPaired = false;
					return of(res);
				} else {
					return this.getOAuthToken().pipe(
						mergeMap((oauth) => {
							if (!oauth || !oauth.refresh_token) {
								res.isPaired = false;
								return of(res);
							} else {
								res.isPaired = true;
								return of(res);
							}
						})
					);
				}
			})
		);
	}

	// I am not sure that it's necessary
	ngOnDestroy() {
		clearInterval(this.pollingInterval);
	}

	private setPollingInterval(loginWindow: Window) {
		this.pollingInterval = setInterval(() => {
			try {
				if (!loginWindow || (loginWindow && loginWindow.closed)) {
					this.iosimAuthenticationSuccess.next(false);
					clearInterval(this.pollingInterval);
				} else if (loginWindow.location.host === window.location.host) {
					const isAuthenticationSuccess =
						this.utils.getUrlParameterFromQueryString('isSuccess', loginWindow.location.search) === 'true';
					if(isAuthenticationSuccess) {
						this.windowsApplicationService.setPromptLogin(!isAuthenticationSuccess);
					}
					this.iosimAuthenticationSuccess.next(isAuthenticationSuccess);
					clearInterval(this.pollingInterval);
					loginWindow.close();
				}
			} catch (e) {
				console.log(`Waiting for response from ids login window.`);
			}
		}, 1000);
	}
}
