import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of, BehaviorSubject } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

@Injectable()
export class OnlineOfflineService {
	onlineStatus$ = new BehaviorSubject(navigator.onLine);
	isOnline: boolean;

	isOnlineStatus(): Observable<boolean> {
		return merge(
			this.onlineStatus$,
			fromEvent(window, 'online').pipe(mapTo(true)),
			fromEvent(window, 'offline').pipe(mapTo(false))
		).pipe(tap((isOnline: boolean) => {
			this.isOnline = isOnline;
		}));
	}
}
