<div bsModal #settingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button id="btn-hide-settings" type="button" class="close close-sign" (click)="closeSettings()">
                        &times;
                    </button>
                    {{'Settings.Settings_Title' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <section class="row">
                    <h4 class="col-sm-12">
                        {{'Settings.Computer_Settings' | translate }}
                    </h4>
                    <div id="show-localization" class="clickable col-sm-3" (click)="showLocalizationSettings()">
                        <svg class="svg-localization48 svg-localization48-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Localization' | translate }}</span>
                    </div>
                    <div id="show-my-account" class="clickable col-sm-3" (click)="showLoginSettings()">
                        <svg class="svg-loginSettings40 svg-loginSettings40-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Login' | translate }}</span>
                    </div>
                    <div id="show-downloads-and-resources" class="clickable col-sm-3" *ngIf="isDownloadAndResourcesShown">
                        <a [routerLink]="['/downloads']" target="_blank"><svg class="svg-downloadsResources40 svg-downloadsResources40-dims"></svg>
                            <svg class="svg-downloadsResources44 svg-downloadsResources44-dims"></svg>
                            <span class="settingsLinkText">Download resources</span>
                        </a>
                    </div>
                </section>
                <section class="row">
                    <h4 class="col-sm-12">
                        {{'Settings.User_Settings' | translate }}
                    </h4>
                    <div id="show-rx-settings" class="clickable col-sm-3" (click)="showRxSettings()" *ngIf="shouldShowRxSettings">
                        <svg class="svg-rxSettings40 svg-rxSettings40-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Rx_Settings' | translate }}</span>
                    </div>
                    <div id="show-export-settings" class="clickable col-sm-3" (click)="showExportSettings()" *ngIf="allowExportSettings">
                        <svg class="svg-exportSettings50 svg-exportSettings50-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Export_Settings' | translate }}</span>
                    </div>
                    <div *ngIf="isWeChatSharingEnabled" 
                          id="show-account-pairing-settings" 
                          class="clickable col-sm-3" 
                          (click)="showAccountPairingSettings()">
                        <svg class="svg-accountPairingSettings40 svg-accountPairingSettings40-dims accountPairingSettingsIcon"></svg>
                        <span class="settingsLinkText">{{'Settings.Account_Pairing' | translate }}</span>
                    </div>
                    <div *ngIf="isNotificationsEnabled" 
                          id="show-notifications-settings" 
                          class="clickable col-sm-3" 
                          (click)="showNotificationsSettings()">
                        <svg class="svg-notificationsSettings40 svg-notificationsSettings40-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Notifications' | translate }}</span>
                    </div>
                </section>
                <section class="row" *ngIf="isSystemSettingsEnabled">
                    <h4 class="col-sm-12">
                        {{'Settings.System_Settings' | translate }}
                    </h4>
                    <div id="show-system-information" class="clickable col-sm-3" (click)="showScannerInformation()">
                        <svg class="svg-systemInformation40 svg-systemInformation40-dims"></svg>
                        <span class="settingsLinkText">{{'Settings.Scanners_Title' | translate }}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
<eup-rxSettings id="modal-rx-settings"></eup-rxSettings>
<eup-localizationSettings id="modal-localization-settings"></eup-localizationSettings>
<eup-loginSettings id="modal-login-settings"></eup-loginSettings>
<eup-scannerSettings id="modal-scanner-settings"></eup-scannerSettings>
<eup-exportSettings id="modal-export-settings"></eup-exportSettings>
<eup-account-pairing-settings-modal id="account-pairing-settings"></eup-account-pairing-settings-modal>
<eup-notifications-settings-modal id="modal-notifications-settings"></eup-notifications-settings-modal>
