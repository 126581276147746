import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalSettingsService, ContextParams } from '../../core/globalSettings.service';
import { ScannerDetails } from '../../shared/generalInterfaces';
import { Subscription } from 'rxjs';
import { Utils } from '../../shared/utils.service';

@Component({
	selector: 'eup-scannerSettings',
	templateUrl: './scannerSettings.component.html',
	styleUrls: [ './scannerSettings.component.scss' ]
})
export class ScannerSettingsModal implements OnInit, OnDestroy {
	@ViewChild('scannerSettingsModal') scannerSettingsModal: ModalDirective;

	scannerSettings: ScannerDetails[];
	contextSubscription: Subscription;

	constructor(
		private globalSettingsService: GlobalSettingsService,
		private utils: Utils,
	) {}

	ngOnInit(): void {
		const settings = this.globalSettingsService.get();

		const selectedCompanyId = settings.selectedCompanyId;
		this.scannerSettings = settings.companies.filter((c) => c.id === +selectedCompanyId)[0].scannerDetails;

		this.contextSubscription = this.globalSettingsService.contextChanged.subscribe(
			(contextParams: ContextParams) => {
				if (!contextParams.companyId) {
					return;
				}

				this.scannerSettings = settings.companies.filter(
					(c) => c.id === +contextParams.companyId
				)[0].scannerDetails;
			}
		);
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
	}

	show(): void {
		this.scannerSettingsModal.show();
	}

	hide(): void {
		this.scannerSettingsModal.hide();
	}
}
