import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
	transform(value: string, search: string): string {
		if (!search || !value) {
			return value;
		}
		const searchWords = search.split(' ');
		const regExpToHighlight = new RegExp(`\\b${searchWords.join('|\\b')}`, 'gi');
		return value.toString().replace(regExpToHighlight, (match) => `<span class="eup-highlight-text">${match}</span>`);
	}
}
