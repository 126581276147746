import { Injectable } from '@angular/core';
import { BillOfWorkType } from './enums';
import { Order } from 'app/doctors/orders/orders.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { RxService } from '../rx/services/rx.service';
import { resolve } from 'path';
import { Observable } from 'rxjs';

@Injectable()
export class IteroLabService {

	constructor(private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private rxService: RxService) { }


	isEditableWithIteroLab(order: Order): boolean {
		let result = false;
		if (order.canOpenWithItero && !order.isOrthoOrder &&
			(order.lastBowId === BillOfWorkType.ReadyForDownload ||
				(order.lastBowId === BillOfWorkType.Completed && order.canSendToMilling) ||
				order.lastBowId === BillOfWorkType.LabReview
			)
		) {
			result = true;
		}
		return result;
	}

	isNeedToSendToLab(order: Order): boolean {
		return this.isEditableWithIteroLab(order) && order.lastBowId !== BillOfWorkType.LabReview;
	}

	buildITeroProtocolLink(orderId: number, orderDetailsId: number) {
		const url = `${this.eupRoutesService.lab.buildITeroProtocolLink}?orderHeaderId=${orderId}&orderDetailsId=${orderDetailsId}`;
		return this.http.get(url);
	}

	// in case its ReadyForDownload status and its not ortho case we need to open the app in edit mode:
	// first send the order to "lab review"
	// and get correct protocol link from export 
	// PATCH to fix the optimistic cache so we can remove this buildITeroProtocolLink call !!!
	prepareOpenApp(orderId: number, lastDetailsId: number): Observable<string> {
		return new Observable(observer => {
			this.rxService.processSendToLab(orderId).subscribe(() => {
				this.buildITeroProtocolLink(orderId, lastDetailsId).subscribe((url: string) => {
					observer.next(url);
				});
			});
		});
	}



}



