import { CommonModule } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PlatformCommunicationEvents, ContextEvents, IosimEvents, LaunchAppEvents, StickyHeaderEvents, ShellCommunicationEvents, NavigationEvents, CommunicationChannels } from '@shared/generalInterfaces';
import { BaseDestroyable } from '@core/base-destroyable';
import { eventToAppIdMap } from './event-to-app-id.map';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { mocksDictionary } from 'app/mockups/mocksCommunicationEvents/mocksDictionary';
import { IJsonValue } from 'app/interfaces/IJsonValue';
import { IteroEventBus } from '@itero/itero-client-communication';

export const iteroAppId = 'my-itero';

@Component({
	selector: 'event-bus-tester',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule
	],
	templateUrl: './event-bus-tester.component.html',
	styleUrls: ['./event-bus-tester.component.scss']
})

export class EventBusTesterComponent extends BaseDestroyable implements AfterViewInit {
	channels: Array<{ displayName: string; channelName: string }> = [
		{
			channelName: CommunicationChannels.PlatformChannel,
			displayName: 'Platform Channel',
		},
		{
			channelName: CommunicationChannels.ApplicationChannel,
			displayName: 'Application Channel',
		},
	];
	methods: Array<{ displayName: string; methodName: string }> = [
		{
			methodName: 'emit',
			displayName: 'emit(<emit event>)',
		},
		{
			methodName: 'on',
			displayName: 'on(<subscibe to channel>)',
		},
	];


	selectedEvent = new FormControl(PlatformCommunicationEvents.InitContext);
	selectedChannel = new FormControl(CommunicationChannels.PlatformChannel);
	selectedMethod = new FormControl('emit');
	publisherAppId = new FormControl(iteroAppId);
	eventToBeRun = new FormControl('iTeroEventBus...');
	testEventResult = new FormControl('');
	eventPayload = new FormControl<any>(null);
	targetApplications = new FormControl<string>('*');
	eventBus: IteroEventBus;

	eventGroups: Array<{
		name: string;
		events: Array<{ displayName: string; eventName: string }>;
	}> = this.populateEventGroups();

	constructor(private appConfigService: AppConfigService) {

		super();

		let loggingEndpoint = this.appConfigService?.appSettings?.loggingEndpoint;
		this.eventBus = new IteroEventBus(loggingEndpoint);
	}

	ngAfterViewInit(): void {
		this.handleEventChange();
	}

	handleEventChange() {
		this.eventToBeRun.setValue(this.getEventToRun());
		const eventsWithPayloadMock: string[] = this.getMocks();
		const eventKey = this.selectedEvent.value ?? '';
		if (eventsWithPayloadMock.includes(eventKey)) {
			this.populatePayload(eventKey);
		} else {
			this.eventPayload.setValue(null);
		}
		this.populateFieldsByEventKey(eventKey);
	}

	testEvent() {
		const channel = this.selectedChannel.value;
		const event = this.selectedEvent.value;
		const method = this.selectedMethod.value;
		const publisherAppId = this.publisherAppId.value;
		console.log('iTeroEventBus', this.eventBus);
		try {
			if (!this.eventBus) {
				throw new Error('iTeroEventBus instance not found.');
			}
			if (method === 'emit') {
				this.emitEvent(channel, event, publisherAppId);
			}
			if (method === 'on') {
				this.subscribeToEvent(channel, publisherAppId);
			}
		} catch (error: any) {
			this.testEventResult.setValue(
				`Error testing event or subscription: method: ${method}, channel: ${channel}, event name: ${event}, publisher app ID: ${publisherAppId}, error: ${error}`
			);
		}
	}

	private emitEvent(channel, event, publisherAppId) {
		const eventId = this.eventBus.generateEventId();

		this.eventBus
			.emit(
				channel ?? CommunicationChannels.PlatformChannel,
				publisherAppId ?? iteroAppId,
				event ?? '',
				eventId,
				this.targetApplications.value?.split(',') ?? ['*'],
				this.eventPayload.value ? JSON.parse(this.eventPayload.value) : null
			)
			.then((isEmitSuccessful: boolean) => {
				let emitResult = '';
				if (!isEmitSuccessful) {
					emitResult = `Emit unsuccessful with the following params: channel: ${channel}, publisherId: ${publisherAppId}, event: ${event}, eventId: ${eventId}, payload: ${this.eventPayload.value}`;
					this.testEventResult.setValue(emitResult);
					throw new Error(emitResult);
				}
				emitResult = `Emit successful with the following params: channel: ${channel}, publisherId: ${publisherAppId}, event: ${event}, eventId: ${eventId}, payload: ${this.eventPayload.value}`;
				this.testEventResult.setValue(emitResult);
			});
	}

	private subscribeToEvent(channel, publisherAppId) {
		let testOnResult = '';
		const testHandler = (event: any) => {
			console.log('Handle Subsription event stub. Event:', event);
		};
		const subscribedSuccessfully = this.eventBus.on(
			channel ?? CommunicationChannels.PlatformChannel,
			publisherAppId ?? iteroAppId,
			(event: any) => testHandler(event)
		);

		if (!subscribedSuccessfully) {
			testOnResult = `On unsuccessful subsciption to ${channel}, with the following callbackAppId: ${publisherAppId ?? iteroAppId}`;
			this.testEventResult.setValue(testOnResult);
			throw new Error();
		}

		testOnResult = `On successful subsciption to ${channel}, with the following callbackAppId: ${publisherAppId ?? iteroAppId}`;
		this.testEventResult.setValue(testOnResult);

	}

	private populatePayload(eventKey: string) {
		const eventPayload = this.getEventPayloadMock(eventKey);
		console.warn('event key', eventKey, 'payload', eventPayload);
		this.eventPayload.setValue(JSON.stringify(eventPayload, null, 2));
	}

	private populateAppId(eventKey: string) {
		this.publisherAppId.setValue(eventToAppIdMap[eventKey].publisherAppId);
	}

	private populateChannel(eventKey: string) {
		this.selectedChannel.setValue(eventToAppIdMap[eventKey].channel);
	}

	private populateTargetApplications(eventKey: string) {
		this.targetApplications.setValue(
			eventToAppIdMap[eventKey].targetApplications.join(',')
		);
	}

	private populateFieldsByEventKey(eventKey: string) {
		if (!eventKey) {
			console.warn('No Event Selected');
		}
		this.populateAppId(eventKey);
		this.populateChannel(eventKey);
		this.populateTargetApplications(eventKey);
	}

	private getEventPayloadMock(eventKey: string): IJsonValue {
		return mocksDictionary[eventKey];
	}

	private getEventToRun(): string {
		if (this.selectedMethod.value === 'emit') {
			return `iTeroEventBus.emit('${this.selectedChannel.value}', '${this.publisherAppId.value
				}', '${this.selectedEvent?.value || ''}', [${this.targetApplications.value ?? ''
				}], <Event Payload>)`;
		}
		if (this.selectedMethod.value === 'on') {
			return `iTeroEventBus.on('${this.selectedChannel.value}', '${this.publisherAppId.value}', '<callback function - just logging>')`;
		}
		return '';
	}

	private populateEventGroups(): Array<{
		name: string;
		events: Array<{ displayName: string; eventName: string }>;
	}> {
		const appLifecycleEvents = Object.entries(ShellCommunicationEvents).map(
			(event: [string, ShellCommunicationEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		const navigationEvents = Object.entries(NavigationEvents).map(
			(event: [string, NavigationEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		const contextEvents = Object.entries(ContextEvents).map(
			(event: [string, ContextEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		const iosimEvents = Object.entries(IosimEvents).map(
			(event: [string, IosimEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		const launchAppEvents = Object.entries(LaunchAppEvents).map(
			(event: [string, LaunchAppEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		const stickyHeaderEvents = Object.entries(StickyHeaderEvents).map(
			(event: [string, StickyHeaderEvents]) => {
				const [key, value] = event;
				return {
					displayName: key,
					eventName: value,
				};
			}
		);

		return [
			{
				name: 'Application Lifecycle',
				events: appLifecycleEvents,
			},
			{
				name: 'Navigation Events',
				events: navigationEvents,
			},
			{
				name: 'Context',
				events: contextEvents,
			},
			{
				name: 'IOSIM',
				events: iosimEvents,
			},
			{
				name: 'Launch Application',
				events: launchAppEvents,
			},
			{
				name: 'Sticky Header',
				events: stickyHeaderEvents,
			},
		];
	}

	private getMocks() {
		return [
			ShellCommunicationEvents.AppProgress,
			ShellCommunicationEvents.HandleError,
			NavigationEvents.PushNavigationState,
			NavigationEvents.ExternalApplicationFalied,
			ContextEvents.BizCtxUpdated,
			ContextEvents.CtxUiUpdated,
			ContextEvents.TokenRefreshed,
			ContextEvents.TokenExpired,
			ContextEvents.InitContext,
			ContextEvents.SessionUpdated,
			IosimEvents.IosimProgressResults,
			LaunchAppEvents.AppsConfigResult,
			LaunchAppEvents.InitAppMetadata,
			LaunchAppEvents.OpenApplication,
			LaunchAppEvents.GetAppsConfig,
			StickyHeaderEvents.ScreenshotResults,
			StickyHeaderEvents.ChangeTitle,
		]
	}
}
