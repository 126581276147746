import {Injectable, OnDestroy} from '@angular/core';
import { ILabRxExtension } from '../../interfaces/ILabRxExtension';
import { IOrderInformation } from '../../interfaces/IOrderInformation';
import {SettingsService} from '@shared/settings.service';
import {BehaviorSubject} from 'rxjs';
import {ILabCompanySettingsDto} from '../../interfaces/ILabCompanySettingsDto';

@Injectable()
export class LabRxExtensionService implements OnDestroy {
	public rxExtension: ILabRxExtension;
	public isOrderDirectToLab: boolean;
	public isOrthoOrder: boolean;
	public rxOrderInfo: BehaviorSubject<IOrderInformation>;

	constructor(private settingsService: SettingsService) {
		this.initService();
	}

	public updateOrderInformation(orderInformation: IOrderInformation): void {
		if (!this.rxExtension.orderInformation) { return; }

		this.rxExtension.orderInformation.NumOfModels = orderInformation.NumOfModels == null ? 0 : orderInformation.NumOfModels;
		this.rxExtension.orderInformation.AdditionalDies = orderInformation.AdditionalDies == null ? 0 : orderInformation.AdditionalDies;
		this.rxExtension.orderInformation.DieDitch = orderInformation.DieDitch == null ? false : orderInformation.DieDitch;
	}

	public updateSendToModelingSelection(sendToModelingChecked: boolean): void {
		if (this.rxExtension.sendToModeling !== sendToModelingChecked) {
			this.rxExtension.sendToModeling = sendToModelingChecked;
		}
	}

	public updatePhysicalModelSelection(physicalModelChecked: boolean): void {
		if (this.rxExtension.sendToPhysicalModel !== physicalModelChecked) {
			this.rxExtension.sendToPhysicalModel = physicalModelChecked;
			this.updateSendToModelingSelection(physicalModelChecked);

			if (!physicalModelChecked) {
				this.clearPhysicalModel();
			} else if (!this.isOrderDirectToLab) {
				this.rxExtension.orderInformation.NumOfModels = 1;
				this.rxExtension.orderInformation.DieDitch = true;
			}
		}
	}

	public getOrderInformation(): IOrderInformation {
		if (this.rxExtension) {
			return this.rxExtension.orderInformation;
		}

		return null;
	}

	public clearRxExtension(): void {
		this.rxExtension = { sendToModeling: false, sendToPhysicalModel: false };
		if (!this.rxExtension.orderInformation) {
			this.rxExtension.orderInformation = {};
		}

		this.clearPhysicalModel();
	}

	public clearPhysicalModel(): void {
		if (this.rxExtension.orderInformation) {
			this.rxExtension.orderInformation.NumOfModels = 0;
			this.rxExtension.orderInformation.AdditionalDies = 0;
			this.rxExtension.orderInformation.DieDitch = false;
		}
	}

	public initOrderInformation(orderInformation: IOrderInformation): void {
		if (this.isOrthoOrder) {
			return;
		}

		if (!orderInformation) {
			orderInformation = {};
		}

		this.settingsService.getLabCompanySettings().subscribe((settings: ILabCompanySettingsDto) => {
			orderInformation.NumOfModels = (orderInformation.NumOfModels) ? orderInformation.NumOfModels : settings.restoExportSettings.modelsCount;
			orderInformation.AdditionalDies = (orderInformation.AdditionalDies) ? orderInformation.AdditionalDies : settings.restoExportSettings.additoinalDiesPerToothCount;
			orderInformation.DieDitch = (orderInformation.DieDitch) ? orderInformation.DieDitch : settings.restoExportSettings.isDieDitch;
			orderInformation.IdeCadCamSystemId = (orderInformation.IdeCadCamSystemId) ? orderInformation.IdeCadCamSystemId : -1;

			if (!orderInformation.IdeProductionCenterId) {
				orderInformation.IdeProductionCenterId = -1;
			}

			if (!orderInformation.LocalIdeCadCamSystemId) {
				orderInformation.LocalIdeCadCamSystemId = -1;
			}

			if (!orderInformation.IdxProductionCenterId) {
				orderInformation.IdxProductionCenterId = -1;
			}

			if (!orderInformation.IntegrationCompany) {
				orderInformation.IntegrationCompany = -1;
			}

			if (!this.rxExtension) {
				this.clearRxExtension();
			}

			this.rxExtension.orderInformation = orderInformation;

			if (orderInformation.NumOfModels > 0) {
				this.rxExtension.sendToPhysicalModel = true;
				this.rxExtension.sendToModeling = true;
			}

			this.rxOrderInfo.next(orderInformation);
		});
	}

	ngOnDestroy(): void {
		this.rxOrderInfo.unsubscribe();
	}

	private initService() {
		this.rxOrderInfo = new BehaviorSubject<IOrderInformation>({});
	}
}
