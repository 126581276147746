import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'mobile-sticky-header',
	templateUrl: './mobile-sticky-header.component.html',
	styleUrls: ['./mobile-sticky-header.component.scss'],
})
export class MobileStickyHeaderComponent implements OnInit {
	showUserDetails = false;

	@Input() patientName: string;
	@Input() orderId: number;
	@Input() scanDate: string;

	constructor() {}

	ngOnInit(): void {
		this.showUserDetails = this.patientName != '';
	}

	toggleUserDetails(): void {
		this.showUserDetails = !this.showUserDetails;
	}
}
