import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { CaseTypeEnum } from './enums';
import { ProcedureEnum } from './enums';

@Directive({
	selector: '[allowedCaseTypes]'
})
export class AllowedCaseTypesDirective {

	@Input()
	set allowedCaseTypes(value: { allowedTypes: CaseTypeEnum[], type: CaseTypeEnum, procedureType: ProcedureEnum }) {
		if (value.allowedTypes.some(allowedType => allowedType === value.type) || (value.procedureType == ProcedureEnum.StudyModel_iRecord)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} 
		else {
			this.viewContainer.clear();
		}
	}

	constructor(private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) {
	}
}
