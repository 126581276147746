
@if (debug) {
<div
  class="internal-debug bottom"  
  [class.is-minimized]="isMinimized">     
    <div>
      <div id="debug-header">
        <div id="debug-title"><h2>Debug iTero Event Bus</h2></div>        
        <div id="debug-control-buttons">        
          <button                       
            class="minimize-toggle-button"
            [class.is-minimized]="isMinimized"
            (click)="toggleMinimizeDebugTool($event)" >
            {{isMinimized ? 'Debug' : 'Minimize'}}
          </button>
          <button                       
            class="close-button"           
            (click)="closeDebugTool()" >
            Close
          </button>
        </div>
      </div>
      <hr/>
      <event-bus-tester></event-bus-tester>
    </div>
</div>
}