import { RxNote } from 'app/services/rx-notes-parser/lib/notes.model';
import { invisalignLogo } from './invisalign-logo';

export interface ReferralValues {
	caseType: string;
	doctorName: string;
	dueDate: string;
	issueDate: string;
	notes: RxNote[];
	practiceAddress: string;
	practiceName: string;
	practicePhone: string;
	patientName: string;
	referralCode: string;
	treatmentStage: string;
}

export interface ReferralLabels {
	caseType: string;
	doctorName: string;
	dueDate: string;
	notes: string;
	patientName: string;
	practiceAddress: string;
	practiceName: string;
	practicePhone: string;
	referralCode: string;
	treatmentStage: string;
	referralValidFor: string;
}

const TEXT_COLOR = '#3e3d40';
const TABLE_BORDER_COLOR = '#cacaca';
const TABLE_BORDER_WIDTH = 0.5;
const TABLE_PADDING_X = 15;
const TABLE_PADDING_Y = 10;

const tableLayout = {
	hLineWidth: (i, node) => {
		return TABLE_BORDER_WIDTH;
	},
	vLineWidth: (i, node) => {
		return TABLE_BORDER_WIDTH;
	},
	hLineColor: (i, node) => {
		return TABLE_BORDER_COLOR;
	},
	vLineColor: (i, node) => {
		return TABLE_BORDER_COLOR;
	},
	paddingLeft: (i, node) => {
		return TABLE_PADDING_X;
	},
	paddingRight: (i, node) => {
		return TABLE_PADDING_X;
	},
	paddingTop: (i, node) => {
		return TABLE_PADDING_Y;
	},
	paddingBottom: (i, node) => {
		return TABLE_PADDING_Y;
	},
};

export function createReferralPdfDefinition(values: ReferralValues, labels: ReferralLabels, fontName: string) {
	const {
		caseType,
		doctorName,
		dueDate,
		issueDate,
		notes = [],
		practiceAddress,
		practiceName,
		practicePhone,
		patientName,
		referralCode,
		treatmentStage,
	} = values;

	const caseTableRows = [
		[
			{ label: `${labels.patientName}:`, value: `${patientName}` },
			{ label: `${labels.caseType}:`, value: `${caseType}` },
		],
		[
			{ label: `${labels.dueDate}:`, value: `${dueDate}` },
			{ label: `${labels.treatmentStage}:`, value: `${treatmentStage}` },
		],
	];

	const practiceTableRows = [
		[
			{ label: `${labels.practiceName}:`, value: `${practiceName}` },
			{ label: `${labels.doctorName}:`, value: `${doctorName}` },
		],
		[
			{ label: `${labels.practiceAddress}:`, value: `${practiceAddress}` },
			{ label: `${labels.practicePhone}:`, value: `${practicePhone}` },
		],
	];

	const noteContentHeight = notes.length ? 0 : 150;

	const notesStack = [];
	notes.forEach((note) => {
		const stack = [
			{ text: note.createdByName, fontSize: 12, bold: true },
			{ text: note.dateCreated, fontSize: 10, bold: true, margin: [0, 2] },
			{ text: note.content, fontSize: 10, margin: [0, 0, 0, 10] },
		];
		notesStack.push(...stack);
	});

	const docDefinition = {
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [20, 30, 20, 30],
		content: [
			{
				image: invisalignLogo,
				fit: [200, 30],
				absolutePosition: { x: 375, y: 23 },
			},
			{ style: 'issueDate', text: issueDate },
			{ style: 'pageHeader', text: `${labels.referralCode}: ${referralCode}` },
			createTable(caseTableRows),
			createTable(practiceTableRows),
			{
				style: 'table',
				table: {
					heights: [0, noteContentHeight],
					widths: ['*'],
					headerRows: 1,
					body: [
						[{ style: 'tableHeader', text: `${labels.notes}` }],
						[
							{
								stack: notesStack,
							},
						],
					],
				},
				layout: tableLayout,
			},
		],
		footer: {
			columns: [{ text: labels.referralValidFor, alignment: 'left' }],
			margin: [20, 5, 20, 10],
		},
		styles: {
			pageHeader: {
				fontSize: 18,
				bold: true,
				margin: [0, 0, 0, 15],
				color: TEXT_COLOR,
			},
			issueDate: {
				fontSize: 14,
				margin: [0, 0, 0, 0],
				color: TEXT_COLOR,
			},
			table: {
				margin: [0, 0, 0, 15],
				color: TEXT_COLOR,
			},
			tableHeader: {
				fontSize: 14,
			},
		},
		defaultStyle: {
			font: fontName,
		},
	};

	return docDefinition;
}

function createTable(data: TableColumnData[][]) {
	const table = {
		style: 'table',
		table: {
			widths: ['*', '*'],
			body: createTableBody(data),
		},
		layout: {
			...tableLayout,
			vLineWidth: (i, node) => {
				return i === 0 || i === node.table.widths.length ? TABLE_BORDER_WIDTH : 0;
			},
			paddingLeft: (i, node) => {
				return i === 0 ? TABLE_PADDING_X : 0;
			},
		},
	};

	return table;
}

function createTableBody(rows: TableColumnData[][]): any {
	const tableBody = rows.map((columns) => createTableRow(columns));

	return tableBody;
}

function createTableRow(columns: TableColumnData[]): any {
	const tableRow = columns.map((col) => createTableColumn(col));

	return tableRow;
}

function createTableColumn(data: TableColumnData): any {
	const tableColumn = {
		layout: 'noBorders',
		table: {
			widths: ['*'],
			body: [
				[
					{
						text: data.label,
						bold: true,
					},
				],
				[
					{
						text: data.value,
					},
				],
			],
		},
	};

	return tableColumn;
}

interface TableColumnData {
	label: string;
	value: string;
}
