import { IShellEvent } from './IShellEvent';
import { ShellCommunicationEvents } from '@shared/generalInterfaces';
import { Injectable } from '@angular/core';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class BackButtonClickedEvent implements IShellEvent {

	constructor(private applicationNavigationService: ApplicationNavigationService,
		private router: Router) { }

	get eventName(): string {
		return ShellCommunicationEvents.BackButtonClicked;
	}

	async handleCustomEvent(event: any, publishEventFunc: Function) {
		const transitionsCount = this.applicationNavigationService.stackLength();
		if (transitionsCount === 1) {
			let transition = this.applicationNavigationService.peekNavigationTransition();
			this.applicationNavigationService.startClosingAppTimer(transition.transitionId);
			await publishEventFunc(ShellCommunicationEvents.PrepareToClose, 
				[transition.navigatedToAppId]);
		} else if (transitionsCount > 1) {
			const transition = this.applicationNavigationService.popNavigationTransition();
			const url = new URL(transition.urlFrom);
            this.router.navigateByUrl(url.pathname + url.search);
		} else {
			this.applicationNavigationService.navigateToHomePageWhenStackIsEmpty();
		}
	}

	handlePostMessage(event): void {}
}
