import { TDocumentDefinitions } from 'pdfmake/interfaces';

export const labAgreementPdfDefinitions = function(header: string, body: string, fontName: string, logoURL: string): TDocumentDefinitions {
	const darkBlue = '#063b5f';
	return {
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [40, 40, 40, 60],
		content: [
			{
				columns: [
					{
						width: '*',
						text: header,
						style: 'header'
					},
					{
						width: 'auto',
						stack: [
							{
								image: 'logo',
								fit: [60, 60],
							}
						],
					},

				]
			},
			{ text: body }
		],
		footer: (currentPage, pageCount) => ({
				text: `${currentPage} / ${pageCount}`,
				alignment: 'right',
				style: 'footer'
		}),
		images: {
			logo: logoURL,
		},
		styles: {
			header: {
				fontSize: 20,
				bold: true,
				color: darkBlue,
				margin: [0, 20],
			},
			footer: {
				marginRight: 40,
				color: darkBlue,
			}
		},
		defaultStyle: {
			font: fontName,
		}
	};
}
