import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { ClientDiscoveryService } from '../../../platform/services/client-discovery/client-discovery.service';

@Injectable({providedIn: 'root'})
export class GetAppsConfigEvent implements ICommunicationEvent {
	constructor() {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.GetApplicationsConfiguration;
	}

	async handleEvent(event: any, publishEventFunc: Function) {
		let appIds = event.data.appIds;
		let appsConfig = await ClientDiscoveryService.getClientApplications(appIds);
		await publishEventFunc(PlatformCommunicationEvents.ApplicationsConfigurationResult, 
			[event.eventHeader.publisher], appsConfig);
	}
}