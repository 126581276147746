import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RegulatoryConfigurationState, RegulatoryConfigurationStore } from './regulatory-configuraton.store';

@Injectable({ providedIn: 'root' })
export class RegulatoryConfigurationQuery extends Query<RegulatoryConfigurationState> {

	readonly dateOfBirthFormat$ = this.select(state => state.dateOfBirthFormat);
	readonly dateOfBirthMask$ = this.select(state => state.dateOfBirthMask);

	get dateOfBirthFormat(): string { return this.getValue().dateOfBirthFormat; }

	constructor(protected store: RegulatoryConfigurationStore) {
		super(store);
	}

}
