import { Injectable } from '@angular/core';

export class ScreenshotMessage {
	content: string;
	style: string;
	constructor(content, style?) {
		this.content = content;
		this.style = style;
	}
}

@Injectable({
	providedIn: 'root',
})
export class ScreenshotToastService {
	public messages = [];
	show(content, style = 'info', timeout = 5000) {
		const screenshotMessage = new ScreenshotMessage(content, style);
		this.messages.push(screenshotMessage);
		setTimeout(() => {
			this.messages.pop();
		}, timeout);
	}
}
