import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IKeyValue } from '../../../../interfaces/IKeyValue';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'eup-generic-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: [ './dropdown.component.scss' ]
})
export class DropdownComponent implements OnChanges, OnInit {
	@Input() label: string;
	@Input() items: IKeyValue[];
	@Input() selectedValue?: any;
	@Input() isRemoveEmptyItems? = true;
	@Input() disabled? = false;
	@Output() onSelect?: EventEmitter<any> = new EventEmitter();
	selected: IKeyValue = { key: 0, value: '' };
	@Input() placeholder: string;
	private langChangeSubscription: Subscription;

	constructor(private translateService: TranslateService) { }

	ngOnInit(): void {
		this.setPlaceholder();
		this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.setPlaceholder();
		});
	}

	setPlaceholder() {
		if (this.selected.key === 0) {
			const placeholder = this.placeholder? this.translateService.instant(this.placeholder) : this.translateService.instant('Dropdown.Placeholder');
			this.selected = { key: 0, value: placeholder };
		}}

	onSelected(item: IKeyValue) {
		this.selected = item;
		this.onSelect.emit(item);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.items !== undefined &&
			changes.items.currentValue !== undefined &&
			changes.items.currentValue !== null
		) {
			if (this.isRemoveEmptyItems) {
				changes.items.currentValue = changes.items.currentValue.filter((i) => i.value !== null);
			}
		}

		if (
			changes.selectedValue !== undefined &&
			changes.selectedValue.currentValue !== undefined &&
			changes.selectedValue.currentValue !== null
		) {
			if (this.items !== undefined && changes.selectedValue.currentValue !== 0) {
				const found = this.items.find((i: IKeyValue) => i.key === changes.selectedValue.currentValue);
				this.selected = found !== undefined ? found : this.selected;
			}
		}
	}

}