import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { firstValueFrom, Observable } from 'rxjs';
import { AppConfigService } from '../appConfig/appConfigService';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { IVisitReportInputs, IVisitReportSdk } from '../../microfrontend-interfaces/IVisitReport';
import { TimberService } from '@logging/timber.service';
import { SessionInfo } from '../authentication/auth.service';
import { ShellContextService } from '../shell-context/shell-context.service';

export const VISIT_REPORT_NAME_FOR_LOGS = 'Visit Report Sdk';

export class ResourceLoadingError extends Error {

	constructor(message: string, public src: string, public durationSec: number, public originalError?: Error | ErrorEvent | Event) {
		super(message);
		this.name = 'ResourceLoadingError';
	}
}

export class ResourceLoadingSuccess {
	constructor(public durationSec: number) {
	}
}

export class BootstrapError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'BootstrapError';
	}
}

@Injectable({
	providedIn: 'root',
})
export class VisitReportService {

	private get assetsMapEndpoint() {
		return `${this.visitReportEndpoint}/assets.map.json`;
	}

	private get legacyAssetsMapEndpoint() {
		return `${this.visitReportEndpoint}/assets/assets.map.json`;
	}

	private get visitReportEndpoint() {
		return this.appConfigService.appSettings.visitReportEndpoint;
	}

	constructor(
		private globalSettings: GlobalSettingsService,
		private eupRoutesService: EupRoutesService,
		private appConfigService: AppConfigService,
		private translateService: TranslateService,
		private logger: TimberService,
		private shellContextService: ShellContextService
	) {}

	private compName = 'VisitReportService';

	private authContext$: Observable<SessionInfo> = this.shellContextService.getContext().pipe(
		filter(context => !!context.session.sessionId && !!context.security.accessToken),
		map(context => {
			return {
				sessionId: context.session.sessionId,
				accessToken: context.security.accessToken,
				sessionType: context.session.sessionType
			};
		})
	);

	private sdkPromise: Promise<IVisitReportSdk> | undefined;

	static loadJavaScript(containerToAttach: HTMLElement, src: string): Promise<ResourceLoadingSuccess> {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.crossOrigin = 'anonymous';
		scriptElement.src = src;
		const promise = new Promise<ResourceLoadingSuccess>((resolve, reject) => {
			const handleLoadSuccess = () => {
				scriptElement.removeEventListener('load', handleLoadSuccess);
				const endTimeMs = Date.now();
				resolve(new ResourceLoadingSuccess((endTimeMs - startTimeMs) / 1000));
			};

			const handleLoadError = (e: ErrorEvent | Event) => {
				scriptElement.removeEventListener('error', handleLoadError);
				const endTimeMs = Date.now();
				let message = `${VISIT_REPORT_NAME_FOR_LOGS} script loading failed due to the next reason:`;
				if (e instanceof ErrorEvent) {
					message += `message: ${e.message}, error ${e.error}`;
				} else {
					message += `unknown error with type: ${e.type}`;
				}
				reject(new ResourceLoadingError(message, src, (endTimeMs - startTimeMs) / 1000, e));
			};

			scriptElement.addEventListener('load', handleLoadSuccess);
			scriptElement.addEventListener('error', handleLoadError);
		});

		containerToAttach.appendChild(scriptElement);

		const startTimeMs = Date.now();

		return Promise.race([promise, this.promiseTimeout(src)]).catch(e => {
			scriptElement?.remove();

			return Promise.reject(e);
		});
	}

	static promiseTimeout(src: string, timeoutMs: number = 45_000): Promise<ResourceLoadingSuccess> {
		return new Promise((_, reject) => setTimeout(() => reject(new ResourceLoadingError(`${VISIT_REPORT_NAME_FOR_LOGS} script loading failed due to custom timeout ${timeoutMs}`, src, timeoutMs / 1000)), timeoutMs));
	}

	isNewVisitReportAvailable(): boolean {
		return !!this.visitReportEndpoint;
	}

	async initiallyLoadSdk(container: HTMLElement): Promise<IVisitReportSdk> {
		if (!this.sdkPromise) {
			this.sdkPromise = this.loadVisitReportScript(container);
		}
		try {
			return await this.sdkPromise;
		} catch (e) {
			if (e instanceof ResourceLoadingError) {
				this.logResourceLoadingError(`Unable to initially load ${VISIT_REPORT_NAME_FOR_LOGS}`, e);
			} else {
				this.logUnrecognizedError(`Unrecognized error during initial loading ${VISIT_REPORT_NAME_FOR_LOGS} script`, e);
			}
			throw e;
		}
	}

	async getMainFileName() {
		try {
			const response = await fetch(this.assetsMapEndpoint);
			const { 'main.js': main } = await response.json() as { 'main.js': string };
			return main;
		} catch (e) {
			this.logger.warn('could not load assets map with error, trying fallback to main without hash' + e, { module: this.compName });

			const response = await fetch(this.legacyAssetsMapEndpoint);
			const {  main } = await response.json() as { 'main': string };

			return main;
		}
	}


	async loadVisitReportScript(container: HTMLElement): Promise<IVisitReportSdk> {
		let error: unknown;

		try {
			const main = await this.getMainFileName();

			try {
				const resourceSuccess = await VisitReportService.loadJavaScript(container, `${this.visitReportEndpoint}/${main}`);

				this.logger.debug(`${VISIT_REPORT_NAME_FOR_LOGS} script loading succeed with duration: ${resourceSuccess.durationSec} sec.`, {module: this.compName });

				return window.visitReportSdk;
			} catch (e) {
				error = e;
			}
		} catch (e) {
			error = e;
		}

		if (error) {
			throw error;
		}
	}

	async createMicrofrontendVisitReport(orderId: number, parentEl: HTMLElement, tagName: string): Promise<HTMLElement> {
		let sdk: IVisitReportSdk;
		try {
			sdk = await this.sdkPromise;
		} catch {
			try {
				this.sdkPromise = this.loadVisitReportScript(parentEl);
				sdk = await this.sdkPromise;

			} catch (e) {
				if (e instanceof ResourceLoadingError) {
					this.logResourceLoadingError(`Unable to load ${VISIT_REPORT_NAME_FOR_LOGS} by user's click on the open button`, e);
				} else {
					this.logUnrecognizedError(`Unrecognized error during loading ${VISIT_REPORT_NAME_FOR_LOGS} script`, e);
				}
				throw e;
			}
		}
		try {
			const session = await firstValueFrom(this.authContext$);
			return this.bootstrapMicrofrontend(sdk, session, orderId, parentEl, tagName);
		} catch (e) {
			if (e instanceof BootstrapError) {
				this.logger.error(e.message, { module: this.compName });
			} else {
				this.logUnrecognizedError(`Unrecognized error during bootstrapping ${VISIT_REPORT_NAME_FOR_LOGS} app`, e);
			}
			throw e;
		}
	}

	private bootstrapMicrofrontend(sdk: IVisitReportSdk, authContext: SessionInfo, orderId: number, parentEl: HTMLElement, tagName: string): HTMLElement {
		try {
			const inputs: IVisitReportInputs = {
				orderId,
				parentEl,
				componentTagName: tagName,
				isMIDC: true,
				authInfo: {
					...authContext,
					authUrl: this.eupRoutesService.iTeroWebAuthApiUrl
				},
				...this.getInputParameters()
			};
			this.logger.info(`Start to bootstrapping ${VISIT_REPORT_NAME_FOR_LOGS} as a Web Component`, { module: this.compName });
			return sdk.bootstrapApp(inputs);
		} catch (e) {
			throw new BootstrapError(`bootstrapping ${VISIT_REPORT_NAME_FOR_LOGS} as a Web Component is failed with error: ${e?.message} and stack: ${e?.stack}`);
		}
	}

	async createVisitReportFromPackage(orderId: number, parentEl: HTMLElement, tagName: string): Promise<HTMLElement> {
		try {
			await import('@itero/visit-report-client/main-es5');

			const webComponent = document.createElement(tagName);
			const setting = this.getInputParameters();
			const reportInfo = {
				orderId: orderId,
				doctorId: setting.doctorId,
				companyId: setting.companyId,
				serverUrl: setting.serverUrl
			};
			webComponent.setAttribute('report-info', JSON.stringify(reportInfo));
			webComponent.setAttribute('language-code', setting.languageCode);
			webComponent.setAttribute('logger-url', setting.loggerUrl);

			parentEl.appendChild(webComponent);
			this.logger.info('Create visit report as npm package', { module: this.compName });

			return webComponent;
		} catch (e) {
			this.logger.error(`Loading NPM package of Visit Report is failed with error: ${e.message}`, { module: this.compName });

			throw e;
		}
	}

	getInputParameters() {
		const settings = this.globalSettings.get();
		return {
			doctorId: settings.selectedDoctorId.toString(),
			companyId: settings.selectedCompanyId.toString(),
			languageCode: this.translateService.currentLang,
			serverUrl: this.eupRoutesService.serverUrl,
			loggerUrl: this.appConfigService.appSettings.loggingEndpoint,
			staticFilesEndpoint: this.appConfigService.appSettings.visitReportEndpoint,
		};
	}

	private logResourceLoadingError(message: string, error: ResourceLoadingError) {
		const extendedParameters: Record<string, string> = {
			src: error.src,
			stack: error.stack,
			durationSec: String(error.durationSec),
		};

		if (error.originalError instanceof ErrorEvent) {
			extendedParameters['message'] = error.originalError.message;
			extendedParameters['colno'] = String(error.originalError.colno);
			extendedParameters['filename'] = error.originalError.filename;
			extendedParameters['lineno'] = String(error.originalError.lineno);
			extendedParameters['error'] = error.originalError.error;
		} else if (error.originalError instanceof Error) {
			extendedParameters['message'] = error.originalError.message;
			extendedParameters['name'] = error.originalError.name;
			extendedParameters['stack'] = error.originalError.stack;
		} else if (error.originalError instanceof Event) {
			extendedParameters['type'] = error.originalError.type;
		}


		this.logger.error(`${message}; ${error.name}: ${error.message}`, { module: this.compName, extendedParameters });
	}

	private logUnrecognizedError(msg: string, error: unknown) {
		let name = '';
		let message = '';
		if (typeof error === 'object' && error !== null) {
			if ('name' in error) {
				name = String(error.name);
			}

			if ('message' in error) {
				message = String(error.message);
			}
		}

		this.logger.error(`${msg}; ${name}: ${message}`, { module: this.compName });
	}
}
