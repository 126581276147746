<div id="notification-card" class="card">
    <div class="left">
        <div class="side-color"></div>
        <div class="icon-container">
            <svg class="icon"></svg>
        </div>
        <div class="content">
            <div class="title">{{ 'AttachmentsNotification.PendingAttachments' | translate }}</div>
            <div class="text">{{ 'AttachmentsNotification.AttachAndSend' | translate }}</div>
        </div>
    </div>
    <button class="button" (click)="onButtonClick()">{{ 'AttachmentsNotification.ViewOrders' | translate }}</button>
</div>
