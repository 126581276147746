<div class="export-download-component-list-container">
    <h3 class="clearfix">
        <span class="pull-left" *ngIf="(files$ | async)?.length > 1">{{'ExportDownloadsProgressList.TitleX' | translate:{'count': (files$ | async)?.length} }}</span>
        <span class="pull-left" *ngIf="(files$ | async)?.length === 1">{{'ExportDownloadsProgressList.Title' | translate }}</span>
        <span class="pull-right export-download-close" (click)="onClose()">&times;</span>
    </h3>
    <div class="download-list-container">
        <div class="download-item-container" *ngFor="let file of (files$ | async)">
            <div class="download-text-container">
                <span>{{'ExportDownloadsProgressList.Order' | translate }}</span>
                <span class="order-id margin-left">{{file.orderId}}</span>
            </div>

            <div class="download-info-container" *ngIf="file.status !== Status.Failed && file.status !== Status.Canceled">
                <div class="download-progressbar-container">
                    <div class="progressbar" [style.width]="file.progress + '%'"></div>
                </div>
                <div class="download-percent">
                    <div><span>{{floor(file.progress)}}</span><span>%</span></div>
                </div>
                <div class="download-cancel" (click)="cancel(file)">
                    {{'ExportDownloadsProgressList.Cancel' | translate }}
                </div>
            </div>

            <div class="download-info-container" *ngIf="file.status === Status.Canceled">
                <div class="download-message-container">
                    {{'ExportDownloadsProgressList.Cancelled' | translate }}
                </div>
                <div class="download-cancel margin-left" (click)="cancel(file)">
                    {{'ExportDownloadsProgressList.Remove' | translate }}
                </div>
            </div>

            <div class="download-info-container" *ngIf="file.status === Status.Failed">
                <div class="download-error-message-container">
                    {{'ExportDownloadsProgressList.Error' | translate }}
                </div>
                <div class="download-cancel margin-left" (click)="cancel(file)">
                    {{'ExportDownloadsProgressList.Remove' | translate }}
                </div>
            </div>

        </div>
    </div>
</div>
