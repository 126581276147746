import {Injectable} from '@angular/core';
import { interval, Subscription } from 'rxjs';
const Nanobar = require('nanobar/nanobar.min');

@Injectable()
export class NanobarService {
	private maxPercentage = 80;
	private removeAfterPercentage = 1000;
	private stepSize = 2;
	private stepInterval = 200;
	
	private serviceInterval: any = null;
	private subscription: Subscription;

	nanobarOptions = {};
	private nanobar: any;
	private numberOfUsers = 0;
	private stepNumber = 1;

	constructor() {
		this.nanobar = new Nanobar(this.nanobarOptions);
		this.serviceInterval = interval(this.stepInterval);
	}

	start() {
		this.numberOfUsers++;
		this.stepNumber = 1;
		if (!this.subscription) {
			this.step();
			this.subscription = this.serviceInterval.subscribe(() => this.step());
		} 
	}

	stop() {
		this.numberOfUsers--;
		if (this.subscription && this.numberOfUsers === 0) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
			// go(100) will close the processbar
			this.nanobar.go(100);
		}
	}

	private step() {
		const percentage = this.stepNumber * this.stepSize;
		this.stepNumber++;

		// in case users calls Start and don't call stop -> bar will stop automatically.
		if (percentage > this.removeAfterPercentage) {
			this.stop();
			return;
		}

		if (percentage >= 100 || percentage > this.maxPercentage) {
			return;
		}

		this.nanobar.go(percentage);
	}
}
