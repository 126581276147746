import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface RegulatoryConfigurationState {
	dateOfBirthMask?: string;
	maxPatientLastNameLength?: number;
	isChartNumberDisabled?: boolean;
	shouldGenerateChartNumber?: boolean;
	dateOfBirthFormat?: string;
}

export function createInitialState(): RegulatoryConfigurationState {
	return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'regulatory-configuration-store' })
export class RegulatoryConfigurationStore extends Store<RegulatoryConfigurationState> {

	constructor() {
		super(createInitialState());
	}
}

