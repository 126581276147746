import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeferredActionsService {
	private readonly deferredActions : Map<string, Subscription> = new Map<string, Subscription>();

	cancelAction(actionId: string) {
		const existingSubscr = this.deferredActions.get(actionId);
		if (!!existingSubscr) {
			existingSubscr.unsubscribe();
			this.deferredActions.delete(actionId);
		}
	}

	cancelAllActions() {
		this.deferredActions.forEach((subscription, _) => subscription.unsubscribe());
		this.deferredActions.clear();
	}

	scheduleAction(actionId: string, subscr: Subscription) {
		this.cancelAction(actionId);
		this.deferredActions.set(actionId, subscr);
	}
}
