import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { BaseDestroyable } from '@core/base-destroyable';
import { filter, forkJoin, map, of, switchMap, take, tap } from 'rxjs';
import { IOSimPlusIntegrationService } from 'app/services/iosim-plus-integration/iosim-plus-integration.service';
import { OrderSimulationInfo } from '@shared/iosim-plus/models/simulationStatus';
import { IOSimSimulationInfoQuery } from 'app/services/iosim-simulation.store/iosim-simulation-status-progress.query';

@Injectable({providedIn: 'root'})
export class GetIosimProgress extends BaseDestroyable implements ICommunicationEvent {
	constructor(private iosimSimulationInfoQuery: IOSimSimulationInfoQuery,
		private iosimPlusIntegrationService: IOSimPlusIntegrationService) {
        super();
    }
	
	get eventName(): string {
		return PlatformCommunicationEvents.GetIosimProgress;
	}

	async handleEvent(event: any, publishEventFunc: Function) {
		this.iosimPlusIntegrationService.getOrderSimulationInfo(event.data.orderId, event.data.companyId).pipe(
			switchMap((orderSimulationInfo: OrderSimulationInfo) => {
				return forkJoin([
						of(orderSimulationInfo), 
						this.iosimSimulationInfoQuery.selectEntity(event.data.orderId).pipe(take(1), map(store => store))
					]);
			}),
			filter(([orderSimulationInfo, store]) => !!store?.progress),
			map(([orderSimulationInfo, store]) => {
				return {
					expectedDuration: store?.progress.expectedDuration || +orderSimulationInfo?.expectedDuration,
					simulationStatus: `${store?.progress.simulationStatus}`,
					startSimulationTime: (new Date(store?.progress.startSimulationTime)).getTime(),
					notSupportedScanDate: store?.notSupportedScanDate ?? false,
					isQualifiedForIoSimPlus: orderSimulationInfo?.isQualifiedForIoSimPlus,
				};
			}),
			tap(async progress => {
				await publishEventFunc(PlatformCommunicationEvents.IosimProgressResult, 
					[event.eventHeader.publisher], progress);
			}),
			take(1)
		).subscribe();
	}
}