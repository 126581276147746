import { IKeyValuePair } from "@interfaces/IKeyValuePair";
import { applicationProgressMock } from "./application-progress-mock";
import { appsConfigResultMock } from "./apps-config-result-mock";
import { changeTitleMock } from "./change-title-mock";
import { contextBizctxUpdatedMock } from "./context-bizctx-updated-mock";
import { contextUiUpdatedMock } from "./context-ui-updated-mock";
import { externalApplicationFailedMock } from "./external-application-failed-mock";
import { getAppsConfigMock } from "./get-apps-config-mock";
import { handleErrorMock } from "./handle-error-mock";
import { initAppMetadataMock } from "./init-app-metadata-mock";
import { initContextMock } from "./init-context-mock";
import { iosimProgressResultsMock } from "./iosim-progress-results";
import { iosimStatusMock } from "./iosim-status-mock";
import { openApplicationMock } from "./open-application-mock";
import { pushNavigationStateMock } from "./push-navigation-state-mock";
import { screenshotResultsMock } from "./screenshot-results-mock";
import { sessionUpdatedMock } from "./session-updated-mock";
import { tokenExpiredMock } from "./token-expired-mock";
import { tokenRefreshedMock } from "./token-refreshed-mock";
import { contextMidcMock } from "./context-midc-mock";
import { contextScannerMock } from "./context-scanner-mock";

export const mocksDictionary: IKeyValuePair = {
    'application-progress': applicationProgressMock,
    'apps-config-result': appsConfigResultMock,
    'change-title': changeTitleMock,
    'context-bizctx-updated': contextBizctxUpdatedMock,
    'context-ui-updated': contextUiUpdatedMock,
    'external-application-failed': externalApplicationFailedMock,
    'get-apps=condig': getAppsConfigMock,
    'handle-error': handleErrorMock,
    'init-app-metadata': initAppMetadataMock,
    'init-context': initContextMock,
    'iosim-progress-results': iosimProgressResultsMock,
    'iosim-status': iosimStatusMock,
    'open-application': openApplicationMock,
    'push-navigation-state': pushNavigationStateMock,
    'screenshot-results': screenshotResultsMock,
    'session-updated': sessionUpdatedMock,
    'token-expired': tokenExpiredMock,
    'token-refreshed': tokenRefreshedMock,
    'context-midc': contextMidcMock,
    'context-scanner': contextScannerMock
}