import { HttpParams } from '@angular/common/http';
import {
	ExportTypeFormatEnum,
	ExportDataFormatEnum,
	FileTypeParallelEnum,
	ExcelExtensionsEnum,
	LabCasesState,
	GroupedStatus,
} from './enums';
import { Observable } from 'rxjs';
import { IKeyValue } from '../interfaces/IKeyValue';
import { LogLevel } from '@itero/timber/enums';
import { NavigationExtras } from '@angular/router';

export enum Sorting {
	Ascending = 0,
	Descending = 1,
}

export class PagedQueryParameters {
	pageNumber: number;
	pageSize: number;
	filter: string;
	sortField: string;
	companyId: number;
	doctorId: number;
	isScanImagesZipFileExist: string;
	sort: Sorting;
	existingTotal: number;
	defaultTotalValue: number;
	requestOriginScreen: string;
	getPendingOrders: boolean;

	constructor() {
		this.existingTotal = this.defaultTotalValue;
	}

	createSearchParams(): HttpParams {
		let params = new HttpParams();

		if (this.pageNumber) {
			params = params.set('PageNumber', this.pageNumber.toString());
		}

		if (this.pageSize) {
			params = params.set('PageSize', this.pageSize.toString());
		}

		if (this.sort) {
			params = params.set('Sort', this.sort.toString());
		}

		if (this.sortField) {
			params = params.set('SortField', this.sortField);
		}

		if (this.filter) {
			params = params.set('Filter', this.filter);
		}

		if (this.companyId) {
			params = params.set('CompanyId', this.companyId.toString());
		}

		if (this.doctorId) {
			params = params.set('DoctorId', this.doctorId.toString());
		}

		if (this.isScanImagesZipFileExist) {
			params = params.set('isScanImagesZipFileExist', this.isScanImagesZipFileExist.toString());
		}

		if (!isNaN(this.existingTotal)) {
			params = params.set('existingTotal', this.existingTotal.toString());
		}

		if (this.requestOriginScreen) {
			params = params.set('RequestOriginScreen', this.requestOriginScreen.toString());
		}

		if (this.getPendingOrders) {
			params = params.set('GetPendingOrders', this.getPendingOrders);
		}

		return params;
	}
}

export class ContactQueryParameters extends PagedQueryParameters {
	isAdmin: boolean;
	isPrimaryContact: boolean;
	isVisibleToAllOrders: boolean;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams();

		if (this.isAdmin) {
			params = params.set('isAdministrator', this.isAdmin.toString());
		}

		if (this.isPrimaryContact) {
			params = params.set('isPrimaryContact', this.isPrimaryContact.toString());
		}

		if (this.isVisibleToAllOrders) {
			params = params.set('isVisibleToAllOrders', this.isVisibleToAllOrders.toString());
		}

		return params;
	}
}

export class LabOrdersPagedQueryParameters extends PagedQueryParameters {
	filterMode: LabCasesState;
	dateFormat: string;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams();
		if (this.filterMode) {
			params = params.set('filterMode', this.filterMode.toString());
		}
		if (this.dateFormat) {
			params = params.set('dateFormat', this.dateFormat.toString());
		}
		return params;
	}
}

export class ActivityReportPagedQueryParameters extends PagedQueryParameters {
	fromDate: Date;
	toDate: Date;

	createSearchParams(): HttpParams {
		let params = super.createSearchParams();
		if (this.fromDate) {
			params = params.set('from', this.fromDate.toUTCString());
		}
		if (this.companyId) {
			params = params.set('to', this.toDate.toUTCString());
		}
		return params;
	}
}

export class PagedQueryResult<TResult extends any = any> {
	results: TResult[];
	pageSize: number;
	totalItemCount: number;
	totalPageCount: number;
}

export interface IEupRxForm {
	version: number;
	id?: number;
	guid: string;
	patientName: string;
	chartNumber: string;
	scanDate: string;
	isTakenByScanner: boolean;
	status: string;
	statusId?: number;
	groupedStatus: GroupedStatus;
	companyId: number;

	caseTypeId: number;
	caseTypeDescription: string;
	canViewRx: boolean;
	isActive: boolean;
	dateUpdated: string;
	isTaggedForSent: boolean;
	isFailedToSend: boolean;

	firstName: string;
	lastName: string;
	isAlta?: boolean;
	hasNIRI: boolean;
	nirIhasOnlyIOC: false;
	toothNumberingSystem: number;
	mi?: string;
	referral: ReferralResponse;

	procedureId?: number;
	procedureMapId?: number;
	procedureTypeId?: number;
	procedureDescription?: string;
}

export interface IDisplayedRxForm extends IEupRxForm {
	shouldRxBeReadOnly: boolean;
	shouldDisplayDeleteButton: boolean;
	referralExist: boolean;
}

export interface IRxForm {
	Teeth: any[];
	Bridges: any[];
	Notes: string;
	OrderInformation: any[];
	// Not full Rx model, enrich it on demand
}

export interface ITableService {
	requestPage: (query: PagedQueryParameters) => Observable<PagedQueryResult>;
	getRows: () => any[];
	id?: string;
}

export class RxRunMode {
	static View = 'View';
	static Edit = 'Edit';
}

export class Contact {
	contactId: number;
	contactName: string;
}

export class ContactWithBusinessPartners {
	contactId: number;
	contactName: string;
	businessPartners: Company[];
	contactTitle: string;
}

export class Company {
	id: number;
	name: string;
	businessPartnerTypeNames: string;
	isDentalClinic: boolean;
	currentUserHasFullOrdersVisibility: boolean;
	currentUserIsAccountAdmin: boolean;
	highestScannerVersion: string;
	scannerDetails: ScannerDetails[];
	contacts: Contact[];
	region: string;
	handlingEupUrl: string;
	countryCode: number;
	companyTitle: string;
}

export class Language {
	id: number;
	code: string;
	localizedName: string;
	name: string;
	isSupportedForLab: boolean;
	isSupportedForDoctor: boolean;
}

export class UserLanguage {
	code: string;
	description: string;
	englishName: string;
}

export class LocaleData {
	languages: Language[];
	dateFormats: string[];
}

export class ScannerDetails {
	description: string;
	softwareVersion: string;
	baseSerialNumber: string;
	wandSerial: string;
	companyId: number;
	iOsimVersion: string;
}

export class KeyValuePair implements IKeyValue {
	constructor(public key: string, public value: any) {}
}

export class ExportRequestDto {
	clientId: number;
	orderId: number;
	workOrderId?: number;
}

export class PasswordPolicyConfig {
	showPasswordPolicyNotification: boolean;
	mustChangePasswordLastDate: string;
	passwordPolicyNotificationApproved = false;
	mustChangePasswordNow: boolean;
	isTemporaryPassword: boolean;
}

export class ExcelExportOptions {
	data: any[];
	fileName: string;
	extension: ExcelExtensionsEnum;
	sheetName: string;
	/** The keys are the proprties' names of the object that is being exported to excel */
	/** The values can be any strings represent the headers of the excel file or keys for headers' translations */
	headers: KeyValuePair[];
}

export class ChangeCaseTypeEmitData {
	orderId: number;
	caseTypeId: number;
	caseTypeDescription: string;
}

export class UpdatePatientData {
	doctorId: number;
	patientGuid: string;
	firstName: string;
	lastName: string;
	chartNumber: string;
	companyId: number;
}

export class PatientDto {
	id: string;
	fullName: string;
	firstName: string;
	lastName: string;
	mi: string;
	dateOfBirth: string;
	gender: string;
	zipCode: string;
	chartNumber: string;
	alignPatientId: string;
	uid: string;
	lastScanDate: Date;
	type: number;
}

export interface IScannerVersionsPerFeature {
	element: string;
	evx: string;
}

export interface ApiError {
	status: StatusCode;
	error: string;
	headers: any;
}

export enum StatusCode {
	Ok = 200,
	ContentCreated = 201,
	NoContent = 204,
	Redirect = 302,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	ContentNotFound = 404,
	Conflict = 409,
	Locked = 423,
	InternalServerError = 500,
	Timeout = 504,
}

export enum RxiFrameEvents {
	GetAvailableCaseTypes = 1,
	RenewSession = 2,
	SaveOrderRxForm = 3,
	SaveRx = 4,
	LoadConfiguration = 5,
	LoadContactConfiguration = 6,
	LoadUserSettings = 7,
	GetRxByOrderId = 8,
	GetRxById = 9,
	GetPatientByUid = 10,
	CheckPatientExistBy = 11,
	FlushInstrumentationLogs = 12,
	EupHomeClick = 13,
	RxSaveEventToiFrameParentNavigateBack = 14,
	RxSaveEventToiFrameParentCanceled = 15,
	RxLoadCompleted = 16,
	GetScannerPatientsByDoctor = 17,
	IsAltaEnabled = 18,
	GetScannerDoctorIdsAddresses = 19,
	GetIdsPatientsForCurrentDoctor = 20,
	GetOrderInformationConfiguration = 21,
	GetOrderToSaveAndProceed = 22,
	GetOrderToSaveAndRescan = 23,
	GetOrderToSaveAndProceedToModeling = 24,
	GetLabNotes = 25,
	SetLabNote = 26,
	ReloadWithoutUserConfirmation = 27,
	GenerateReferralCode = 28,
	ReferralCodeReceived = 29,
	PatientAppRedirect = 106,
}

export enum NavigationEvents {
	PushNavigationState = 'push-navigation-state',
	ExternalApplicationFalied = 'external-application-failed',
}

export enum ShellCommunicationEvents {
	GetContext = 'itero_shell_get-context',
	InitContext = 'itero_{0}_init-context',
	LanguageChanged = 'itero_everyone_language-changed',
	HandleError = 'itero_shell_handle-error',
	TokenExpired = 'itero_shell_token-expired',
	CompanyChanged = 'itero_everyone_company-changed',
	OpenApplication = 'open-application',
	BackButtonClicked = 'back-button-clicked',
	PushNavigationState = 'push-navigation-state',
	PrepareToClose = 'prepare-to-close',
	CloseMeEvent = 'close-me',
	AppLoaded = 'application-loaded',
	AppClosed = 'application-closed',
	AppProgress = 'application-progress',
	AppReady = 'application-ready',
	ClosingRejected = 'closing-rejected',
	CloseMe = 'close-me',
}

export enum PlatformCommunicationEvents {
	GetApplicationsConfiguration = 'get-apps-config',
	ApplicationsConfigurationResult = 'apps-config-result',
	ContextBizCtxUpdated = 'context-bizctx-updated',
	ContextUiUpdated = 'context-ui-updated',
	ApplicationLoaded = 'application-loaded',
	InitContext = 'init-context',
	TokenExpired = 'token-expired',
	TokenRefreshed = 'token-refreshed',
	HandleError = 'handle-error',
	ChangeTitle = 'change-title',
	ClearTitle = 'clear-title',
	GetIosimProgress = 'get-iosim-progress',
	IosimProgressResult = 'iosim-progress-results',
	StartIosimSimulation = 'start-iosim-simulation',
	BackButtonClicked = 'back-button-clicked',
	ScreenshotEnabled = 'screenshot-enabled',
	ScreenshotClicked = 'screenshot-clicked',
	ScreenshotResults = 'screenshot-results',
}

export enum CommunicationChannels {
	ApplicationChannel = 'application-channel',
	PlatformChannel = 'platform-channel',
}

export enum StickyHeaderEvents {
	ScreenshotEnabled = 'screenshot-enabled',
	ScreenshotClicked = 'screenshot-clicked',
	ScreenshotResults = 'screenshot-results',
	BackButtonClicked = 'back-button-clicked',
	ChangeTitle = 'change-title',
	ClearTitle = 'clear-title',
}

export enum LaunchAppEvents {
	OpenApplication = 'open-application',
	InitAppMetadata = 'init-app-metadata',
	GetAppsConfig = 'get-apps-config',
	AppsConfigResult = 'apps-config-result',
}

export enum IosimEvents {
	GetIosimProgress = 'get-iosim-progress',
	IosimProgressResults = 'iosim-progress-results',
}

export enum ContextEvents {
	BizCtxUpdated = 'context-bizctx-updated',
	CtxUiUpdated = 'context-ui-updated',
	TokenRefreshed = 'token-refreshed',
	TokenExpired = 'token-expired',
	InitContext = 'init-context',
	SessionUpdated = 'session-updated',
}

export enum PatientIframeEvents {
	PatientAppGeneral = 100,
	PatientAppAdd = 101,
	PatientAppSelectedPatient = 102,
	PatientAppUpdatePatient = 103,
	PatientAppSelectedPatientConflict = 104,
	PatientAppRedirect = 106,
}

export class ExportSettings {
	constructor(
		public exportTypeIrecord: ExportTypeFormatEnum = 0,
		public dataFormat: ExportDataFormatEnum = 0,
		public fileType: FileTypeParallelEnum = 0,
		public cadCamSystemTypeId: number = 0
	) {}
}

export class SignalFx {
	beaconUrl: string;
	rumAuth: string;
	app: string;
	environment: string;
	isEnabled: boolean;
	timeoutMs: number;
}

export class AdaChatBot {
	adaScriptUrl: string;
	adaDataHandle: string;
}

export class NgBannerUrls {
	apac: string;
	eu: string;
	na: string;
}

export class AppSettings {
	ExocadMiniAppInstallerBuilderUrl: string;
	apiEndpoint: string;
	notificationHub: string;
	loggingEndpoint: string;
	minLogLevel: string;
	patientAppEndpoint: string;
	rxAppSdkEndpoint: string;
	visitReportEndpoint: string;
	isProduction: boolean;
	signalFx: SignalFx;
	minimumCompaniesForFiltering: number;
	googleMapsApi: string;
	biEndpoint: string;
	learnEndPoint: string;
	adaChatBot: AdaChatBot;
	exocadMiniAppInstallerUrl: string;
	ngBannerUrls: NgBannerUrls;
	pendoApiKey: string;
	pendoFeatureFlagsPrefix: string;
}

export class PendoConfig {
	constructor(public appsettings: AppSettings = new AppSettings()) {}
}

export class AppConfig {
	constructor(public appSettings: AppSettings = new AppSettings()) {}
}

export interface IMyIteroException {
	StatusCode: string;
	StatusDescription: string;
	Source: string;
	ExceptionMessage: string;
	ExceptionStackTrace: string;
	RouteData: string;
}

export class ViewerSoftwareOptionsResponse {
	enableNiriReviewTool = false;
	enableIntraoralCameraTool = false;
	shouldDisableTextureMapping = false;
	enableTextureMappingInViewer = false;
}

export class SnapshotResponse {
	constructor(public shouldDisableCapture = false, public shouldDisableDrawingTool = false) {}
}

export class IdsPairingData {
	externalUsername: string;
	contactId: number;
	refreshToken: string;
	zipCodeRequired: boolean;
}

export class OAuthToken {
	refresh_token: string;
	expires_in: number;
	access_token: string;
	scope: string;
	token_type: string;
}

export class IdsPairingStatus {
	isPaired: boolean;
	url: string;
}
export interface ReferralResponse {
	id: string;
	referralCode: string;
	originalRxId: string;
	expiredDate: string;
	rowStatusId: number;
	isLoadedByScanningCenter: boolean;
}

export interface ActivateReferralResponse {
	referral: ReferralResponse;
	referralStatus: string;
	rxForm: any;
}

export interface SessionExpiredEventInfo {
	appId: string;
	sessionId: string;
	payload: SessionExpiredEventPayload;
}
export interface SessionExpiredEventPayload {
	returnUrl: string;
}

export interface ClientApplicationConfiguration {
	appId: string;
	url: string;
	appMetadata: string;
}

export interface NavigationInfo {
	transitionId: number;
	navigatedFromAppId: string;
	urlFrom: string;
	navigatedToAppId: string;
	appMetadata: string;
}

export class CachedData<T> {
	data: T;
	expiresOn: number;

	constructor(data: T, expirationInMilliseconds: number) {
		this.data = data;
		this.expiresOn = new Date().getTime() + expirationInMilliseconds;
	}
}

export interface ChangeTitlePayload {
	title: string;
	logo: string;
	logoFallback: string;
}

export interface IosimInfo {
	orderId: string;
	orderCode: string;
	viewedScreen: string;
	isQualifiedForIoSimPlus: boolean;
}
