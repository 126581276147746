import { KnownComponentsNames } from '@core/components-names';
import { CommunicationChannels } from 'app/shared/generalInterfaces';

export const eventToAppIdMap: { [key: string]: {publisherAppId: string, targetApplications: string[], channel: CommunicationChannels} } = {
  'platform-ready': { 
    publisherAppId: 'platform',
    targetApplications: ['app-shell'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'application-loaded': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'application-closed': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'application-ready': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'application-progress': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'handle-error': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'prepare-to-close': { 
    publisherAppId: 'app-shell',
    targetApplications: [KnownComponentsNames.AohsComponent],
    channel: CommunicationChannels.PlatformChannel,
  },
  'closing-rejected': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['app-shell'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'close-me': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['app-shell'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'push-navigation-state': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['app-shell'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'external-application-failed': { 
    publisherAppId: 'platform',
    targetApplications: ['app-shell'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'context-bizctx-updated': { 
    publisherAppId: 'platform',
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'context-ui-updated': { 
    publisherAppId: 'platform',
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'token-refreshed': { 
    publisherAppId: 'platform',
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'token-expired': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'init-context': { 
    publisherAppId: 'platform',
    targetApplications: [KnownComponentsNames.AohsComponent],
    channel: CommunicationChannels.PlatformChannel,
  },
  'session-updated': { 
    publisherAppId: 'platform',
    targetApplications: ['*'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'get-iosim-progress': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'iosim-progress-results': { 
    publisherAppId: 'platform',
    targetApplications: [KnownComponentsNames.AohsComponent],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'open-application': { 
    publisherAppId: 'app-shell',
    targetApplications: ['app-shell', 'platform'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'init-app-metadata': { 
    publisherAppId: 'app-shell',
    targetApplications: ['*'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'get-apps-config': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.PlatformChannel,
  },
  'apps-config-result': { 
    publisherAppId: 'platform',
    targetApplications: [KnownComponentsNames.AohsComponent],
    channel: CommunicationChannels.PlatformChannel,
  },
  'screenshot-enabled': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'screenshot-clicked': { 
    publisherAppId: 'platform',
    targetApplications: ['*'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'screenshot-results': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'change-title': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.ApplicationChannel,
  },
  'clear-title': { 
    publisherAppId: KnownComponentsNames.AohsComponent,
    targetApplications: ['platform'],
    channel: CommunicationChannels.ApplicationChannel,
  },
};
