import { Injectable } from '@angular/core';

import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { PasswordPolicyPopupComponent } from './passwordPolicyPopup.component';
import { GlobalSettingsService } from '../globalSettings.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginSettingsModal } from '../../settings/login/loginSettings.component';
import { DomService } from '../dom.service';
import { Subscription } from 'rxjs';

@Injectable()
export class PasswordPolicyGuard  {
	constructor(
		private globalSettings: GlobalSettingsService,
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private domService: DomService
	) {}

	private settings: any;
	private ppc: any;
	private popupTimeout = 0;
	private passwordPolicyPopupSubscription: Subscription;

	canActivate() {
		this.settings = this.globalSettings.get();
		if (this.settings) {
			this.ppc = this.settings.passwordPolicyConfig;
			if (this.ppc.mustChangePasswordNow) {
				// open pop-up to alert that user must now change their password
				this.showPasswordChangePopUp(true);
			} else if (
				this.ppc &&
				this.ppc.showPasswordPolicyNotification &&
				!this.ppc.passwordPolicyNotificationApproved &&
				this.ppc.mustChangePasswordLastDate
			) {
				// open pop-up to alert that user has 'x' number of days to change their password
				this.showPasswordChangePopUp(false);
			} else if (this.ppc.isTemporaryPassword){
				this.showPasswordChangePopUp(false, true);
			}
		}
		return true;
	}

	showPasswordChangePopUp(mustChangePassword: boolean, isTemporaryPassword: boolean = false) {
		const needsImmediatePasswordChange = mustChangePassword || isTemporaryPassword;
		const options = {
			buttonCombination: ModalKeys.Ok,
			icon: needsImmediatePasswordChange ? ModalIcon.Error : ModalIcon.Info
		};

		const title = this.translateService.instant('PasswordPolicy.AlertPopup_Title');

		const data = {
			passwordPolicyConfig: this.ppc,
			dateFormat: this.settings.dateFormat,
			currentLang: this.settings.selectedLanguage.code
		};

		this.passwordPolicyPopupSubscription = this.notificationService
			.showTemplate(PasswordPolicyPopupComponent, title, data, options)
			.subscribe(() => {
				if (!needsImmediatePasswordChange) {
					this.ppc.passwordPolicyNotificationApproved = true;
					this.globalSettings.initialize(this.settings);
				} else {
					this.showLoginSettingsModal();
				}

				if (this.passwordPolicyPopupSubscription) {
					this.passwordPolicyPopupSubscription.unsubscribe();
				}
			});
	}

	showLoginSettingsModal() {
		const componentRef = this.domService.appendComponentToBody(LoginSettingsModal);
		const instance = <LoginSettingsModal>componentRef.instance;
		instance.updateSecurityQuestionsData();
		instance.onHide.subscribe(() => {
			this.domService.removeComponent(componentRef);
		});
		setTimeout(() => {
			instance.show();
		}, 0);
	}
}
