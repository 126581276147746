import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccountPairingComponent} from '../../components/account-pairing/account-pairing.component';
import {GenericUIModule} from '@shared/genericUI/module/generic-ui/generic-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { WeChatPairingService } from '../../services/wechat-pairing.service';

@NgModule({
	declarations: [AccountPairingComponent],
	imports: [
		CommonModule,
		GenericUIModule,
		TranslateModule
	],
	exports: [AccountPairingComponent],
	providers: [WeChatPairingService]
})
export class AccountPairingModule { }
