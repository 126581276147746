import { Injectable, OnDestroy } from "@angular/core";
import { GlobalSettingsService } from "@core/globalSettings.service";
import { AuthService } from "../authentication/auth.service";
import { TimberService } from "./timber.service";
import { tap, takeUntil, filter, map, distinctUntilChanged } from "rxjs/operators";
import { BaseDestroyable } from "@core/base-destroyable";
import { ISessionTagsEvent, ITags } from "./interfaces/bi.events";

@Injectable({ providedIn: 'root' })
export class LoggingInitializerService extends BaseDestroyable implements OnDestroy {
    constructor(private globalSettingsService: GlobalSettingsService, 
        private authService: AuthService, 
        private timberService: TimberService) {
        super();
    }

    initialize() {
        let globalSettings = this.globalSettingsService.get();
        this.timberService.initialize(globalSettings ? globalSettings.contactId : undefined, globalSettings ? globalSettings.selectedCompanyId : undefined);
        this.trackUserChanges();
        this.trackCompanyChanges();
        this.trackLogIn();
        this.trackLogOut();
    }

    private trackUserChanges(){
        this.globalSettingsService.contactIdChanged$.pipe(takeUntil(this.componentAlive$),
            tap(contactId => {
                this.timberService.setUserConfiguration(contactId);
                this.timberService.info(`User has changed, contactId: ${contactId}`, { module: 'LoggingInitializerService' });
            })
        ).subscribe();
    }

    private trackCompanyChanges(){
        this.globalSettingsService.contextChanged.pipe(takeUntil(this.componentAlive$),
            map(context => context.companyId),
            filter(companyId => !!companyId),
            distinctUntilChanged(),
            tap(companyId => {
                {
                    this.timberService.setCompanyConfiguration(companyId);
                    this.timberService.info(`Company has changed, companyId: ${companyId}`, { module: 'LoggingInitializerService' });
                    this.timberService.sessionTagsEvent({
                            tags: {
                                companyProfile: companyId.toString()
                            } as ITags
                        } as ISessionTagsEvent);
                }
            })
        ).subscribe();
    }

    private trackLogIn(){
        this.authService.authStatus$.pipe(takeUntil(this.componentAlive$),
            filter(v => v.isAuthenticated && v.userId > 0),
            tap(data => {
                this.timberService.setUserConfiguration(data.userId);
                this.timberService.info(`User logged in ${data.userId}`, { module: 'LoggingInitializerService' });
            })
        ).subscribe();
    }

    private trackLogOut(){
        this.authService.authStatus$.pipe(takeUntil(this.componentAlive$),
            filter(v => !v.isAuthenticated),
            tap(data => {
                this.timberService.clearUserConfiguration();
                this.timberService.info(`User logged out ${data.userId}`, { module: 'LoggingInitializerService' });
            })
        ).subscribe();
    }
}