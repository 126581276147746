import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';

@Injectable()
export class CacheManagementService {

	constructor(private http: EupHttpHandler,
              private eupRoutesService: EupRoutesService) {
                
	}
	
	deleteCache(key: string): Observable<any> {
		const deleteCacheUrl = this.eupRoutesService.cache.getDeleteCacheUrl(key);
		return this.http.post(deleteCacheUrl);
	}
}
