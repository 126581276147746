import { ExportStatus, ExportTypeFormatEnum, ExportDataFormatEnum, FileTypeParallelEnum } from '../enums';

export interface DownloadFileInfo {
  orderId: number;
  orderDetailsId: number;
  workOrderId?: number;
  downloaded: boolean;
  requestData: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto;
}

export interface FileInfo extends DownloadFileInfo
{
	progress: number;
  	status: FileInfoStatus;
}

export enum FileInfoStatus {
	NoStatus = -1,
	Active = 3,
	Completed = 4,
	Failed = 5,
	Canceled = 6
}

export class ExportInfoDto {
	status: ExportStatus;
  	isFileExists: boolean;
	orderId?: number;
	workOrderId?: number;
	requestData: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto;
}

export class CheckIfFileReadyResult {
	orderId: number;
	isFileExists: boolean;
}

export class BaseExportFileRequestDto {
	clientId: number;
	orderId: number;
	orderDetailsId: number;
	patientAnonymization: boolean;
	creationTime: string;
  }

export class CreateOrthodonticsExportFileRequestDto extends BaseExportFileRequestDto {
	typeFormat: ExportTypeFormatEnum;
	fileFormat: ExportDataFormatEnum;
	fileType: FileTypeParallelEnum;
}

export class CreateRestorativeExportFileRequestDto extends BaseExportFileRequestDto {
	cadCamSystemTypeId: number;
}

export class CreateExportFilesRequestDto {
	orthodonticsExportRequest?: {
		dtos: CreateOrthodonticsExportFileRequestDto[];
	};
	restorativeExportRequest?: {
		dtos: CreateRestorativeExportFileRequestDto[];
	};
}