import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import {BaseDestroyable} from '../../core/base-destroyable';
import { Subscription } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { SettingsService } from '../../shared/settings.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { TimberService } from '@logging/timber.service';
import { BiAction, BiLocationObject, BiObjectType } from '@logging/interfaces/bi.enums';

@Component({
	selector: 'eup-notifications-settings-modal',
	templateUrl: './notificationsSettings.component.html',
	styleUrls: ['./notificationsSettings.component.scss']
})
export class NotificationsSettingsModal extends BaseDestroyable implements OnDestroy {
	@ViewChild('notificationsSettingsModal') notificationsSettingsModal: ModalDirective;
	primaryEmail: string;
	allowEmailNotifications: boolean;
	getSettingsSubscription: Subscription;
	setSettingsSubscription: Subscription;

	constructor(
		public translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService,
		private settingsService: SettingsService,
		private shellContextService: ShellContextService,
		private timberService: TimberService
	) {
		super();
	}

	show(): void {
		this.primaryEmail = this.globalSettingsService.get().primaryEmail;
		this.getSettingsSubscription = this.shellContextService.getContext().pipe(
				take(1), mergeMap(context => this.settingsService.getSettings(context.bizCtx.companyId)))
			.subscribe(settingsRes => {
				this.allowEmailNotifications = settingsRes.userSettings.allowEmailNotifications;
			});

		this.notificationsSettingsModal.show();
	}
	
	onSubscribeCheck(value: boolean):void {
		this.timberService.userActionEvent({
			objectName: 'NotificationsCheckBox',
			action: BiAction.Clicked,
			objectType: BiObjectType.Checkbox,
			locationParentObject: BiLocationObject.NotificationsSettings,
			selectedValue: value.toString()
		})

		this.allowEmailNotifications = value;
		this.setSettingsSubscription = this.settingsService.setSettings('AllowEmailNotifications', JSON.stringify(this.allowEmailNotifications))
			.pipe(takeUntil(this.componentAlive$)).subscribe();
	}
}
