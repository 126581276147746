import { Component, OnInit } from '@angular/core';


@Component({
	templateUrl: './pageNotFound.component.html'
})
export class PageNotFoundComponent implements OnInit {
	ngOnInit(): void {
	}
}
