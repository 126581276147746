export enum BiEventType {
    UserAction = 'UserActionType',
    UiNavigation = 'UiNavigationType',
    ErrorReport = 'ErrorReportType',
    APIReport = 'ApiReportType',
    SessionTags = 'SessionTagsType',
    SessionStart = 'SessionStartType',
};

export enum BiAction {
    Clicked = 'Clicked'
}

export enum BiLocationObject {
    Orders = 'Orders',
    PatientsOrders = 'PatientsOrders',
    WebViewer = 'WebViewer',
    NotificationsSettings = 'NotificationsSettings',
    Email = 'Email',
    Home = 'Home',
    Patients = 'Patients',
    RxContainer = 'Rx',
    Messages = 'Messages',
    PracticeManagement = 'AccountManagement',
    Education = 'LearningCenter',
    Analytics = 'Analytics'
}

export enum BiObjectType {
    Button = 'Button',
    Checkbox = 'CheckBox',
    Link = 'Link'
}