import {Injectable} from '@angular/core';
import {Appearance} from '@shared/enums';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

export const DEFAULT_THEME = Appearance.Dark;

@Injectable()
export class AppearanceService {
	appearanceChanged$: Observable<Appearance>;
	responsiveChanged$: Observable<boolean>;

	private appearanceChangeSource: BehaviorSubject<Appearance>;
	private responsiveChangeSource: BehaviorSubject<boolean>;
	private currTheme: Appearance;

	constructor() {
		this.currTheme = DEFAULT_THEME;
		this.appearanceChangeSource = new BehaviorSubject(this.currTheme);
		this.appearanceChanged$ = this.appearanceChangeSource.asObservable().pipe(
			distinctUntilChanged()
		);

		this.responsiveChangeSource = new BehaviorSubject(false);
		this.responsiveChanged$ = this.responsiveChangeSource.asObservable().pipe(
			distinctUntilChanged()
		);
	}

	private get theme() {
		return this.currTheme;
	}

	private set theme(theme: Appearance) {
		this.currTheme = theme;
		this.appearanceChangeSource.next(this.currTheme);
	}

	toTheme(theme: Appearance) {
		this.theme = theme;
	}

	toLightTheme() {
		this.theme = Appearance.Light;
	}

	toDarkTheme() {
		this.theme = Appearance.Dark;
	}

	setResponsive(isResponsive: boolean | undefined) {
		this.responsiveChangeSource.next(!!isResponsive);
	}
}
