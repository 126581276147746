import { Injectable } from '@angular/core';
import { ScannerDetails } from '../../shared/generalInterfaces';
import { featuresLimitedToMinScannerVersion } from '../../../assets/consts';
import { Features, ScannerModel } from '../../shared/enums';
import { Utils } from '../../shared/utils.service';


@Injectable()
export class FeatureAvailabilityService {
  
	constructor(
	  private utils: Utils
	) { }

	compareScannerVersions(scanner: ScannerDetails, feature: Features, scannerModel: string) {
		return this.utils.compareVersions(scanner.softwareVersion, featuresLimitedToMinScannerVersion[feature.valueOf()][scannerModel]) >= 0;
	}

	scannerHasMinimumScannerVersionForFeature(scanner: ScannerDetails, feature: Features): boolean {
		const scannerModel = this.utils.getScannerModelBySwVersion(scanner);
		if (scannerModel === ScannerModel.other) {
			return true;
		}
		if (scannerModel) {
			return this.compareScannerVersions(scanner, feature, scannerModel);
		}
		return false;
	}
}