<table class="table table-clickable" [ngClass]="{'scroll-header': isScrollable}" (click)="onTableClickedEvent($event)">
    <ng-content select="[header]"></ng-content>
</table>
<div class="scrollable" #innerTableDiv
     infinite-scroll
     [infiniteScrollDistance]="3"
     [infiniteScrollThrottle]="100"
     [scrollWindow]="false"
     (scrolled)="onScroll()"
     [infiniteScrollDisabled]="!pagingOnScroll || !shouldGetMoreData">
    <table class="table table-striped table-hover table-clickable inner-table" (click)="onTableClickedEvent($event)" #innerTable
           [ngClass]="{'inner-table-overflow-x': preventOverflowX}">
        <ng-content select="[body]"></ng-content>
        <div *ngIf="platformDetector.isEdge && !showNoResults()" class="placeholder">Placeholder</div>
    </table>
    <div class="no-results" *ngIf="showNoResults()">
        <div>{{'Messages.NoMatches' | translate }}</div>
    </div>
</div>
