import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoutingData } from '../../core/eupRoutes.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { PlatformCommunicationEvents, SessionExpiredEventPayload } from '../../shared/generalInterfaces';
import { Router } from '@angular/router';
import { AuthenticationStatus } from './models/authentication-status';
import { Consts } from '@shared/consts';
import { ShellContextService } from '../shell-context/shell-context.service';
import { PlatformCommunicationService } from 'app/platform/services/platform-communication/platform-communication.service';
import { Location } from '@angular/common';
import { SessionInfo } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class TokenRefreshService {
	private isRefreshing = false;
	private tokenRefreshed$ = new BehaviorSubject<any>(null);

	authStatus$: BehaviorSubject<AuthenticationStatus>;

	constructor(private router: Router,
				private shellContextService: ShellContextService,
				private httpClient: HttpClient,
				private platformCommunicationService: PlatformCommunicationService,
				private location: Location	
	) {
	}

	refreshToken(sessionInfo?: SessionInfo) : Observable<string> {
		if (this.isRefreshing) {
			return this.tokenRefreshed$.pipe(take(1));
		}

		this.isRefreshing = true;
		sessionInfo = sessionInfo ?? JSON.parse(localStorage.getItem(Consts.Storage.SessionInfo)) as SessionInfo;

		const routes = JSON.parse(localStorage.getItem(Consts.Storage.Routes)) as RoutingData;
		const iTeroWebAuthApiUrl = routes?.iTeroWebAuthApiUrl;
		const refreshSessionUrl = `${iTeroWebAuthApiUrl}session/refresh?sessionId=${sessionInfo.sessionId}&appId=midc`;

		return this.httpClient.get<SessionInfo>(refreshSessionUrl).pipe(
			map((updatedSessionInfo: SessionInfo) => {
				this.updateSessionInfo(updatedSessionInfo);
				this.tokenRefreshed$.next(updatedSessionInfo.accessToken);

				return updatedSessionInfo.accessToken;
			}),
			catchError(error => {
				this.dispatchExpiredTokenEvent(sessionInfo.sessionId);

				return throwError({...error, refreshRequest: true, skipEupHttpErrorHandler: true });
			}),
			finalize(() => {
				this.isRefreshing = false;
			})
		);
	}

	dispatchExpiredTokenEvent(sessionId: string) {
		const payload: SessionExpiredEventPayload = { returnUrl: this.getReturnUrl() };
	
		const sessionExpired = new CustomEvent('midc_event_onTokenExpired', {
			detail: {
				appId: 'midc',
				sessionId,
				payload: payload.returnUrl.includes('mfe-wrapper') ? {} : payload
			},
		  });
		window.dispatchEvent(sessionExpired);
	}

	private getReturnUrl() {
		const currentUrl = this.location.path();
		const params = this.router.parseUrl(currentUrl).queryParams;
		if (params.returnUrl) {
			return params.returnUrl;
		} else if (currentUrl) {
			return currentUrl;
		}
	}

	private updateSessionInfo(updatedSessionInfo: SessionInfo) {
		updatedSessionInfo.sessionType = Consts.SessionType;
		localStorage.setItem(Consts.Storage.SessionInfo, JSON.stringify({accessToken: updatedSessionInfo.accessToken, sessionId: updatedSessionInfo.sessionId, sessionType: updatedSessionInfo.sessionType} as SessionInfo));
		this.shellContextService.updateContext(context => {
			context.session.sessionId = updatedSessionInfo.sessionId;
			context.security.accessToken = updatedSessionInfo.accessToken;
		});

		let tokenRefreshedEventPayload = { 'accessToken': updatedSessionInfo.accessToken };
		this.platformCommunicationService.publishEvent(PlatformCommunicationEvents.TokenRefreshed, ['*'], tokenRefreshedEventPayload);
	}
}
