import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { StickyHeaderService } from 'app/services/stickyHeaderService/stickyHeader.service';

@Injectable({providedIn: 'root'})
export class ScreenshotResults implements ICommunicationEvent {
	constructor(
		private stickyHeaderService: StickyHeaderService
	) {}

	get eventName(): string {
		return PlatformCommunicationEvents.ScreenshotResults;
	}

    handleEvent(event: any): void {
		this.stickyHeaderService.screenshotsResult$.next(event.data);
    }
}