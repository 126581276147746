import { SimulationInfo } from './../../shared/iosim-plus/models/simulationStatus';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { IEupRxForm, PagedQueryParameters, PagedQueryResult, ReferralResponse } from '../../shared/generalInterfaces';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { AssetReferenceType, GroupedStatus, RxVersion } from '../../shared/enums';
import { DownloadFileService } from '../../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { ToothNumberingSystem } from '@shared/enums';
import { IBaseOrderDataDto } from '@interfaces/IBaseOrderDataDto';
import {ICaseState} from "@interfaces/ICaseState";
import { IOrdersStatusesDto } from '@interfaces/IOrdersStatusesDto';
import { TimberService } from '@logging/timber.service';

@Injectable()
export class OrdersService {
	public orderObservable$: Observable<Order>;
	public OrderUpdated = new EventEmitter<any>();

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private downloadFileService: DownloadFileService,
		private translateService: TranslateService,
		private notificationService: NotificationService,
		private timberService: TimberService,
	) {}

	public getUndeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult<IEupRxForm>> {
		return this.getOrders(this.eupRoutesService.orders.undeliveredUrl, query);
	}

	public getDeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.deliveredUrl, query);
	}

	public getDeliveredFrontlineOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.deliveredFrontlineOrdersUrl, query);
	}


	public sendPendingAttachmentsOrder(orderId: number): Observable<any> {
		return this.http.post(`${this.eupRoutesService.orders.sendPendingAttachmentsOrder}?orderId=${orderId}`);
	}

	public deleteRx(rxId: string): Observable<any> {
		return this.http.delete(`${this.eupRoutesService.orders.deleteRxUrl}/${rxId}`, { responseType: 'text' });
	}

	public startProtocol(link: string, createNewiFrame: boolean): void {
		let ifrm = undefined;
		if (!createNewiFrame) {
			ifrm = document.getElementsByTagName('iframe')[0];
		}
		if (ifrm === null || ifrm === undefined) {
			ifrm = document.createElement('iframe');
		}
		ifrm.setAttribute('src', link);
		ifrm.style.display = 'none';
		document.body.appendChild(ifrm);
	}

	private getOrders(url: string, query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.http.get(url, { params: query.createSearchParams() }, true, true);
	}

	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams().set('orderId', orderId).set('assetReferenceType', AssetReferenceType.Fms_capture.toString());

		this.downloadFileService
			.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, ''))
			.subscribe({
				error: (e) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				},
			});
	}

	public downloadGalleryImages(orderId: string): void {
		const params = new HttpParams().set('orderId', orderId);

		this.downloadFileService
			.getFile(this.eupRoutesService.orders.downloadGalleryImages, { params }, `iTero_Export_Gallery_${orderId}`)
			.subscribe({
				error: (e) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_file_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				},
			});
	}

	public getFileStorage(orderId: number): Observable<number> {
		const params = new HttpParams().set('orderId', orderId.toString());
		return this.http
			.get(this.eupRoutesService.orders.getFileStorage, { params }, true, true)
			.pipe(catchError((err) => this.handleError(err)));
	}

	public getOrderStatuses(selectedCompanyId: number, selectedDoctorId: number): Observable<IOrdersStatusesDto> {
		const params = new HttpParams().set('companyId', selectedCompanyId.toString()).set('doctorId', selectedDoctorId.toString());
		return this.http.get(this.eupRoutesService.orders.getOrderStatusesUrl, { params: params }, true, false)
			.pipe(catchError((err) => {
					this.timberService.error(err, { module: 'order.service' });
					return of({
						failedOrderMessage: '',
						isFailedOrderExists: false,
						isPendingOrders: false,
					} as IOrdersStatusesDto);
			}));
	}

	public getDoctorOrder(params: { orderId: number; doctorId: number; companyId: number }): Observable<Order> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.orderId}`)
			.set('doctorId', `${params.doctorId}`)
			.set('companyId', `${params.companyId}`);
		return this.http.get(this.eupRoutesService.orders.getOrderByIdUrl, { params: queryParams });
	}

	public getBaseOrderData(params: { orderId: number }): Observable<IBaseOrderDataDto> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.orderId}`);
		return this.http.get(this.eupRoutesService.orders.getBaseOrderDataUrl, { params: queryParams });
	}

	public getOrderCode(orderId: number): Observable<string> {
		const queryParams = new HttpParams()
			.set('orderId', `${orderId}`);
		return this.http.get(this.eupRoutesService.orders.getOrderCode, { params: queryParams });
	}

	public validateOrder(params: { id: number }): Observable<boolean> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.id}`);
		return this.http.get(this.eupRoutesService.orders.validateOrderUrl, { params: queryParams });
	}

	public getLabOrder(params: { orderId: number | string; doctorId: number | string; companyId: number | string }): Observable<Order> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.orderId}`)
			.set('doctorId', `${params.doctorId}`)
			.set('companyId', `${params.companyId}`);
		return this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: queryParams });
	}

	public initLabOrderObservable(params: { orderId: number; doctorId: number; companyId: number }): void {
		this.orderObservable$ = this.getLabOrder(params);
	}

	getProcedureOrCaseTypeDescription(orderOrRxForm: Order | IEupRxForm, procedureEnabled: boolean): string {
		const version = orderOrRxForm.hasOwnProperty('rxVersion')
			? (orderOrRxForm as Order).rxVersion
			: (orderOrRxForm as IEupRxForm).version;

		if (procedureEnabled && version === RxVersion.ProcedureFlow) {
			return orderOrRxForm.procedureDescription || '';
		}

		return orderOrRxForm.caseTypeDescription;
	}

	public updateLabOrderById(orderId: string, doctorId: string, companyId: string, action: (orderToEdit: Order) => void): void {
		const params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
		this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: params }).subscribe((order: Order) => {
			if (this.OrderUpdated) {
				if (action) {
					action(order);
				}
				this.OrderUpdated.emit(order);
			}
		});
	}

	private handleError(err) {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Errors.Error_download_file_body'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);

		return throwError(err);
	}
}

export class Order {
	attachmentsCount: number;
	canChangeCaseType: boolean;
	canEditRx?: boolean;
	canExportFile: boolean;
	canExportGallery: boolean;
	canOpenFile: boolean;
	canOpenWithItero?: boolean;
	canOpenWithOrthoCad?: boolean;
	canRestorativeExport: boolean;
	canReturnToDoctor?: boolean;
	canSendToMilling?: boolean;
	canSendToModeling?: boolean;
	canUndoCase: boolean;
	canWebExport: boolean;
	caseAssessmentLink: string;
	caseState?: ICaseState;
	caseTypeDescription: string;
	caseTypeId: number;
	chartNumber: string;
	companyId: number;
	dateModified: string;
	designSharedMessageId: string;
	doctorId: number;
	doctorName: string;
	exportFileLink: string;
	groupedStatus: GroupedStatus;
	guid: string;
	iGoCaseAssessmentEnabled: boolean;
	iGoCaseAssessmentLink: string;
	iGoOutcomeSimulatorEnabled: boolean;
	iGoOutcomeSimulatorLink: string;
	iGoProgressAssessmentEnabled: boolean;
	id: number;
	ioSimPlusEnabled: boolean;
	isCaptureFileExist: boolean;
	isChecked?: boolean;
	isDesignShared: boolean;
	isEvxEnabled: boolean;
	isFailedToSend: boolean;
	isItero360Enabled: boolean;
	isMyDesignAppEnabled: boolean
	isOrder: boolean;
	isOrderDownloaded: boolean;
	isOrderPrinted: boolean;
	isOrthoOrder: boolean;
	isQualifiedForIoSimPlus: boolean = true;
	isScanImagesZipFileExist: boolean;
	isTakenByScanner: boolean;
	lastBowId: number;
	lastDetailsId: number;
	lastWorkOrderDateCreated: string;
	openFileLink: string;
	orderCode: string;
	outcomeSimulatorEnabled: boolean;
	outcomeSimulatorLink: string;
	patientName: string;
	procedureDescription?: string;
	procedureId?: number;
	procedureMapId?: number;
	procedureTypeId?: number;
	progressAssessmentEnabled: boolean;
	progressAssessmentLink: string;
	progressTrackingLink: string;
	referral: ReferralResponse;
	rxVersion: number;
	scanDate: string;
	scanImagesHasOnlyColoredImages: boolean;
	serverEndpoint: string;
	shipToPartnerId: number;
	shipToPartnerName: string;
	simulationInfo: SimulationInfo;
	status: string;
	statusAndState: string;
	toothNumberingSystem: ToothNumberingSystem;
	statusId: number;
}
