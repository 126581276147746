import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FeatureToggle } from '@shared/enums';
import { FeatureToggleSettings } from 'app/featuresToggleSettings/featuresToggleSettings.model';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSettingsService } from './globalSettings.service';
import { of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleGuard  {
	constructor(
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private globalSettingsService: GlobalSettingsService,
		private router: Router
	) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> {

		const featureFlags = next.data.featureToggles as FeatureToggle[];
		const roleType = this.globalSettingsService.rolePath();

		return this.featureToggleSource().pipe(
			map((res: FeatureToggleSettings[]) => {
				const canActivateGate = featureFlags.every(f => res.some( ff => ff.id === f  && ff.isActive));
				if (!canActivateGate) {
					this.router.navigate([`${roleType}/home`]);
				}
				return canActivateGate;
			}),
			take(1)
		);
	}

	private featureToggleSource(): Observable<FeatureToggleSettings[]> {
		return this.featuresToggleSettingsService.getFeaturesToggleSettings().pipe(
			switchMap((ff) => {
				if (!ff || ff.length === 0) {
					return this.featuresToggleSettingsService.getAll();
				}
				return of(ff);
			})
		);
	}
}