<div class="header">
    <div class="itero-logo" id="itero-logo"></div>
    <div *ngIf="scanDate" [ngClass]="{'expand': !showUserDetails && orderId, 'collapse-svg': showUserDetails}" (click)="toggleUserDetails()" id="expand-collapse-icon"></div> 
</div>
<div *ngIf="showUserDetails && scanDate" class="userDetails">
    <div class="row-data" id="patient-name-row">
      <label>{{'StickyHeader.PatientName' | translate}}:</label>
        <span class="patientName">{{ patientName }}</span>
    </div>
    <div class="row-data" id="order-id-row">
      <label>{{'StickyHeader.OrderId' | translate}}:</label>
        <span>{{ orderId }}</span>
    </div>
    <div class="row-data" id="scan-date-row">
      <label>{{'StickyHeader.ScanDate' | translate}}:</label>
        <span>{{ scanDate | eupDate }}</span>
    </div>
</div>
