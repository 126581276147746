import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Order, OrdersService } from '../../doctors/orders/orders.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { StorageType, FeatureToggle, SoftwareOptionsForCompany } from '../enums';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { IteroLabService } from '../../shared/iteroLab.service';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { DoctorPairingService } from 'app/services/doctor-pairing/doctor-pairing.service';
import { WindowsApplications } from './WindowsApplications';
import { take, tap } from 'rxjs/operators';

@Component({
	selector: 'windows-app-button',
	templateUrl: './windowsAppButton.component.html',
	styleUrls: ['./windowsAppButton.component.scss'],
	providers: [IteroLabService],
})
export class WindowsAppButtonComponent implements OnInit {
	// Mandatory
	@Input() public application: string;
	@Input() public order: Order;

	// Non-mandatory
	@Input() public title: string;
	@Input() public user: string = 'doctor';
	@Input() public windowsOnly: boolean = true;
	@Input() public modalAllowed = true;

	@ViewChild(DownloadLinkModal) downloadLinkModal: DownloadLinkModal;

	@Output() openModal: EventEmitter<any> = new EventEmitter();

	public orthoCadSetupDownloadUrl: string;
	public iTeroSetupDownloadUrl: string;
	public iOSimDownloadUrl: string;
	public orthoCadSetupDownloadUrl_acs: string;
	public iTeroSetupDownloadUrl_acs: string;
	public iOSimDownloadUrl_acs: string;

	disclaimer = 'Orders.Disclaimer';
	isInvisalignOutcomeSimulatorEnabled = false;
	isProgressAssesmentEnabled = false;
	isCaseAssesmentEnabled = false;
	iosimAuthenticationSuccessSubscription: any;

	constructor(
		private eupRoutesService: EupRoutesService,
		private orderService: OrdersService,
		private translateService: TranslateService,
		public globalSettingsService: GlobalSettingsService,
		private iteroLabService: IteroLabService,
		private featureToggleSettingsService: FeaturesToggleSettingsService,
		private softwareOptionsService: SoftwareOptionsService,
		private iosimService: DoctorPairingService
	) {}

	ngOnChanges(changes: SimpleChange): void {
		if (changes['application'].currentValue !== changes['application'].previousValue) {
			this.disclaimer = this.nameMap[changes['application'].currentValue] === 'Orders.OpenWithOrthoCAD' ? 'Orders.Disclaimer' : '';
		}
	}

	ngOnInit(): void {
		this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
		this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
		this.iOSimDownloadUrl = this.eupRoutesService.downloads.iOSimDownloadUrl;
		this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
		this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;
		this.iOSimDownloadUrl_acs = this.eupRoutesService.downloads.iOSimDownloadUrl_acs;

		const softwareOptions = this.globalSettingsService.get().companySoftwareOptions;

		this.isInvisalignOutcomeSimulatorEnabled = softwareOptions.includes(SoftwareOptionsForCompany.InvisalignOutcomeSimulator);
		this.isProgressAssesmentEnabled = softwareOptions.includes(SoftwareOptionsForCompany.ProgressAssesment);
		this.isCaseAssesmentEnabled = softwareOptions.includes(SoftwareOptionsForCompany.CaseAssesment);

		if (!this.title) {
			this.title = this.translateService.instant(this.nameMap[this.application]);
		}
	}

	public get available(): boolean {
		return this.visibilityMap[this.user][this.application];
	}

	public onClick(): void {
		this.orderService
		.getFileStorage(this.order.id)
		.pipe(
			take(1),
			tap((storage: StorageType) => this.onclickHandler(storage))
		)
		.subscribe();
	}

	private onclickHandler(storage: StorageType) {
		const orderId = this.order.id;
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		const appProtocolLink = this.linkMap[this.application];
		const downloadLink = this.installationLinksMap[this.application][storage];
		const name = this.nameMap[this.application];
		const title = this.translateService.instant(name);

		if (this.application.includes('iosim')) {
			this.openPairedApplication(companyId, orderId, title, downloadLink, appProtocolLink);
		} else if (this.iteroLabService.isNeedToSendToLab(this.order)) {
			this.openLabApplication(title, downloadLink);
		} else {
			this.openApp(title, downloadLink, appProtocolLink);
		}
	}

	private openPairedApplication(companyId, orderId, title, downloadLink, appProtocolLink) {
		if (!this.hasRefreshToken(appProtocolLink) && this.canPairWithIds()) {
			this.openIdsLoginWindow(companyId, orderId, title, downloadLink, appProtocolLink);
			return;
		}

		this.openApp(title, downloadLink, appProtocolLink);
	}

	private openIdsLoginWindow(companyId, orderId, title, downloadLink, appProtocolLink) {
		this.iosimService.openIdsLoginWindow();
		this.iosimAuthenticationSuccessSubscription = this.iosimService.iosimAuthenticationSuccess.pipe(take(1)).subscribe((isSuccess) => {
			if (!isSuccess) {
				this.openApp(title, downloadLink, appProtocolLink);
				return;
			}
			this.iosimService
				.getIOSimLinkWithOAuth(companyId, orderId)
				.pipe(
					take(1),
					tap((iosimApplicationLinks) => {
						const name = this.nameMap[this.application].split('.')[1];
						const applicationKey = `${name.charAt(0).toLowerCase()}${name.slice(1)}`;
						this.openApp(title, downloadLink, iosimApplicationLinks[applicationKey]);
					})
				)
				.subscribe();
		});
	}

	private hasRefreshToken(appProtocolLink: string): boolean {
		if (appProtocolLink.indexOf('refresh_token=') >= 0) return true;
		return false;
	}

	private openLabApplication(title, downloadLink) {
		this.iteroLabService
			.prepareOpenApp(this.order.id, this.order.lastDetailsId)
			.pipe(
				take(1),
				tap((url) => {
					this.openApp(title, downloadLink, url);
					this.orderService.updateLabOrderById(
						this.order.id.toString(),
						this.globalSettingsService.get().selectedDoctorId.toString(),
						this.globalSettingsService.get().selectedCompanyId.toString(),
						(order: Order) => {
							// optimistic UI issue
							order.canReturnToDoctor = false;
						}
					);
				})
			)
			.subscribe();
	}

	private openApp(title: string, downloadLink: string, appProtocolLink: string): void {
		this.orderService.startProtocol(appProtocolLink, true);
		if (this.modalAllowed) {
			this.downloadLinkModal.show(title, downloadLink);
		} else {
			this.openModal.emit({ title, downloadLink });
		}
	}

	private canPairWithIds(): boolean {
		const isFeatureAvailable = this.featureToggleSettingsService.isVisibleFeature(FeatureToggle.IdsPairingForIOSIM);
		const softwareOptions = this.globalSettingsService.get().companySoftwareOptions;
		const hasSoftwareOption = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.shouldEnableDoctorPairing,
			softwareOptions
		);

		return isFeatureAvailable && hasSoftwareOption;
	}

	public get customWidth() {
		return this.widthMap[this.application];
	}

	private get visibilityMap() {
		return {
			doctor: {
				[WindowsApplications.Iosim]: this.order.outcomeSimulatorEnabled && this.isInvisalignOutcomeSimulatorEnabled,
				[WindowsApplications.IosimPa]: this.order.progressAssessmentEnabled && this.isProgressAssesmentEnabled,
				[WindowsApplications.IosimCa]: this.order.iGoCaseAssessmentEnabled && this.isCaseAssesmentEnabled,
				[WindowsApplications.IosimGo]: this.order.iGoOutcomeSimulatorEnabled && this.isInvisalignOutcomeSimulatorEnabled,
				[WindowsApplications.Orthocad]: this.order.canOpenFile && this.order.isOrthoOrder,
			},
			lab: {
				[WindowsApplications.Orthocad]: this.order.canOpenWithOrthoCad
			},
		};
	}
	private get linkMap() {
		return {
			[WindowsApplications.Iosim]: this.order.outcomeSimulatorLink,
			[WindowsApplications.IosimPa]: this.order.progressAssessmentLink,
			[WindowsApplications.IosimCa]: this.order.iGoCaseAssessmentLink,
			[WindowsApplications.IosimGo]: this.order.iGoOutcomeSimulatorLink,
			[WindowsApplications.Orthocad]: this.order.openFileLink,
		};
	}

	private get nameMap() {
		return {
			[WindowsApplications.Iosim]: 'Orders.InvisalignOutcomeSimulator',
			[WindowsApplications.IosimPa]: 'Orders.InvisalignProgressAssessment',
			[WindowsApplications.IosimCa]: 'Orders.InvisalignGoCaseAssessment',
			[WindowsApplications.IosimGo]: 'Orders.InvisalignGoOutcomeSimulator',
			[WindowsApplications.Orthocad]: 'Orders.OpenWithOrthoCAD',
		};
	}

	private get installationLinksMap() {
		const iosimLinks = {
			[StorageType.ACS]: this.iOSimDownloadUrl_acs,
			[StorageType.OriginalFMS]: this.iOSimDownloadUrl,
		};

		const iTeroLinks = {
			[StorageType.ACS]: this.iTeroSetupDownloadUrl_acs,
			[StorageType.OriginalFMS]: this.iTeroSetupDownloadUrl,
		};

		return {
			[WindowsApplications.Iosim]: iosimLinks,
			[WindowsApplications.IosimPa]: iosimLinks,
			[WindowsApplications.IosimCa]: iosimLinks,
			[WindowsApplications.IosimGo]: iosimLinks,
			[WindowsApplications.Orthocad]: {
				[StorageType.ACS]: this.orthoCadSetupDownloadUrl_acs,
				[StorageType.OriginalFMS]: this.orthoCadSetupDownloadUrl,
			},
		};
	}

	private get widthMap() {
		return {
			[WindowsApplications.Iosim]: 'extended',
			[WindowsApplications.IosimPa]: 'extended',
			[WindowsApplications.IosimCa]: 'extended',
			[WindowsApplications.IosimGo]: 'extended',
			[WindowsApplications.Orthocad]: undefined,
		};
	}
}
