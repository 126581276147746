import {  Output, OnInit, Input, EventEmitter, Directive, OnDestroy } from '@angular/core';

@Directive({
	selector: 'eup-timer'
})
export class TimerDirective implements OnInit, OnDestroy {
	@Input() intervalSec: number;
	@Input() autoStart = false;

	@Output() timerTriggered: EventEmitter<any> = new EventEmitter<any>();

	private timerId: any;

	ngOnInit(): void {
		if (this.autoStart) {
			this.start();
		}
	}

	start() {
		if (this.intervalSec) {
			this.timerId = setInterval(() => this.timerTriggered.emit(), this.intervalSec * 1000);
		}
	}

	restart() {
		clearInterval(this.timerId);
		this.start();
	}

	ngOnDestroy(): void {
		clearInterval(this.timerId);
	}
}
