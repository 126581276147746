import { Directive, OnInit, OnDestroy } from '@angular/core';
import { timer, Subscription, PartialObserver } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { EupObserver } from '@core/eupObserver.service';
import * as AT from '@shared/actionTypes';
import { AuthService } from 'app/services/authentication/auth.service';

@Directive({
	selector: '[inactivity-timer]'
})
export class InactivityTimerDirective implements OnInit, OnDestroy {
	private timerSubscription: Subscription;
	private eupObserverSubscription: Subscription;

	constructor(private eupObserver: EupObserver,
				private authService: AuthService) {
	}

	ngOnInit() {
		if (environment.sessionTimeoutSec) {
			this.resetTimer();
			this.subscribeToUserActivityEvents();
		}
	}

	subscribeToUserActivityEvents() {
		const observer: PartialObserver<any> = {
			next: (value) => {
				switch (value.action) {
					case AT.LAB_ACTION_RAISED:
						if (this.timerSubscription) {
							this.timerSubscription.unsubscribe();
						}
						this.resetTimer();
						break;
				}
			}
		};

		this.eupObserverSubscription = this.eupObserver.subscribe(observer);
	}

	resetTimer() {
		const interval = 1000;
		this.timerSubscription = timer(0, interval).pipe(
			take(environment.sessionTimeoutSec),
		).subscribe(
			_ => {},
			_ => {},
			() => {
				this.authService.logoutClient({ byTimeout: true });
			}
		);
	}


	ngOnDestroy(): void {
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
		if (this.eupObserverSubscription) {
			this.eupObserverSubscription.unsubscribe();
		}
	}
}
