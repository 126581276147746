import { Injectable, OnDestroy } from '@angular/core';
import { IDesktopNotification } from '../../../interfaces/IDesktopNotification';
import { BehaviorSubject, from, Observable, Subject, of, interval, iif, throwError } from 'rxjs';
import { BaseSignalR } from '../signalR/base-signalR';
import { map, tap, takeUntil, single, delayWhen, mergeMap, catchError, switchMap, filter } from 'rxjs/operators';
import { IMessage } from 'app/interfaces/IMessage';
import { TimberService } from '../../logging/timber.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { EupRoutesService } from '@core/eupRoutes.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { SettingsService } from '@shared/settings.service';

@Injectable({
	providedIn: 'root',
})
export class RealTimeDesktopNotificationsService extends BaseSignalR<IMessage> {
	private isNotificationPermitted: boolean;
	private allNotifications: Notification[] = [];
	private readonly channelName = 'EupClientMessageRecieved';
	private readonly hubName = 'notificationHub';
	isDesktopNotificationPermitted$ = new BehaviorSubject<boolean>(false);

	constructor(
		private eupRoutesService: EupRoutesService,
		private translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService,
		private settingsService: SettingsService,
	    timberService: TimberService,
	) {
		super(timberService);
		this.publishNotificationPermission();
	}

	closeNotification(notification: Notification): void {
		notification.close();
	}

	closeAllNotifications(): void {
		this.allNotifications.forEach((notificationItem: Notification) => this.closeNotification(notificationItem));
	}

	requestUserPermission(): Observable<boolean> {
		return from(this.checkIfPermissionIsPromise()).pipe(
			single(),
			tap((permission) => console.log('permission requested, permission: ', permission)),
			map((permission) => permission === 'granted'),
			tap((isPermitted) => {
				this.isNotificationPermitted = isPermitted;
				this.isDesktopNotificationPermitted$.next(isPermitted);
				console.log('permission requested, isNotificationPermitted: ', isPermitted);
			})
		);
	}

	checkPermissionAndStartNotifications() {
		of(this.globalSettingsService.getFirstCompany().id)
			.pipe(
				switchMap((companyId) => this.settingsService.getSettings(companyId, true, false)),
				filter((settings) => settings.userSettings.allowDesktopNotifications),
				tap(async (_) => {
					try {
						await this.startNotifications();
					} catch (message) {
						this.timberService.error(message, { module: 'RealTimeDesktopNotificationsService' });
					}
				}),
				catchError((error) => {
					const message = 'error in check permission notification: ';
					this.timberService.error(message, { error, module: 'RealTimeDesktopNotificationsService' });
					return throwError(error);
				})
			)
			.subscribe();
	}

	async startNotifications(): Promise<any> {
		const notificationAgentUrl = this.eupRoutesService.notificationAgentUrl;
		const token = this.globalSettingsService.get().jwtAccessToken;
		if (!notificationAgentUrl) {
			const message = 'notificationAgentUrl url is not specified in global settings';
			throw Error(message);
		}
		await this.stopNotifications();
		const hubUrl = `${notificationAgentUrl}${this.hubName}`;
		this.initializeNotifications(hubUrl, token);
	}

	private initializeNotifications(hubUrl: string, jwtAccessToken: string): void {
		const notPermittedFlow$ = of({}).pipe(
			tap((_) => {
				this.timberService.warn('User did not permit notifications. Connection to signalR Service cannot be established', {
					module: 'RealTimeDesktopNotificationsService',
				});
			})
		);

		const permittedFlow$ = of({}).pipe(
			tap(async (_) => await this.initMessages(hubUrl, this.channelName, jwtAccessToken)),
			mergeMap((_) => this.messageBroker$),
			delayWhen((_) => iif(() => !!window['chrome'], of(undefined), interval(Math.floor(Math.random() * 10 + 1) * 10))),
			takeUntil(this.stopNotifications$),
			map((order) => this.prepareDesktopNotification(order)),
			tap((notification) => this.showNotification(notification))
		);

		this.requestUserPermission()
			.pipe(
				mergeMap((isPermitted) => iif(() => isPermitted, permittedFlow$, notPermittedFlow$)),
				catchError((error) => {
					this.timberService.error('error while processing notification', {
						module: 'RealTimeDesktopNotificationsService',
						error: error,
					});
					return of(error);
				})
			)
			.subscribe();
	}

	private publishNotificationPermission() {
		this.isNotificationPermitted = Notification['permission'] === 'granted';
		this.isDesktopNotificationPermitted$.next(this.isNotificationPermitted);
	}

	private prepareDesktopNotification(order: IMessage): IDesktopNotification {
		this.timberService.debug(`Notification message received.`, { module: 'DesktopNotificationsService', traceId: order.traceId });

		const translatedTitle = this.translateService.instant('Lab.notificationTitle');
		const translatedBody = <string>this.translateService.instant('Lab.notificationsBody', { orderId: `${order.orderId}` });
		return {
			title: translatedTitle,
			body: translatedBody,
			icon: '/assets/images/iteroLogo.png',
			redirectUrl: this.buildNotificationsHandlerUrl(order),
			tag: order.hashCode.toString(),
		};
	}

	private buildNotificationsHandlerUrl(orderInfo: IMessage): string {
		// NOTE: HttpParams recieves object only with string fields.
		const orderInfoQueryObject = {
			order: JSON.stringify(orderInfo),
		};

		const queryParams = new HttpParams({
			fromObject: orderInfoQueryObject,
		});

		return `/notifications/orders?${queryParams.toString()}`;
	}

	private showNotification(desktopNotification: IDesktopNotification): Notification {
		if (!this.isNotificationPermitted) {
			return;
		}
		
		const options = {
			body: desktopNotification.body,
			icon: desktopNotification.icon,
			tag: desktopNotification.tag,
			renotify: true,
		};
		  
		const newDesktopNotification = new Notification(desktopNotification.title, options);

		const notificationClickHandler = (event: Event) => {
			window.open(desktopNotification.redirectUrl);
			newDesktopNotification.removeEventListener('click', notificationClickHandler);
		};

		newDesktopNotification.addEventListener('click', notificationClickHandler);

		this.allNotifications.push(newDesktopNotification);

		return newDesktopNotification;
	}

	private checkIfPermissionIsPromise(): Promise<any> {
		// Safari doesn't support notifications permission as a promise
		const requestNotiPermission = Notification.requestPermission();

		return requestNotiPermission
			? requestNotiPermission
			: new Promise((resolve) => Notification.requestPermission((perm) => resolve(perm)));
	}
}
