import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PagedQueryParameters } from '../generalInterfaces';

@Injectable()
export class SortEventEmitterService {
	// Observable string sources
	private sortClickedSource = new Subject<PagedQueryParameters>();

	// Observable string streams
	sortClicked$ = this.sortClickedSource.asObservable();

	// Service message commands
	clickSort(saf: PagedQueryParameters) {
		this.sortClickedSource.next(saf);
	}
}
