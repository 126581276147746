import {Injectable} from '@angular/core';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import {from, Observable, Observer, Subject} from 'rxjs';
import {map, filter, tap} from 'rxjs/operators';



@Injectable({
	providedIn: 'root',
})
export class PostMessageService {
	subState: Object = {};

	private messageEventSubject: Subject<MessageEvent>;

	constructor(private appConfigService: AppConfigService) {
		this.messageEventSubject = new Subject<MessageEvent>();
	}

	eventListener = (e: MessageEvent) => {
		this.messageEventSubject.next(e)
	};

	listenToEvents() {
		console.info(`[Post Message Service] - adding event listener`);
		window.addEventListener('message', this.eventListener);
	}

	removeListener() {
		console.info(`[Post Message Service] - removing event listener`);
		window.removeEventListener('message', this.eventListener);
	}

	getMessages$<T>(iframeId: string, permittedOrigin: string): Observable<T> {
		if (!(iframeId in this.subState)) {
			this.listenToEvents();
		}

		this.subState[iframeId] = this.subState[iframeId] || {counter: 0, subscription: null};
		this.subState[iframeId].counter++;


		return Observable.create((observer: Observer<any>) => {

			this.subState[iframeId].subscription = from(this.messageEventSubject)
				.pipe(
					filter((e: MessageEvent) => {
						const iframe: HTMLIFrameElement = <HTMLIFrameElement>(document.getElementById(iframeId));
						return iframe.contentWindow === e.source;
					}),
					filter((e: MessageEvent) => e.origin === new URL(permittedOrigin).origin),
					map((e: MessageEvent) => e.data),
					tap((data: T) => console.info(`[Post Message Service] - message received from ${iframeId}`, this.appConfigService?.appSettings?.isProduction ? '' : data))
				)
				.subscribe(observer);


			return () => { // when unSubscribing from the observable
				this.subState[iframeId].counter--;
				if (this.subState[iframeId].counter <= 0) {
					this.subState[iframeId].subscription.unsubscribe();
					delete this.subState[iframeId];
				}
				if (!(iframeId in this.subState)) {
					this.removeListener();
				}
			};
		});
	}

	send(iframeId: string, message: any): void {
		const iFrame: HTMLIFrameElement = document.getElementById(iframeId) as HTMLIFrameElement;
		this.sendToIFrame(iFrame, message);
	}

	sendToIFrame<T>(targetIframe: HTMLIFrameElement, message: T): void {
		if (targetIframe && targetIframe.contentWindow) {
			targetIframe.contentWindow.postMessage(message, '*');
			console.info(`[Post Message Service] - message have been sent to ${targetIframe.id}`, this.appConfigService?.appSettings?.isProduction ? '' : message);
		} else {
			throw new Error(`[Post Message Service] - can't send message to iframe. ${targetIframe.id} does not exists`);
		}
	}
}
