import { Injectable } from '@angular/core';
import { CommunicationService } from '../../../services/client-communication/communication.service';
import { TimberService } from '@logging/timber.service';
import { GetAppsConfigEvent } from '@shared/communicationEvents/platformEvents/get-apps-config-event';
import { ICommunicationEvent } from '@shared/communicationEvents/ICommunicationEvent';
import { ApplicationLoaded } from '@shared/communicationEvents/platformEvents/application-loaded-event';
import { ChangeTitle } from '@shared/communicationEvents/platformEvents/change-title-event';
import { ClearTitle } from '@shared/communicationEvents/platformEvents/clear-title-event';
import { HandleError } from '@shared/communicationEvents/platformEvents/handle-error-event';
import { TokenExpired } from '@shared/communicationEvents/platformEvents/token-expired-event';
import { TokenRefreshed } from '@shared/communicationEvents/platformEvents/token-refreshed-event';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { takeUntil, tap } from 'rxjs';
import { CommunicationChannels, PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { GetIosimProgress } from '@shared/communicationEvents/platformEvents/get-iosim-progress-event';
import { StartIosimSimulation } from '@shared/communicationEvents/platformEvents/start-iosim-simulation-event';
import { ScreenshotEnabled } from '@shared/communicationEvents/platformEvents/screenshot-enabled-event';
import { ScreenshotResults } from '@shared/communicationEvents/platformEvents/screenshot-results-event';
import { ContextBizCtxUpdated } from '@shared/communicationEvents/platformEvents/context-bizctx-updated-event';
import { ContextUiUpdated } from '@shared/communicationEvents/platformEvents/context-ui-updated-event';
import { InitContext } from '@shared/communicationEvents/platformEvents/init-context-event';
import { AppsConfigResult } from '@shared/communicationEvents/platformEvents/apps-config-result-event';
import { IosimProgressResult } from '@shared/communicationEvents/platformEvents/iosim-progress-results-event';
import { ScreenshotClicked } from '@shared/communicationEvents/platformEvents/screenshot-clicked-event';
import { BackButtonClicked } from '@shared/communicationEvents/platformEvents/back-button-clicked-event';

@Injectable({ providedIn: 'root' })
export class PlatformCommunicationService extends BaseDestroyable {    
    private readonly platformChannelEvents: ICommunicationEvent[];
    private readonly applicationChannelEvents: ICommunicationEvent[];

    constructor(private communicationService: CommunicationService,
        private shellContextService: ShellContextService,
        private timberService: TimberService,
        private getAppsConfigEvent: GetAppsConfigEvent,
        private appsConfigResult: AppsConfigResult,
        private applicationLoadedEvent: ApplicationLoaded,
        private changeTitleEvent: ChangeTitle,
        private clearTitleEvent: ClearTitle,
        private handleErrorEvent: HandleError,
        private tokenExpiredEvent: TokenExpired,
        private tokenRefreshedEvent: TokenRefreshed,
        private getIosimProgress: GetIosimProgress,
        private iosimProgressResult: IosimProgressResult,
        private startSimulation: StartIosimSimulation,
        private screenshotEnabled: ScreenshotEnabled,
        private screenshotClicked: ScreenshotClicked,
        private screenshotResults: ScreenshotResults,
        private contextBizCtxUpdated: ContextBizCtxUpdated,
        private contextUiUpdated: ContextUiUpdated,
        private initContext: InitContext,
        private backButtonClicked: BackButtonClicked
        ){
            super();
            this.platformChannelEvents = [
                getAppsConfigEvent,
                appsConfigResult,
                applicationLoadedEvent,
                handleErrorEvent,
                tokenExpiredEvent,
                tokenRefreshedEvent,
                contextBizCtxUpdated,
                contextUiUpdated,
                initContext,
                backButtonClicked
            ];

            this.applicationChannelEvents = [
                getIosimProgress,
                iosimProgressResult,
                startSimulation,
                changeTitleEvent,
                clearTitleEvent,
                screenshotEnabled,
                screenshotClicked,
                screenshotResults
            ];

            this.shellContextService.contextBizContextChanged$.pipe(tap(contextBizCtx => {
                this.publishEvent(PlatformCommunicationEvents.ContextBizCtxUpdated, ['*'], contextBizCtx);
            }), takeUntil(this.componentAlive$)).subscribe();
            
            this.shellContextService.contextUiChanged$.pipe(tap(contextUi => {
                this.publishEvent(PlatformCommunicationEvents.ContextUiUpdated, ['*'], contextUi);
            }), takeUntil(this.componentAlive$)).subscribe();
    }

    public subscribeToPlatformChannel() {
        this.communicationService.subscribeToChannel(CommunicationChannels.PlatformChannel, 'platform', (event) => this.handleEvent(event, CommunicationChannels.PlatformChannel));
    }

    public subscribeToApplicationChannel() {
        this.communicationService.subscribeToChannel(CommunicationChannels.ApplicationChannel, 'platform', (event) => this.handleEvent(event, CommunicationChannels.ApplicationChannel));
    }

    async publishEvent(eventName: string, targetApplications: Array<string>, eventPayload: any) {
        let channelName = '';
        if (this.applicationChannelEvents.findIndex(x => x.eventName === eventName) > -1) {
            channelName = CommunicationChannels.ApplicationChannel;
        } else if (this.platformChannelEvents.findIndex(x => x.eventName === eventName) > -1) {
            channelName = CommunicationChannels.PlatformChannel;
        } else {
            this.timberService.error(`Illegal ${eventName} event failed to published from platform`, { module: 'PlatformCommunicationService' });
            return;
        }

        await this.communicationService.publishEvent(channelName, 'platform', eventName, targetApplications, eventPayload);
    }

    private handleEvent(event: any, channelName: string) {
        let channelEvents = channelName === CommunicationChannels.ApplicationChannel ? this.applicationChannelEvents : this.platformChannelEvents; 
        this.timberService.info(`Event ${event.eventHeader.eventId} ${event.eventHeader.eventName} arrived successfully from ${event.eventHeader.publisher} to platform on ${channelName}`, { module: 'PlatformCommunicationService', extendedParameters: { eventId: event.eventHeader.eventId, sessionId: event.systemHeader.sessionId } });
        let platformEvent = this.getEvent(event, channelEvents, channelName);
        platformEvent?.handleEvent(event, this.publishEvent.bind(this));
    }

    private getEvent(event: any, channelEvents: ICommunicationEvent[], channelName: string) {
	const platformEvent = channelEvents.find(e => e.eventName === event.eventHeader.eventName);
		if (!platformEvent) {
			this.timberService.warn(`Event ${event.eventHeader.eventName} from ${event.eventHeader.publisher} in ${channelName} is not handled by the platform`, { module: 'PlatformCommunicationService' });
			return null;
		}
		return platformEvent;
	}
}