import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { KeyValuePair } from '../shared/generalInterfaces';
import { ExcelExportOptions } from '../shared/generalInterfaces';
import { ExcelExtensionsEnum } from '../shared/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExcelService {
	constructor(private translateService: TranslateService) {}

	public exportAsExcelFile(options: ExcelExportOptions): void {
		const sheets: { [sheet: string]: XLSX.WorkSheet } = {};
		const extension: string = '.' + ExcelExtensionsEnum[options.extension].toLowerCase();
		const translatedHeaders: KeyValuePair[] = [];
		const headers: string[] = [];
		let dataToExport: any[] = [];

		if (options.data && options.data.length > 0) {
			// do not export if there is no data
			if (options.headers && options.headers.length > 0) {
				options.headers.forEach((h) => {
					let translatedHeader = this.translateService.instant(h.value);
					if (!translatedHeader) {
						translatedHeader = h.value;
					}

					headers.push(translatedHeader); // excel headers
					translatedHeaders.push(new KeyValuePair(h.key, translatedHeader));
				});
				const props = Object.getOwnPropertyNames(options.data[0]); // get object properties

				dataToExport = options.data.map((obj) => {
					const clone = Object.assign({}, obj);
					props.forEach((p) => {
						// if header was not mapped then remove it from the excel
						const a = translatedHeaders.filter((t) => t.key === p)[0];
						if (!a) {
							delete clone[p];
						}
					});
					translatedHeaders.forEach((h) => {
						clone[h.value] = clone[h.key]; // convert the property of the object with the equalent translated header
						delete clone[h.key];
					});
					return clone;
				});
			}

			const excelMime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers });
			sheets[options.sheetName] = worksheet;
			const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: [ options.sheetName ] };
			const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

			this.saveAsExcelFile(excelBuffer, options.fileName, extension, excelMime);
		}
	}

	private saveAsExcelFile(buffer: any, fileName: string, excelExtension: string, mime: string): void {
		const data: Blob = new Blob([ buffer ], {
			type: mime
		});
		FileSaver.saveAs(data, fileName + excelExtension);
	}
}
