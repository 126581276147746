import { Directive, ElementRef, Input, HostListener, OnInit } from '@angular/core';
import { Utils } from '@shared/utils.service';

@Directive({
	selector: '[appRestrictInput]',
})
export class RestrictInputDirective implements OnInit {
	@Input('appRestrictInput') disallowedChars: string;
	inputElement: HTMLInputElement;
	sanitizedDisallowedCharsArray: string[];
	regexFromDisallowedChars: RegExp;

	constructor(public Element: ElementRef, private utils: Utils) {
		this.inputElement = Element.nativeElement;
	}

	ngOnInit(): void {
		this.sanitizedDisallowedCharsArray = Array.from(new Set(this.disallowedChars));
		this.regexFromDisallowedChars = new RegExp('[' + this.sanitizedDisallowedCharsArray.join('') + ']', 'g');
		console.log('appRestrictInput initialise on element: ' + this.inputElement);
	}

	isValidInput(input: string) {
		return this.sanitizedDisallowedCharsArray.indexOf(input) === -1;
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		// Ensure that it is a number and stop the keypress
		if (!this.isValidInput(e.key)) {
			e.preventDefault();
		}
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(e: KeyboardEvent) {
		if (!this.isValidInput(e.key)) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: any) {
		const clipboardData = event.clipboardData || window['clipboardData'] || event.originalEvent.clipboardData;
		const pastedInput = clipboardData.getData('Text');

		if (this.utils.isInternetExplorer()) {
			document.execCommand('paste', false, pastedInput.replace(this.regexFromDisallowedChars, ''));
		} else {
			document.execCommand('insertText', false, pastedInput.replace(this.regexFromDisallowedChars, ''));
		}

		event.preventDefault();
	}

	@HostListener('drop', ['$event'])
	onDrop(event: any) {
		const textData = event.dataTransfer.getData('text');
		this.inputElement.focus();

		const pasted = document.execCommand('insertText', false, textData.replace(this.regexFromDisallowedChars, ''));
		event.preventDefault();
	}
}
