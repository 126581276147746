import { WindowsApplicationProtocol } from './enums';

export class WindowApplicationLinkData {
	link: string;
	name: string;
	downloadLink: string;
	protocol: WindowsApplicationProtocol;
	constructor(link: string, name: string, downloadLink: string, protocol: WindowsApplicationProtocol) {
		this.link = link;
		this.name = name;
		this.downloadLink = downloadLink;
		this.protocol = this.defineProtocol(downloadLink);
	}

	defineProtocol(downloadLink:string): WindowsApplicationProtocol {
		return WindowsApplicationProtocol.IteroLab;
	}
}
