export class LegacyRxNote {
	static nextNoteId = 0;
	public dateCreated: string;
	private _id: number;

	constructor(
		date: Date | string,
		public content: string,
		public role: LegacyRxNoteRole,
		public name: string,
		public canEdit: boolean = false
	) {
		this._id = LegacyRxNote.nextNoteId++;

		// date sent as string type
		if (typeof date === 'string') {
			this.dateCreated = date;
			// date is defined and sent as date type
		} else if (date) {
			this.dateCreated = date.toLocaleDateString('en-US') + ' | ' + date.toLocaleTimeString('en-US');
		}
	}

	get id() {
		return this._id;
	}

	toString(): string {
		if (!this.dateCreated || !this.role || !this.name) return this.content;

		return `(${this.role.toString()}) ${this.name} (${this.dateCreated})` + `${'\n'}${this.content}`;
	}
}

export abstract class LegacyRxNoteRole {
	static getRole(isLabMode: boolean, isTechnicianMode: boolean): LegacyRxNoteRole {
		if (isLabMode) {
			return new LabRole();
		}

		if (isTechnicianMode) {
			return new TechnicianRole();
		}

		return new DoctorRole();
	}
}

export class DoctorRole extends LegacyRxNoteRole {
	toString(): string {
		return 'D';
	}
}

export class LabRole extends LegacyRxNoteRole {
	toString(): string {
		return 'L';
	}
}

export class TechnicianRole extends LegacyRxNoteRole {
	toString(): string {
		return 'T';
	}
}

export class LegacyRxNoteService {
	public static readonly NoteDelimeter: string = '\r\n---------------------------------\r\n';

	flatten(notes: LegacyRxNote[]): string {
		if (!notes) return '';
		return notes.map((x) => x.content).join(LegacyRxNoteService.NoteDelimeter);
	}

	mergeNotes(oldNotes: LegacyRxNote[], newNotes: LegacyRxNote[]): string {
		const notes = [];

		notes.push.apply(notes, oldNotes || []);
		notes.push.apply(notes, newNotes || []);

		return notes.map((note) => note.toString()).join(LegacyRxNoteService.NoteDelimeter);
	}

	parseNotes(notes: string): LegacyRxNote[] {
		if (!notes) {
			return null;
		}

		const notesArray = notes.split(LegacyRxNoteService.NoteDelimeter);

		return notesArray.map((note) => {
			const noteRegex = /\((D|T|L)?\)([\w\W]*)?\(([\s\S]*)?\)\n([\s\S]*)?/;

			const regexRes = noteRegex.exec(note);
			if (!regexRes) {
				return new LegacyRxNote(null, note, null, null);
			} else {
				const role = regexRes[1];
				const name = regexRes[2];
				const date = regexRes[3];
				const content = regexRes[4];

				return new LegacyRxNote(date, content, role, name);
			}
		});
	}
}
