import { Injectable } from '@angular/core';
import { IOrderInformation } from '@interfaces/IOrderInformation';
import { TimberService } from '@logging/timber.service';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { from, Observable, of } from 'rxjs';
import { RxAppInputParameters } from '../models/rx-app-input-parameters';

@Injectable()
export class RxAppSdkService {
	private sdkEndpoint = `${this.appConfigService.appSettings.rxAppSdkEndpoint}/sdk.js`;
	private sdk: any;
	private readonly name = 'RxAppSdkService';
	private readonly sdkLoadingFailedTimeout = 5000;

	constructor(
		private appConfigService: AppConfigService,
		private logger: TimberService
	) { }

	get webComponentElement() {
		return this.sdk?.webComponentElement;
	}

	loadSdk(container: HTMLElement): Observable<any> {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.src = this.sdkEndpoint;
		const sdkLoadedPromise = new Promise((resolve, reject) => {
			const loadingTimeout = setTimeout(() => {
				const errorMessage = `Rx sdk loading failed due to timeout ${this.sdkLoadingFailedTimeout}`;
				this.logger.error(errorMessage, { module: this.name });
				reject(errorMessage);
			}, this.sdkLoadingFailedTimeout);

			const hadleLoadSuccess = () => {
				scriptElement.removeEventListener('load', hadleLoadSuccess);
				clearTimeout(loadingTimeout);
				this.sdk = (window as any).rxAppSdk;
				resolve('Rx sdk loaded');
			};

			scriptElement.addEventListener('load', hadleLoadSuccess);
		});
		container.appendChild(scriptElement);
		return from(sdkLoadedPromise);
	}

	loadSdkConfiguration(): Observable<any> {
		return from(this.sdk.loadSdkConfiguration(this.appConfigService.appSettings.rxAppSdkEndpoint));
	}

	createRxApp(container: HTMLElement): void {
		this.sdk.createRxApp(container);
	}

	updateInputParameters(inputParameters: Partial<RxAppInputParameters>): void {
		this.sdk.updateInputParameters(inputParameters);
	}

	saveRx(): Observable<any> {
		if (this.sdk && this.sdk.saveRx){
			return from(this.sdk.saveRx());
		}
		return of(null);
	}

	deleteRxApp(): void {
		if (this.sdk && this.sdk.deleteRxApp) {
			this.sdk.deleteRxApp();
		}
	}

	printRxs(): Observable<any> {
		return from(this.sdk.printRxs());
	}

	printRxsReset(): Observable<any> {
		try {
			return from(this.sdk.printRxsReset());
		} catch {
			return of(true);
		}
	}
	
	onPdfRequested(callback): void {
		this.sdk.onPdfRequested(callback);
	}

	onGetLabNotes(): Observable<any> {
		return from(this.sdk.onGetLabNotes());
	}

	addNote({ content, createdByName, contactId }: { content, createdByName, contactId }): Observable<any> {
		return from(this.sdk.addNote({ content, createdByName, contactId }));
	}

	updateOrderInformation(orderInformation: IOrderInformation): Observable<any> {
		return from(this.sdk.updateOrderInformation(orderInformation));
	}

	onGetRx(): Observable<any> {
		return from(this.sdk.onGetRx());
	}
}
