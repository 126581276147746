import { Pipe, PipeTransform } from '@angular/core';
import { GlobalSettingsService } from '../core/globalSettings.service';
import { formatDate } from './utils.service';

@Pipe({
	name: 'eupDate'
})
export class EupDatePipe implements PipeTransform {
	constructor(private globalSettingService: GlobalSettingsService) {}

	transform(value: string, overrideFormat: string): string {
		const settings = this.globalSettingService.get();
		const settingsDateFormat = settings ? settings.dateFormat : null;
		return formatDate(value, overrideFormat, settingsDateFormat);
	}
}
