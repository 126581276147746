import { EventEmitter, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { GlobalSettingsService } from '@core/globalSettings.service';
import { PatientIframeEvents, PatientDto, ApiError } from '../generalInterfaces';
import { EupRoutesService } from '@core/eupRoutes.service';
import { AppConfigService } from '../../services/appConfig/appConfigService';
import { ModalIframeService } from 'app/services/ModalIframe/modalIframe.service';
import { PatientAppModalComponent } from './patientAppModal/patientApp.component';
import { addOrEditPatientPath } from '../../../assets/consts';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { LocalStorageService } from '@core/localStorage.service';
import { Consts } from '@shared/consts';
import { RegulatoryConfigurationQuery } from '../../settings/state/regulatory-configuration.query';

@Injectable()
export class PatientAppService implements OnDestroy {
	public patientUpdatedEvent = new EventEmitter<PatientDto>();
	public patientAppClosedEvent = new EventEmitter<void>();
	public patientAppRedirectEvent = new EventEmitter<ApiError>();

	constructor(
		private eupRoutesService: EupRoutesService,
		private eupHttpHandler: EupHttpHandler,
		private appConfigService: AppConfigService,
		private globalSettings: GlobalSettingsService,
		private modalIframeService: ModalIframeService,
		private sanitizer: DomSanitizer,
		private localStorageService: LocalStorageService,
		private regulatoryConfigurationQuery: RegulatoryConfigurationQuery
	) {
		window.addEventListener('message', this.handleMessageFromPatientApp.bind(this), false);
		this.patientAppRedirectEvent.subscribe(error => {
			this.eupHttpHandler.handleError(error, true);
		});
	}

	ngOnDestroy(): void {
		window.removeEventListener('message', this.handleMessageFromPatientApp, false);
	}

	openEditPatientModal(patientGuid: string, patientType: number): void {
		const url = this.getEditPatientUrl(patientGuid, patientType);

		this.modalIframeService.openModal(PatientAppModalComponent, {patientAppUrl: url}, 'patient-form-modal');
	}

	private sanitizeUrl(url: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	getEditPatientUrl(patientUid: string, patientType: number): SafeResourceUrl {
		const companyId = this.globalSettings.get().selectedCompanyId;
		const doctorId = this.globalSettings.get().selectedDoctorId;
		const serverBase = this.appConfigService.appSettings.patientAppEndpoint;
		const endpoint = this.localStorageService.storage.getItem(Consts.Storage.RegionalApiEndpoint).slice(0, -1);
		const language = this.globalSettings.getLanguage().code;
		const url = `${serverBase}${addOrEditPatientPath}?isMidcSource=true&id=${patientUid}&type=${patientType}
		&doctorId=${doctorId}&internalEventId=28&endpoint=${endpoint}/mvc&companyid=${companyId}&lang-code=${language}
		&dateOfBirthFormat=${this.regulatoryConfigurationQuery.dateOfBirthFormat}`;
		return this.sanitizeUrl(url);
	}

	public handleMessageFromPatientApp(event: any): void {
		// valid origins but not handled here
		if (this.eupRoutesService.rx &&
			(event.origin === new URL(this.eupRoutesService.rx.rxUrl).origin ||
			 event.origin === new URL(this.eupRoutesService.rx.onlineOrthoViewerUrl).origin)) {
			return;
		}

		// invalid origins
		if (event.origin !== this.appConfigService.appSettings.patientAppEndpoint) {
			return;
		}

		if (!event || !event.data || !event.data.eventId) {
			console.warn(`Patient Service: Illegal event from PatientApp to EUP, no event id: ${JSON.stringify(event && event.data)}`);
			return;
		}

		switch (event.data.eventId) {
			case PatientIframeEvents.PatientAppGeneral:
					console.log(event);
					break;
			case PatientIframeEvents.PatientAppAdd:
				console.log(event);
				if (!event.data.data) {
					this.closeModal();
					return;
				}
				this.patientUpdatedEvent.emit(event.data.data);
				this.closeModal();
				break;
			case PatientIframeEvents.PatientAppSelectedPatient:
				console.log(event);
				if (!event.data.data) {
					this.closeModal();
					return;
				} else {
					this.patientUpdatedEvent.emit(event.data.data);
					this.closeModal();
				}
				break;
			case PatientIframeEvents.PatientAppSelectedPatientConflict:
				const patient = event.data.data;
				// close existing modal and opening new modal for editing patient
				this.modalIframeService.closeModal();
				const url = this.getEditPatientUrl(patient.uid, patient.type);
				this.modalIframeService.openModal(PatientAppModalComponent, {patientAppUrl: url}, 'patient-form-modal');
				break;
			case PatientIframeEvents.PatientAppUpdatePatient:
				console.log(event);
				this.patientUpdatedEvent.emit(event.data.data);
				this.closeModal();
				break;
			case PatientIframeEvents.PatientAppRedirect:
				this.closeModal();
				this.patientAppRedirectEvent.emit(event.data.data);
			default:
				console.error(`Illegal event id from Patient App to EUP: ${event.data.eventId}`);
		}
	}

	private closeModal(): void {
		this.modalIframeService.closeModal();
		this.patientAppClosedEvent.emit();
	}
}