import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPairType } from '@interfaces/IPairType';
import { IExternalPairStatus } from '@interfaces/IExternalPairStatus';
import { AccountPairingStatus } from '@shared/enums';

@Component({
	selector: 'eup-account-pairing',
	templateUrl: './account-pairing.component.html',
	styleUrls: ['./account-pairing.component.scss']
})
export class AccountPairingComponent implements OnInit {
	public iconStyles;

	@Input()
	public pairType: IPairType;

	@Input()
	public pairingStatus: AccountPairingStatus;

	@Output()
	public pair: EventEmitter<IExternalPairStatus> = new EventEmitter<IExternalPairStatus>();

	get AccountPairingStatus() { return AccountPairingStatus; }

	public emitPairing = () =>  this.pair.emit({
		name: this.pairType.name,
		pairingRequested : true
	})

	public emitUnpairing = () =>  this.pair.emit({
		name: this.pairType.name,
		pairingRequested : false
	})

	ngOnInit() {
		this.iconStyles = {
			width: this.pairType.iconWidth,
			height: this.pairType.iconHeight
		};
	}
}
