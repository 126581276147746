import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FeatureToggleSettings } from '../featuresToggleSettings.model';
import { FeatureToggleState, FeatureToggleStore } from './feature-toggle-store';


@Injectable({ providedIn: 'root' })
export class FeatureToggleQuery extends Query<FeatureToggleState> {
	featureToggles$: Observable<FeatureToggleSettings[]> = this.select(state => state.featureToggles).pipe(delay(0));
	lastUpdateTimestamp$: Observable<Date> = this.select(state => state.lastUpdateTimestamp).pipe(delay(0));
    isNavigationTriggered$: Observable<boolean> = this.select(state => state.isNavigationTriggered).pipe(delay(0));

    constructor(protected store: FeatureToggleStore) {
		super(store);
	}
	
    get lastUpdateTimestamp(): Date {
        return this.getValue().lastUpdateTimestamp;
    }

    get isNavigationTriggered(): boolean {
        return this.getValue().isNavigationTriggered;
    }

    get intervalTimespan(): number {
        return this.getValue().intervalTimespan;
    }
    
    get featureToggles(): FeatureToggleSettings[] {
        return this.getValue().featureToggles;
    }
}
