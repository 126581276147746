import { Injectable } from '@angular/core';
import { identify, pendo } from '@itero/itero-pendo';
import { TimberService } from '@logging/timber.service';
import { FeatureToggle } from '@shared/enums';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { AppConfigService } from 'app/services/appConfig/appConfigService';

@Injectable({
	providedIn: 'root',
})
export class PendoService {
	applicatioName = 'myItero.com';
	private readonly serviceName = 'PendoService';
	constructor(
		private appconfig: AppConfigService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private timber: TimberService
	) {}

	logInfoFromPendoService = (message: string) => {
		this.timber.info(message, { module: this.serviceName });
	};

	initializePendo(contactId, companyId): void {
		const pendoApiKey = this.appconfig.appSettings.pendoApiKey;

		this.featuresToggleSettingsService.getAll().subscribe((featureFlags) => {
			const isPendoAvailable = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.PendoToggle);
			const isPendoInitByDefaultForAllClients = this.featuresToggleSettingsService.isVisibleFeature(
				FeatureToggle.PendoInitOnAppStart
			);
			const pendoFeatureFlagsPrefix = this.appconfig.appSettings.pendoFeatureFlagsPrefix;

			const pendoFeatureFlags = featureFlags
				.filter((key) => key.id.startsWith(pendoFeatureFlagsPrefix))
				.map((key) => {
					return key.id;
				});

			const visitorId = contactId?.toString();
			const accountId = companyId.toString();
			this.callPendo(isPendoAvailable, isPendoInitByDefaultForAllClients, pendoFeatureFlags, pendoApiKey, visitorId, accountId);
		});
	}

	identifyPendo(contactId: string, companyId: string) {
		try {
			this.featuresToggleSettingsService.getAll().subscribe((featureFlags) => {
				const pendoFeatureFlagsPrefix = this.appconfig.appSettings.pendoFeatureFlagsPrefix;
				const pendoFeatureFlags = featureFlags
					.filter((key) => key.id.startsWith(pendoFeatureFlagsPrefix))
					.map((key) => {
						return key.id;
					});
				identify(pendoFeatureFlags, contactId, companyId, this.applicatioName, this.logInfoFromPendoService);
			});
		} catch (error) {
			this.logInfoFromPendoService('Pendo service error');
		}
	}

	callPendo(
		isPendoAvailable: boolean,
		isPendoInitByDefaultForAllClients: boolean,
		pendoFeatureFlags: string[],
		pendoApiKey: string,
		visitorId: string,
		accountId: string
	): void {
		try {
			pendo(
				isPendoAvailable,
				isPendoInitByDefaultForAllClients,
				pendoFeatureFlags,
				pendoApiKey,
				visitorId,
				accountId,
				this.applicatioName,
				this.logInfoFromPendoService
			);
		} catch (error) {
			this.logInfoFromPendoService('Pendo service error');
		}
	}
}
