import {IShellEvent} from './IShellEvent';
import {Injectable} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {ShellCommunicationEvents} from '@shared/generalInterfaces';

@Injectable({providedIn: 'root'})
export class ShellTokenExpiredEvent implements IShellEvent {
	constructor(private authService: AuthService,
				private router: Router) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.TokenExpired;
	}

	handleCustomEvent(event): void {
		this.handle(event.detail.sessionId);
	}

	handlePostMessage(event): void {
		this.handle(event.payload.sessionId);
	}

	private handle(sessionId) {
		this.authService.logout(String(sessionId), {returnUrl: this.router.url}).pipe(take(1)).subscribe();
	}
}
