<div bsModal #loginSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button *ngIf="!mustChangePassword" id="btn-hide-settings" type="button" class="close close-sign" (click)="hide()" [disabled]="isSubmitting" [ngClass]="{'disabled' : isSubmitting}">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Login' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="settingsForm" class="form-horizontal" novalidate (keyup.enter)="submitSettings(settingsForm.value)" onsubmit="return false;">
                    <div class="form-group eup-textbox">
                        <label class="col-sm-4 control-label">{{'Settings.Email' | translate }}</label>
                        <div class="col-sm-8">
                            <p class="form-control-static">{{loginEmail}}</p>
                        </div>
                    </div>

                    <div class="form-group eup-textbox" [ngClass]="{ 'has-error' : !validationState.settingsPassword.valid && submitAttempted }">
                        <label for="settingsPassword" class="col-sm-4 control-label">{{'Settings.Current_Password' | translate }}</label>
                        <div class="col-sm-8">
                            <input formControlName="settingsPassword" type="password" class="form-control" name="settingsPassword" id="settingsPassword">
                            <span *ngIf="validationState.settingsPassword.errors.required"
                                  class="col-sm-12 help-block">{{'Settings.Password_Error' | translate }}</span>
                            <span *ngIf="validationState.settingsPassword.errors.badCredentials"
                                  class="col-sm-12 help-block">{{'Settings.Bad_Credentials' | translate }}</span>
                        </div>
                    </div>

                    <div class="form-group eup-textbox" [ngClass]="{ 'has-error' : !validationState.settingsNewPassword.valid && submitAttempted }">
                        <label for="settingsNewPassword" class="col-sm-4 control-label">{{'Settings.New_Password' | translate }}</label>
                        <div class="col-sm-8">
                            <input formControlName="settingsNewPassword" type="password" class="form-control" name="settingsNewPassword" id="settingsNewPassword">
                            <span *ngIf="validationState.settingsNewPassword.errors.required"
                                  class="col-sm-12 help-block">{{'Settings.New_Password_Error' | translate }}</span>
                            <span *ngIf="validationState.settingsNewPassword.errors.password" class="col-sm-12 help-block">
                                {{'Settings.Password_Policy_Error' | translate }}
                                <a href="javascript:void(0);" [href]="passwordPolicyUrl" target="_blank">{{'Settings.Password_Policy' | translate }}</a>
                            </span>
                        </div>
                    </div>

                    <div class="form-group eup-textbox" [ngClass]="{ 'has-error' : !validationState.settingsConfirmPassword.valid && submitAttempted }">
                        <label for="settingsConfirmPassword" class="col-sm-4 control-label">{{'Settings.Confirm_New_Password' | translate }}</label>
                        <div class="col-sm-8">
                            <input formControlName="settingsConfirmPassword" type="password" class="form-control" name="settingsConfirmPassword" id="settingsConfirmPassword">
                            <span *ngIf="validationState.settingsConfirmPassword.errors.required"
                                  class="col-sm-12 help-block">{{'Settings.New_Password_Confirm_Error' | translate }}</span>
                            <span *ngIf="validationState.settingsConfirmPassword.errors.equivalent"
                                  class="col-sm-12 help-block">{{'Settings.Password_Mismatch' | translate }}</span>
                        </div>
                    </div>

                    <div class="form-group eup-textbox" [ngClass]="{ 'has-error' : !validationState.settingsSecurityQuestion.valid && submitAttempted }">
                        <label for="settingsSecurityQuestion" class="col-sm-4 control-label">{{'Settings.Security_Question' | translate }}</label>
                        <div class="col-sm-8">
                            <select formControlName="settingsSecurityQuestion" class="form-control" name="settingsSecurityQuestion" id="settingsSecurityQuestion">
                                <option *ngFor="let question of securityQuestionList" [ngValue]="question">{{'Settings.' + question | translate }}</option>
                            </select>
                            <span *ngIf="validationState.settingsSecurityQuestion.errors.required"
                                  class="col-sm-12 help-block">{{'Settings.Security_Question_Error' | translate }}</span>
                        </div>
                    </div>
                    
                    <div class="form-group eup-textbox" [ngClass]="{ 'has-error' : !validationState.settingsSecurityAnswer.valid && submitAttempted }">
                        <label for="settingsSecurityAnswer" class="col-sm-4 control-label">{{'Settings.Security_Answer' | translate }}</label>
                        <div class="col-sm-8">
                            <input formControlName="settingsSecurityAnswer" type="text" class="form-control" name="settingsSecurityAnswer" id="settingsSecurityAnswer" >
                            <span *ngIf="validationState.settingsSecurityAnswer.errors.required"
                                  class="col-sm-12 help-block">{{'Settings.Security_Answer_Error' | translate }}</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-sm-2 text-left">
                            <a href="javascript:void(0);" [href]="passwordPolicyUrl" target="_blank">{{'PasswordPolicy.Password_Policy_Link' | translate }}</a>
                        </div>
                        <div class="col-sm-offset-4 col-sm-3 text-right">
                            <a *ngIf="!mustChangePassword" id="btn-cancel" class="cancel" (click)="hide()" [ngClass]="{'disabled' : isSubmitting || mustChangePassword}">{{'Settings.Cancel' | translate }}</a>
                        </div>
                        <div class="col-sm-3">
                            <input id="btn-submit" type="button" class="btn btn-primary btn-lg btn-block" value="{{'Settings.Submit' | translate }}"
                                   (click)="submitSettings(settingsForm.value)" [disabled]="isSubmitting">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>