import { Component, Input, ElementRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettingsService } from '../core/globalSettings.service';
import { RoleTypeEnum } from '../shared/enums';

@Component({
	selector: 'eup-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: [ './error-page.component.scss' ]
})
export class ErrorPage implements AfterContentChecked {
	@Input() statusCode: string;
	@Input() errorHeader: string;
	@Input() errorDescription: string;
	@Input() errorDescriptionToDisplay: string;
	@Input() errorDescriptionToDisplay2: string;
	linkToText: string;
	rolePath: string;

	constructor(
		private router: Router,
		private el: ElementRef,
		private translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService
	) {}

	ngAfterContentChecked(): void {
		if (!!this.errorDescription && this.errorDescription.length > 0) {
			const homeLinkStartIndex = this.errorDescription.indexOf('<a>');
			const homeLinkEndIndex = this.errorDescription.indexOf('</a>');
			this.rolePath = this.globalSettingsService.rolePath();

			this.linkToText = this.errorDescription.substring(homeLinkStartIndex + 3, homeLinkEndIndex);
			this.errorDescriptionToDisplay = this.errorDescription.substring(0, homeLinkStartIndex);
			this.errorDescriptionToDisplay2 = this.errorDescription.substring(homeLinkEndIndex + 4);

			if (this.rolePath) {
				this.linkToText = this.translateService.instant('Errors.Homepage');
			} else {
				this.linkToText = this.translateService.instant('Errors.Login');
			}
		}
	}

	public isUserLoggedIn(): boolean {
		return !!this.rolePath;
	}
}
