import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { take } from 'rxjs';
import { AuthService } from '../../../services/authentication/auth.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class TokenExpired implements ICommunicationEvent {
	constructor(private authService: AuthService,
		private router: Router) {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.TokenExpired;
	}

	async handleEvent(event: any) {
		this.authService.logout(String(event.data.sessionId), {returnUrl: this.router.url}).pipe(take(1)).subscribe();
	}
}