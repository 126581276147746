import moment from 'moment';

export class NowProvider {
	now() {
		return moment.utc();
	}

	convertFromString(date: string) : moment.Moment {
		return date ? moment(date) : this.now();
	}
}
