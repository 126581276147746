import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ternary'
})
export class TernaryPipe implements PipeTransform {
	transform<Treason extends any, Tresult extends any>(value: Treason, truthy: Tresult, falsy: Tresult): Tresult {
		return value ? truthy : falsy;
	}
}
