import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FeatureToggleSettings } from '../featuresToggleSettings.model';

const intervalTimespanInHours = 12;

const hoursToMilliseconds = (hours: number) => hours * 60 * 60 * 1000;


export interface FeatureToggleState {
	featureToggles: FeatureToggleSettings[];
	lastUpdateTimestamp: Date;
	intervalTimespan: number; // in milliseconds
	isNavigationTriggered: boolean;
}

export const createInitialState = (): FeatureToggleState => ({
	featureToggles: [],
	lastUpdateTimestamp: null,
	intervalTimespan: hoursToMilliseconds(intervalTimespanInHours),
	isNavigationTriggered: false
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'feature-toggle' })
export class FeatureToggleStore extends Store<FeatureToggleState> {
	constructor() {
		super(createInitialState());
	}
}
