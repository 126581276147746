import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthService } from 'app/services/authentication/auth.service';
import { Router } from '@angular/router';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { VisitReportService } from '../services/visit-report/visit-report.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { TimberService } from '@logging/timber.service';

@Component({
	selector: 'app-visit-report',
	templateUrl: './visit-report.component.html',
	styleUrls: ['./visit-report.component.scss'],
	exportAs: 'child'
})

export class VisitReportComponent extends BaseDestroyable implements AfterViewInit, OnDestroy {
	@ViewChild('visitReportModal') visitReportModal: ModalDirective;
	@ViewChild('visitReportContainer') visitReportContainer: ElementRef<HTMLElement>;
	visitReportTag = 'itero-visit-report'; // old by npm-package, make sure npm-package creates webcomponent with the same name
	visitReportTag2 = 'itero-visit-report-microfrontend'; // new by microfrontend, can be changed without impact
	compName = 'VisitReportComponent';

	constructor(private authService: AuthService,
				private router: Router,
				private visitReportService: VisitReportService,
				private logger: TimberService
				) {
		super();
	}

	ngAfterViewInit(): void {
		if (this.visitReportService.isNewVisitReportAvailable()) {
			this.visitReportService.initiallyLoadSdk(this.visitReportContainer.nativeElement).catch(() => {
				// logged in service
			});
		}
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	show(orderId: number) {
		const visitReportElementPromise = this.visitReportService.isNewVisitReportAvailable()
			? this.createMicrofrontendVisitReport(orderId)
			: this.createVisitReportFromPackage(orderId);

		visitReportElementPromise.then(el => this.tryAttachListeners(el));

		this.visitReportModal.show();
	}

	hide(visitReportElement: HTMLElement) {
		this.destroyWebComponent(visitReportElement);
		this.visitReportModal.hide();
	}

	private createMicrofrontendVisitReport(orderId: number): Promise<HTMLElement> {
		return this.visitReportService.createMicrofrontendVisitReport(orderId, this.visitReportContainer.nativeElement, this.visitReportTag2)
			.catch(e => {
				this.logger.debug(`Start to fallback to legacy npm package`, { module: this.compName });
				// fallback to npm
				return this.createVisitReportFromPackage(orderId);
			});
	}

	private tryAttachListeners(visitReportElement: HTMLElement): void {
		if (!visitReportElement) {
			return;
		}

		visitReportElement.addEventListener('onCloseEditor', (event) => {
			if ((event as any).detail) {
				this.hide(visitReportElement);
			}
		});
		visitReportElement.addEventListener('userUnauthorized', () => {
			this.authService.logoutClient({ returnUrl: this.router.url });
		});
	}

	createVisitReportFromPackage(orderId: number): Promise<HTMLElement> {
		return this.visitReportService.createVisitReportFromPackage(orderId, this.visitReportContainer.nativeElement, this.visitReportTag);
	}

	private destroyWebComponent(visitReportElement: HTMLElement ) {
		const webComponent = visitReportElement
			|| this.visitReportContainer.nativeElement.querySelector(this.visitReportTag2)
			|| this.visitReportContainer.nativeElement.querySelector(this.visitReportTag);

		if (!('remove' in Element.prototype)) {
			(<Element>webComponent).parentNode.removeChild(webComponent);
		} else {
			webComponent.remove();
		}
	}
}
