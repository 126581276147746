import { Injectable } from '@angular/core';
import { RoutingData } from '@core/eupRoutes.service';
import { Consts } from '@shared/consts';
import { CachedData, ClientApplicationConfiguration } from '@shared/generalInterfaces';

@Injectable({
	providedIn: 'root',
})
export class ClientDiscoveryService {
    private static clientApplications = new CachedData<ClientApplicationConfiguration[]>([], 0);
    public static expiration = 60*60*1000;
    private static uri = 'component-config/all';

    static async getClientApplication(appId: string): Promise<ClientApplicationConfiguration> {
        let appsConfig = this.clientApplications.data.filter(x => x.appId === appId);
        if(this.clientApplications.expiresOn > new Date().getTime() && appsConfig.length === 1)
            return appsConfig[0];
        
        await this.initApplications();        
        return this.clientApplications.data.filter(x => x.appId === appId)[0];
    }

    static async getClientApplications(appIds: string[]): Promise<ClientApplicationConfiguration[]> {
        let uniqueAppIds = this.removeDuplications(appIds);
        let appsConfig = this.clientApplications.data.filter(x => uniqueAppIds.includes(x.appId));
        if(this.clientApplications.expiresOn > new Date().getTime() || appsConfig.length === uniqueAppIds.length) {
            return appsConfig;
        }
        
        await this.initApplications();        
        return this.clientApplications.data.filter(x => uniqueAppIds.includes(x.appId));
    }

    private static async initApplications() {
        try {
            const routes = JSON.parse(localStorage.getItem(Consts.Storage.Routes)) as RoutingData;
            const response = await fetch(`${routes.clientDiscoveryUrl}${this.uri}`);
            const apps = await response.json() as ClientApplicationConfiguration[];
            if(apps && apps.length > 0) {
                this.clientApplications = new CachedData<ClientApplicationConfiguration[]>(apps, this.expiration);
            } else {
                throw new Error('Client applications response is empty');
            }
        } catch(err) {
            throw err;
        }
    }

    static reset(){
        this.clientApplications = new CachedData<ClientApplicationConfiguration[]>([], 0);
    }

    private static removeDuplications(arr: any[]): any[] {
        return [...new Set(arr)];
    }
}
