import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { HttpParams } from '@angular/common/http';
import { EupRoutesService } from '@core/eupRoutes.service';
import { ViewerSoftwareOptions, SnapshotSoftwareOptions } from '@shared/enums';
import {ViewerSoftwareOptionsResponse, SnapshotResponse} from '@shared/generalInterfaces';

@Injectable({
	providedIn: 'root'
})
export class SoftwareOptionsService {
	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
	) { }
	
	isSoftwareOptionEnabled$(softwareOptionParams: HttpParams): Observable<Object[]> {
		return this.http
			.get(this.eupRoutesService.userSettings.areScannerSoftwareOptionsAvailableUrl,
				{ params: softwareOptionParams, withCredentials: true },
				true,
				false);
	}

	hasEvx(companyId): Observable<boolean> {
		return this.getSoftwareOptions(companyId, ViewerSoftwareOptions)
			.pipe(
				map((res) => {
					return !!(res[ViewerSoftwareOptions.ShouldEnableEvx] 
						|| res[ViewerSoftwareOptions.NGNIRI])
				})
			);
	}

	getSoftwareOptionsForViewer(companyId): Observable<ViewerSoftwareOptionsResponse> {
		return this.getSoftwareOptions(companyId, ViewerSoftwareOptions)
			.pipe(
				map((res) => {
					const responseObject = new ViewerSoftwareOptionsResponse();
					responseObject.enableNiriReviewTool = !!res[ViewerSoftwareOptions.ShouldEnableEvx.toString()];
					responseObject.enableIntraoralCameraTool = !!res[ViewerSoftwareOptions.ShouldEnableIntraoralCameraTool.toString()];
					responseObject.enableTextureMappingInViewer = !!res[ViewerSoftwareOptions.ShouldEnableTextureMappingInViewer.toString()];
					return responseObject;
				})
			);
	}

	getSnapshotSoftwareOptions(companyId): Observable<SnapshotResponse> {
		return this.getSoftwareOptions(companyId, SnapshotSoftwareOptions)
			.pipe(
				map((res) => {
					const responseObject = new SnapshotResponse(
						res[SnapshotSoftwareOptions.ShouldDisableCaptureTool.toString()],
						res[SnapshotSoftwareOptions.ShouldDisableDrawingTool.toString()]
					);
					return responseObject;
				})
			);
	}

	private getSoftwareOptions(companyId, softwareOptions) {
		let params = new HttpParams();
		for (const softwareOption in softwareOptions) {
			if (!isNaN(Number(softwareOption))) {
				params = params.append('softwareOptions', softwareOption);
			}
		}
		params = params.append('companyId', companyId);
		return this.isSoftwareOptionEnabled$(params);
	}

	isSoftwareOptionExists(softwareOptionId: number, softwareOptions: number[]): boolean {
		return softwareOptions && softwareOptions.some(so => so === softwareOptionId);
	}

	areSoftwareOptionExist(softwareOptionIds: number[], softwareOptions: number[]): boolean {
		return softwareOptions && softwareOptions.some(so => softwareOptionIds.includes(so));
	}

	areAllSoftwareOptionsExists(softwareOptionIds: number[], softwareOptions: number[]): boolean {
		return softwareOptions && softwareOptionIds.every(id => softwareOptions.includes(id));
	}

	getCompanySoftwareOptions(selectedCompanyId): Observable<number[]> {
		return this.http.get(this.eupRoutesService.userSettings.getCompanySoftwareOptionIds + selectedCompanyId)
			.pipe(
				map((res) => {
					return res as number[];
				})
			);
	}
}
