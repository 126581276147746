import { Injectable } from '@angular/core';

@Injectable()
export class PlatformDetectorService {
	private isIEVal: boolean;
	private ieVersion: number;
	private isEdgeVal: boolean;
	private isMacVal: boolean;
	private isWindowsVal: boolean;
	private isiPadVal: boolean;
	private isSafariVal: boolean;
	private isHeadlessChrome: boolean;

	get isIE() {
		return this.isIEVal;
	}

	get isIE11OrOlder() {
		return this.isIEVal && this.ieVersion <= 11;
	}

	get isSafari() {
		return this.isSafariVal;
	}

	get isEdge() {
		return this.isEdgeVal;
	}

	get isMac() {
		return this.isMacVal;
	}

	get isWindows() {
		return this.isWindowsVal;
	}

	get isTablet() {
		return !this.isMacVal && !this.isWindowsVal && !this.isHeadlessChrome;
	}

	get isiPad() {
		return this.isiPadVal;
	}

	initialize(): void {

		const userAgent = window.navigator.userAgent;
		const platform = window.navigator.platform;

		this.isiPadVal = /iPad/i.test(userAgent) || this.testIfIpadByScreenSizes();
		this.isEdgeVal = /Edge/i.test(userAgent);
		this.isIEVal = /MSIE|Trident\//i.test(userAgent);
		this.isHeadlessChrome = /HeadlessChrome/i.test(userAgent);

		const msie = userAgent.indexOf('MSIE '); // for versions 10 and older
		const trident = userAgent.indexOf('Trident/'); // for ie 11
		if (msie > 0) {
			this.ieVersion = parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
		}

		if (trident > 0) {
			const rv = userAgent.indexOf('rv:');
			this.ieVersion = parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
		}

		this.isWindowsVal = /Win32/i.test(platform);
		this.isMacVal = /Mac68K|MacPPC|MacIntel/i.test(platform);

		// Safari Contains just Safari, Chrome contains both
		this.isSafariVal = userAgent.indexOf('Safari') > 1 && userAgent.indexOf('Chrome') === -1;
	}

	testIfIpadByScreenSizes() {
		// https://51degrees.com/blog/device-detection-for-apple-iphone-and-ipad
		// I used this method because in the iPad 
		// in the setting > Safari > Request Desktop Website
		// there is an option to trick the browser to think that the user agent is mac and not iPad 
		const { screen } = window;
		const { userAgent } = window.navigator;
		const { platform } = window.navigator;
		
		if (platform.match(/iOS/i) || platform.match(/Mac/i)) {
			if (screen.height / screen.width == 1024 / 768) {
				return true ;           
			}
			else if (window.screen.height / window.screen.width == 1112 / 834) {
				return true;
			}
			else if (window.screen.height / window.screen.width == 1366 / 1024)
			{
				return true;
			}
		}
	
		return false;
	}
}
