<div class="eup-dropdown">
    <div
        dropdown
        class="dropdown-container"
        (onHidden)="onDropdownHidden()"
        [isDisabled]="disabled"
        [attr.disabled]="!!disabled ? true : undefined"
    >
        <a dropdownToggle>{{ caption }}<span class="caret"></span></a>
        <ul class="dropdown-menu" *dropdownMenu>
            <li *ngFor="let item of items" (click)="selectItem(item)">{{ item.key }}</li>
        </ul>
    </div>
</div>
