import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'eup-generic-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: [ './textarea.component.scss' ]
})

export class TextareaComponent {
	@Input() label: string;
	@Input() name: string;
	@Input() disabled: boolean;
	@Input() id: string;
	@Input() rows: string;
	@Input() placeholder: string;
	@Input() inputName: string;
	textareaValue: string;
	@Output() textChange = new EventEmitter<string>();

	@Input()
	get text() {
		return this.textareaValue;
	}
	
	set text(val) {
		this.textareaValue = val;
		this.textChange.emit(this.textareaValue);
	}
}


