import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { IOSimSimulationInfo, IOSimSimulationInfoState } from './iosim-simulation-status-progress-state.model';
import { IOSimSimulationInfoStore } from './iosim-simulation-status-progress.store';

@Injectable({ providedIn: 'root' })
export class IOSimSimulationInfoQuery extends QueryEntity<IOSimSimulationInfoState, IOSimSimulationInfo> {
	constructor(protected store: IOSimSimulationInfoStore) {
		super(store);
	}

	selectProgress(orderId: string) {
		return this.selectInfo(orderId).pipe(
			map(store => store.progress)
		);
	}

	selectStatus(orderId: string) {
		return this.selectInfo(orderId).pipe(
			map(store => store.progress?.simulationStatus)
		);
	}

	selectInfo(orderId: string) {
		return this.selectEntity(orderId).pipe(
			filter(store => !!store)
		);
	}
}
