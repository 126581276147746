import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'app/errors/errorHandler.service';
import { createReferralPdfDefinition, ReferralLabels, ReferralValues } from './referral/referral-pdf-definition';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import pdfFonts from './fonts';
import { createPdf, fonts } from 'pdfmake/build/pdfmake.min';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { labAgreementPdfDefinitions } from './lab-agreement/lab-agreement-definitions';

export interface PdfMake {
	createPdf: typeof createPdf;
	fonts: typeof fonts;
}
@Injectable({
	providedIn: 'root',
})
export class PdfService {
	pdfMake: PdfMake  = null;

	constructor(
		private translateService: TranslateService,
		private errorHandler: ErrorHandlerService,
		private globalSettings: GlobalSettingsService,
		private eupRoutesService: EupRoutesService,
		private ngZone: NgZone
		) {	}

	async createPdf(docDefinition: TDocumentDefinitions, fontsToImport?: string) {

		if (!this.pdfMake) {
			this.pdfMake = await this.importPdfMake() as PdfMake;
		}
		const fontsName = fontsToImport ?? this.getFontNameByLanguageCode(this.globalSettings.getLanguage().code);
		this.pdfMake.fonts = this.getFont(fontsName);

		return this.pdfMake.createPdf(docDefinition);
	}

	async createReferralPdf(values: ReferralValues) {
		const labels = this.translateReferralLabels();
		const pdfFontName = this.getFontNameByLanguageCode(this.globalSettings.getLanguage().code);
		const pdfDefinition = createReferralPdfDefinition(values, labels, pdfFontName) as unknown as TDocumentDefinitions;

		return await this.createPdf(pdfDefinition, pdfFontName);
	}

	translateReferralLabels(): ReferralLabels {
		const labels: ReferralLabels = {
			referralCode: this.translateService.instant('ScanningCenter.ReferralCode'),
			patientName: this.translateService.instant('ScanningCenter.PatientName'),
			practicePhone: this.translateService.instant('ScanningCenter.PracticePhone'),
			caseType: this.translateService.instant('ScanningCenter.CaseType'),
			practiceAddress: this.translateService.instant('ScanningCenter.PracticeAddress'),
			dueDate: this.translateService.instant('ScanningCenter.DueDate'),
			doctorName: this.translateService.instant('ScanningCenter.DoctorName'),
			treatmentStage: this.translateService.instant('ScanningCenter.TreatmentStage'),
			practiceName: this.translateService.instant('ScanningCenter.PracticeName'),
			notes: this.translateService.instant('ScanningCenter.Notes'),
			referralValidFor: this.translateService.instant('ScanningCenter.ReferralValidFor', { numberDays: 60 }),
		};

		return labels;
	}

	async createLabAgreementPdf(body: string, languageCode: string) {
		const header = this.translateService?.instant('Lab.AgreementModalLabel');
		const fontName = this.getFontNameByLanguageCode(languageCode);
		const iteroLogo = `${this.origin}/assets/images/iteroLogo.png`;
		const definitions = labAgreementPdfDefinitions(header, body, fontName, iteroLogo);

		(await this.createPdf(definitions, fontName)).download(header);
	}

	async importPdfMake() {
		const pdfMake = import('pdfmake/build/pdfmake.min');

		return Promise.all([pdfMake]).then(([pdfMakeModule]) => {
			return pdfMakeModule.default;
		}).catch(() => {
			this.errorHandler.displayErrorMessage(
				this.translateService.instant('Errors.StandardErrorTitle'),
				this.translateService.instant('Errors.StandardErrorMessageSingle'));
		});
	}

	private getFontNameByLanguageCode(languageCode: string): string {
		switch (languageCode) {
			case 'ja-JP':
				return 'YuGothic';
			case 'ko-KR':
				return 'Malgun';
			case 'th-TH':
				return 'Tahoma';
			case 'zh-CN':
				return 'YaHei';
			case 'ru-RU':
				return 'Nunito';
			default:
				return 'Avenir';
		}
	}

	get origin() {
		return window.location.origin;
	}

	private getFont(pdfFontName: string) {
		const cdnFontsPath = this.eupRoutesService.cdnPath + 'fonts/';
		const fonts = {
			[pdfFontName]: { ...pdfFonts[pdfFontName] }
		};

		for (const fontType in fonts[pdfFontName]) {
			fonts[pdfFontName][fontType] = cdnFontsPath + fonts[pdfFontName][fontType];
		}

		return fonts;
	}
}
