<div
  class="slide-toggle"
  [ngClass]="{ checked, disabled }"
  [attr.aria-checked]="checked"
  (click)="clickHandler()"
  (blur)="blurHandler()"
>
  <input [checked]="checked" role="switch" type="checkbox" class="input-hidden"/>
  <div class="thumb"></div>
  <div class="track"></div>
</div>
