export const getCopy = Symbol('getCopy');
function getCopyImpl<K,V>(this: Map<K, V[]>, key: K) {
    const value = this.get(key);
    if (Array.isArray(value)) {
        return [...value]; 
    } else if (value && typeof value === 'object') {
        return JSON.parse(JSON.stringify(value)); 
    }
    return value;
}

declare global {
    export interface Map<K,V> {
        [getCopy]: typeof getCopyImpl;
    }
}

Map.prototype[getCopy] = getCopyImpl

