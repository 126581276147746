import { IOSimPlusIntegrationService } from 'app/services/iosim-plus-integration/iosim-plus-integration.service';
import { RealTimeSimulationStatusProgress, SimulationStatusEnum } from '@shared/iosim-plus/models/simulationStatus';
import { RoleTypeEnum } from '../../../shared/enums';
import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { TimberService } from '@logging/timber.service';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { IOSimSimulationStatusProgressService } from '../../iosim-simulation.store/iosim-simulation-status-progress.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { BaseSignalR } from '../signalR/base-signalR';
import { IOSimSimulationInfo } from '../../iosim-simulation.store/iosim-simulation-status-progress-state.model';

@Injectable({ providedIn: 'root' })
export class RealTimeUpdateSimulationService extends BaseSignalR<RealTimeSimulationStatusProgress> {
	private readonly channelName = 'SimulationMessageReceived';
	private readonly hubName = 'iosimPlusNotificationHub';
	constructor(
		private eupRoutesService: EupRoutesService,
		private globalSettingsService: GlobalSettingsService,
		private simulationStatusProgressService: IOSimSimulationStatusProgressService,
		private integrationService: IOSimPlusIntegrationService,
		timberService: TimberService
	) {
		super(timberService);
	}

	checkPermissionsAndStartRealUpdateSimulation() {
		this.integrationService.isIOSimPlusAllowed$
			.pipe(
				filter((isIOSimPlusAllowed) => isIOSimPlusAllowed && this.globalSettingsService.get().roleType === RoleTypeEnum.Doctor),
				tap(async (_) => {
					try {
						await this.startNotifications();
					} catch (error) {
						this.timberService.error(error, { module: 'RealTimeUpdateSimulationService' });
					}
				}),
				take(1),
				takeUntil(this.stopNotifications$)
			)
			.subscribe();
	}

	private async startNotifications(): Promise<void> {
		const notificationAgentUrl = this.eupRoutesService.notificationAgentUrl;
		const token = this.globalSettingsService.get().jwtAccessToken;
		if (!notificationAgentUrl) {
			const message = 'notificationAgentUrl url is not specified in global settings';
			throw Error(message);
		}
		const hubUrl = `${notificationAgentUrl}${this.hubName}`;
		await this.initMessages(hubUrl, this.channelName, token);
		this.messageBroker$
			.pipe(
				filter((simulationStatusProgress) => simulationStatusProgress.simulationStatus !== SimulationStatusEnum.NotStarted),
				tap((simulationStatusProgress) => this.addOrUpdateSimulationStatusProgress(simulationStatusProgress)),
				takeUntil(this.stopNotifications$)
			)
			.subscribe();
	}

	private addOrUpdateSimulationStatusProgress(simulationStatusProgress: RealTimeSimulationStatusProgress) {
		const simulationStatus: IOSimSimulationInfo = {
			orderId: simulationStatusProgress.orderId.toString(),
			progress: {
				simulationStatus: simulationStatusProgress.simulationStatus,
				startSimulationTime: simulationStatusProgress.startSimulationUTCTime,
				expectedDuration: simulationStatusProgress.expectedDurationInSeconds,
			},
		};

		this.simulationStatusProgressService.addOrUpdateSimulationStatusProgress(simulationStatus);
	}
}
