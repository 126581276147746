import { Component, Input } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
	selector: 'eup-spinner',
	// styles of this component are derived from _spinner (inside styles.scss)
	template: `<div class="spinner-container initialized" *ngIf="active">
					<div class="spinner"></div>
				</div>`
})
export class SpinnerComponent {
	active = false;

	constructor(private spinner: SpinnerService) {
		spinner.onActiveChanged.subscribe((isActive: boolean) => {
			this.active = isActive;
		});
	}
}
