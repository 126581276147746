<div bsModal #exocadMiniAppModal="bs-modal" class="modal fade" (onShown)="onShown()" (onHidden)="onHidden()">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="exocadMiniAppModal.hide()">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
                <div class="modal-title">   
                    <span [innerHTML]="'Orders.AppDownloadHeader' | translate: { 'AppShortName': unformattedAppShortName }"></span>
                </div>
            </div>

            <div class="modal-body">
                <div class="icon"><svg class="svg-info96 svg-info96-dims"></svg></div>
                <div class="text">
                    <p [innerHTML]="'Orders.AppDownloadLeavingMIDCMessage' | translate: { 'AppLongName': formattedAppLongName }"></p>
                    <p>
                        <span [innerHTML]="'Orders.AppDownloadPromptMessage' | translate: { 'AppShortName': formattedAppShortName }"></span>
                        <a (click)="onMyDesignAppDownloadInstallerClick()" rel="noopener">{{ 'Orders.DownloadAndInstallLink' | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
