import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '@shared/utils.service';
import { UserAuthenticationDetails } from '../models/userAuthenticationDetails.model';
import { ApiContactAuthenticationAnswer } from '../models/apiContactAuthenticationAnswer.model';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { EupRoutesService } from '@core/eupRoutes.service';

@Injectable()
export class RecoverPasswordService {
	private _isUserAuthenticationDetailsFake = false;
	private dummyQuestions: string[] = [
		this.translateService.instant('RecoverPassword.FavoriteMovie'),
		this.translateService.instant('RecoverPassword.FavoriteColor'),
		this.translateService.instant('RecoverPassword.FavoriteBook')
	];

	private getFakeSecurityQuestion = (userName: string): UserAuthenticationDetails => {
		this._isUserAuthenticationDetailsFake = true;
		const sampleDummyQuestion = this.utils.randomItemFromArray(this.dummyQuestions);
		return {
			id: this.utils.uuid(),
			userName,
			recoveryEmail: userName,
			passwordQuestion: sampleDummyQuestion,
			clientId: this.utils.randomClientId()
		};
	}

	get isUserAuthenticationDetailsFake() {
		return this._isUserAuthenticationDetailsFake;
	}

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private utils: Utils,
		private translateService: TranslateService
	) {}

	getUserAuthenticationDetails(userName: string): Observable<UserAuthenticationDetails> {
		return this.http.get(this.eupRoutesService.recoverPassword.getUserAuthenticationDetails(userName), false).pipe(
			map((userAuthenticationDetails: UserAuthenticationDetails) => {
				return userAuthenticationDetails === null ? this.getFakeSecurityQuestion(userName) : userAuthenticationDetails;
			})
		);
	}

	resetUserPassword(apiContactAuthenticationAnswer: ApiContactAuthenticationAnswer): Observable<boolean> {
		return this.http.post(this.eupRoutesService.recoverPassword.resetUserPassword, apiContactAuthenticationAnswer, {}, false)
			.pipe(
				map((res: any) => {
					return !!res;
				})
			);
	}
}
