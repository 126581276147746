import { Component, ViewChild, OnDestroy, OnInit, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as AT from '../actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'eup-downloadLinkModal',
	templateUrl: './downloadLinkModal.component.html',
	styleUrls: ['./downloadLinkModal.component.scss'],
})
export class DownloadLinkModal implements OnDestroy, OnInit {
	@Input() linkTextKey: string;
	@Input() messageTextKey: string;
	@Input() customWidth: string;
	@Input() disclaimer: string = '';

	@ViewChild('downloadLinkModal', { static: true }) downloadLinkModal: ModalDirective;
	softwareName = '';
	softwareDownloadLink = '';
	timeoutId: any;

	constructor(private eupObserver: EupObserver, private translateService: TranslateService) {}

	ngOnInit() {
		this.downloadLinkModal.config = {};
		this.downloadLinkModal.config.backdrop = false;
	}

	ngOnDestroy() {
		clearTimeout(this.timeoutId);
	}

	show(name: string, link: string): void {
		this.messageTextKey = this.translateService.instant(this.messageTextKey).replace('OrthoCAD', name);
		this.softwareName = name;
		this.softwareDownloadLink = link;
		this.downloadLinkModal.show();
		clearTimeout(this.timeoutId);
	}

	onShown() {
		this.timeoutId = setTimeout((() => this.downloadLinkModal.hide()) as any, 15 * 1000);
	}

	onHidden() {
		this.eupObserver.emit({
			action: AT.EDIT_WITH_ITERO_LAB_SUCCESS,
			payload: null,
		});
		clearTimeout(this.timeoutId);
	}
}
