import { Injectable  } from '@angular/core';
import { Query } from '@datorama/akita';
import { WindowsApplicationState } from './windows-application-state.model';
import { WindowsApplicationStore } from './windows-application.store';

@Injectable({ providedIn: 'root' })
export class WindowsApplicationQuery extends Query<WindowsApplicationState> {
	readonly applications$ = this.select(state => state.applications);
	readonly promptLogin$ = this.select(state => state.promptLogin);
	get promptLogin(): boolean { return this.getValue().promptLogin };

	constructor(protected store: WindowsApplicationStore) {
		super(store);
	}
}