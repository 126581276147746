<div class="die-orders">
    <div class="die-orders__order-line">
        <div class="adder-title">
            <div class="adder-title-header">
                {{('Lab.NoOfModels' | translate)}}
            </div>
            {{('Lab.IncludingDies' | translate)}}
        </div>
        <div class="adder-box">
            <item-adder
                (emittedNumber)="setModelOrders($event)"
                [disabled]="disabled"
                [fieldNumber]="numOfModels"
                [debouncePeriod]="300"
            ></item-adder>
        </div>
    </div>

    <div class="die-orders__order-line">
        <div class="adder-title">
            <div class="adder-title-header">
                {{('Lab.AdditionalDies' | translate)}}
            </div>
            {{ 'Lab.PerTooth' | translate }}
        </div>
        <div class="adder-box">
            <item-adder
                (emittedNumber)="setDieOrders($event)"
                [disabled]="disabled"
                [fieldNumber]="additionalDies"
                [debouncePeriod]="300"
            ></item-adder>
        </div>
    </div>
    <div class="die-orders__order-line">
    <eup-generic-checkbox
        class="die-orders__die-ditch-cb"
        [checked]="dieDitch"
        [disabled]="disabled"
        (checkBoxStateEmitter)="setDieDitch($event)"
    >
</eup-generic-checkbox>
<label class="die-orders__die-ditch-label">{{('Lab.DieDitch' | translate)}}</label>
    </div>
    
</div>
