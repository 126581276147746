import { RoleTypeEnum } from '@shared/enums';

export interface IShellContext {
	session: IShellContextSession;
	user: IShellContextUser;
	bizCtx: IShellContextBizCtx;
	UI: IShellContextUI;
	security: IShellContextSecurity;
	platformServices: IShellContextPlatformService[];
}

export interface IShellContextSession {
	sessionId: string;
	sessionType: string;
}

export interface IShellContextUser {
	loggedInUserId: number;
	roleType: number;
}

export interface IShellContextBizCtx {
	contactId: number;
	companyId: number;
	region: string;
	patientId: string;
	orderId: number;
	csmMessageId: string;
	rxId: string;
}

export interface IShellContextUI {
	dateFormat: string;
	language: string;
}

export interface IShellContextSecurity {
	accessToken: string;
}

export interface IShellContextPlatformService {
	name: string;
	url: string;
}

export interface  IShellContextDeprecated {
	loggedInUserId: number;
	selectedDoctorId: number;
	selectedCompanyId: number;
	accessToken: string;
	sessionId: string;
	sessionType: string;
	orderId: number;
	csmMessageId: string;
	patientId: string;
	selectedLanguage: string;
	dateFormat: string;
	roleType: RoleTypeEnum;
	region: string;
	refreshTokenUrl: string;
	rxId: string;
}

export const PlatformServiceNames = {
	SessionManagementRefresh: 'SessionManagementRefresh',	
	AssetManager: 'AssetManager'
}