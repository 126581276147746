import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { IosimInfo, PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { BaseDestroyable } from '@core/base-destroyable';
import { IOSimPlusIntegrationService } from '../../../services/iosim-plus-integration/iosim-plus-integration.service';
import { switchMap, take, tap } from 'rxjs';
import { OrderSimulationInfo, SimulationStatusEnum } from '@shared/iosim-plus/models/simulationStatus';
import { IOSimSimulationInfoQuery } from '../../../services/iosim-simulation.store/iosim-simulation-status-progress.query';

@Injectable({providedIn: 'root'})
export class StartIosimSimulation extends BaseDestroyable implements ICommunicationEvent {
	constructor(private iosimPlusIntegrationService: IOSimPlusIntegrationService,
		private simulationStatusProgressQuery: IOSimSimulationInfoQuery) {
        super();
    }
	
	get eventName(): string {
		return PlatformCommunicationEvents.StartIosimSimulation;
	}

	async handleEvent(event: any) {
		const { simulationStatus } = this.simulationStatusProgressQuery.getEntity(event.data.orderId)?.progress;
		switch (simulationStatus) {
			case SimulationStatusEnum.NotStarted:
				this.iosimPlusIntegrationService.getOrderSimulationInfo(event.data.orderId, event.data.companyId).pipe(
					switchMap((orderSimulationInfo: OrderSimulationInfo) => 
						this.iosimPlusIntegrationService.startSimulation(
							event.data.orderId,
							orderSimulationInfo.orderCode,
							event.eventHeader.publisher
						)
					), take(1)).subscribe();
				break;
			case SimulationStatusEnum.LowQualityScan:
			case SimulationStatusEnum.Fail:
				this.iosimPlusIntegrationService.getOrderSimulationInfo(event.data.orderId, event.data.companyId).pipe(
					take(1)).subscribe((orderSimulationInfo: OrderSimulationInfo) => {
						var iosimInfo = {
							orderId: event.data.orderId,
							orderCode: orderSimulationInfo.orderCode,
							viewedScreen: event.eventHeader.publisher,
							isQualifiedForIoSimPlus: orderSimulationInfo.isQualifiedForIoSimPlus
						} as IosimInfo;
						this.iosimPlusIntegrationService.showStatusInfoModal$.next(iosimInfo);
					});				
				break;
			default:
				break;
		}
	}
}