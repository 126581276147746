import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'dateOfBirth'
})
export class DateOfBirthPipe implements PipeTransform {

	transform(dateOfBirth: string | Date, dateOfBirthFormat: string, regulatoryDOBMask?: string): string {
		if (!dateOfBirth) {
			return '';
		}

		if (typeof dateOfBirth !== 'string') {
			return moment(dateOfBirth).format(dateOfBirthFormat);
		}

		if (regulatoryDOBMask) {
			const formattedDateOfBirth = moment(dateOfBirth, regulatoryDOBMask, true);
			if (formattedDateOfBirth.isValid()) {
				return formattedDateOfBirth.format(dateOfBirthFormat);
			}
		}

		if (moment(dateOfBirth, dateOfBirthFormat, true).isValid()) {
			return dateOfBirth;
		}

		const result = moment(dateOfBirth);
		return result.isValid() ? result.format(dateOfBirthFormat) : '';
	}
}
