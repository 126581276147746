import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from '@shared/genericUI/components/checkbox/checkbox.component';
import { DropdownComponent } from '@shared/genericUI/components/dropdown/dropdown.component';
import { EupDropdownComponent } from '@shared/genericUI/components/eup-dropdown/eup-dropdown.component';
import { EupButtonComponent } from '@shared/genericUI/components/eupButton/eupButton.component';
import { InputComponent } from '@shared/genericUI/components/input/input.component';
import { ItemAdderComponent } from '@shared/genericUI/components/itemAdder/itemAdder.component';
import { RadioButtonComponent } from '@shared/genericUI/components/radioButton/radioButton.component';
import { TextareaComponent } from '@shared/genericUI/components/textarea/textarea.component';
import { TextSendComponent } from '@shared/genericUI/components/textSend/textSend.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestrictInputDirective } from '@shared/restrictInput/restrictInput.directive';
import { CaretAnimatedComponent } from '@shared/genericUI/components/caretAnimated/caretAnimated.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { SlideToggleComponent } from '@shared/genericUI/components/slideToggle/slideToggle.component';
import {LoadableDropdownComponent} from '@shared/genericUI/components/loadable-dropdown/loadable-dropdown.component';

const genericUiComponents = [
	CheckboxComponent,
	DropdownComponent,
	EupDropdownComponent,
	EupButtonComponent,
	InputComponent,
	ItemAdderComponent,
	RadioButtonComponent,
	TextareaComponent,
	TextSendComponent,
	CaretAnimatedComponent,
	SlideToggleComponent,
	LoadableDropdownComponent
];

@NgModule({
	declarations: [...genericUiComponents, RestrictInputDirective],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule
	],
	exports: [genericUiComponents]
})
export class GenericUIModule { }
