import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IKeyValue } from 'app/interfaces/IKeyValue';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'eup-dropdown',
	templateUrl: './eup-dropdown.component.html',
	styleUrls: ['./eup-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EupDropdownComponent),
			multi: true
		}
	]
})
export class EupDropdownComponent implements OnInit, OnChanges, ControlValueAccessor {
	@Input()
	items: IKeyValue[] = [];
	
	@Input() id: string = '';

	@Input()
	defaultCaption: string;

	selectedItem: IKeyValue;

	caption: string;
	disabled = false;

	private propagateChange = (_: any) => {};
	private propagateTouched = () => {};
	@Output() onSelect?: EventEmitter<any> = new EventEmitter();
	@Output() onItemsChanges?: EventEmitter<any> = new EventEmitter();

	ngOnInit() {
		this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const newItems = changes['items'];
		const newDefaultCaption = changes['defaultCaption'];

		if (!newItems && !newDefaultCaption) {
			return;
		}

		if (!!newItems) {
			this.items = newItems.currentValue;
			this.onItemsChanges?.emit({id: this.id});
		}

		if (!!newDefaultCaption) {
			this.defaultCaption = newDefaultCaption.currentValue;
		}

		this.initComponent();
	}

	selectItem(selectedItem: IKeyValue) {
		this.selectedItem = selectedItem;
		this.caption = !!this.selectedItem ? this.selectedItem.key : this.defaultCaption;

		this.propagateChange(!!this.selectedItem ? this.selectedItem.value : this.selectedItem);
		this.onSelect.emit(this.selectedItem);
	}

	onDropdownHidden() {
		this.propagateTouched();
	}

	writeValue(obj: any): void {
		const foundItem = this.items.find(item => item.value === obj);
		this.selectItem(foundItem);
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	private initComponent() {
		if (!this.items.length) {
			if (!!this.defaultCaption) {
				this.caption = this.defaultCaption;
			} else {
				return;
			}
		}

		if (!this.defaultCaption && !this.selectedItem) {
			this.selectItem(this.items[0]);
		}

		this.caption = this.defaultCaption ? this.defaultCaption : this.selectedItem.key;
	}
}
