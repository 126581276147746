<span *ngIf="user === 'doctor'">
    <button type="button"
        class="btn btn-primary"
        [class.windows-only]="windowsOnly"
        *ngIf="available"
        (click)="onClick()">
        {{title}}
    </button>
</span>

<span *ngIf="user === 'lab'">
    <li class="menu-option" *ngIf="available"
    (click)="onClick()">
    <span>{{title}}</span>
    </li>
</span>

<eup-downloadLinkModal *ngIf="modalAllowed" id="modal-download-link" linkTextKey="Orders.LinkText" [disclaimer]="disclaimer" messageTextKey="Orders.MessageText"
[customWidth]="customWidth"></eup-downloadLinkModal>