import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'eup-toggle-button',
	templateUrl: './toggleButton.component.html',
	styleUrls: [ './toggleButton.component.scss' ]
})
export class ToggleButtonComponent {
	@Input() toggledCheckboxValue: boolean;
	@Output() onToggleChanged = new EventEmitter<boolean>();

	onChange(event) {
		this.onToggleChanged.emit(this.toggledCheckboxValue);
	}
}
