import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class EupGlobalValidators {
	static email(control: UntypedFormControl) {
		let EMAIL_REGEXP = /^(.+@.+\..+)$/;

		return EMAIL_REGEXP.test(control.value)
			? null
			: {
					email: {
						valid: false
					}
				};
	}

	static password(control: UntypedFormControl) {
		let PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&amp;*()_\-+=[{\]};:&lt;&gt;|./?]{8,}$/;

		return PASSWORD_REGEXP.test(control.value)
			? null
			: {
					password: {
						valid: false
					}
				};
	}

	static required(control: UntypedFormControl) {
		let REQUIRED_REGEXP = /.*\S.*/;

		return REQUIRED_REGEXP.test(control.value)
			? null
			: {
					required: {
						valid: false
					}
				};
	}

	static companySelection(control: UntypedFormControl): any {
		if (control.value === '' || control.value === 0 || control.value === '0' || control.value === null) {
			return {
				selectedCompanyId: {
					valid: false
				}
			};
		}

		return null;
	}

	static matchingValues(sourceKey: string, targetKey: string) {
		return (group: UntypedFormGroup): any => {
			const sourceInput = group.controls[sourceKey];
			const targetInput = group.controls[targetKey];

			if (sourceInput.value !== targetInput.value) {
				// initialize errors object if it doesn't exist
				if (!targetInput.errors) {
					targetInput.setErrors({});
				}

				targetInput.errors['equivalent'] = true;

				return { equivalent: { valid: false } };
			}

			// previous errors exist
			if (targetInput.errors && targetInput.errors.hasOwnProperty('equivalent')) {
				// removes the error from control errors list
				delete targetInput.errors['equivalent'];

				// clear errors object if no other error exists
				if (Object.keys(targetInput.errors).length === 0) {
					targetInput.setErrors(null);
				}
			}

			return null;
		};
	}
}
