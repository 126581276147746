import { NgModule } from '@angular/core';
import { SharedAnonymousModule } from '../shared/shared.anonymous.module';
import { BadRequestComponent } from './badRequest.component';
import { ForbbidenComponent } from './forbidden.component';
import { InternalServerErrorComponent } from './internalServerError.component';
import { PageNotFoundComponent } from './pageNotFound.component';
import { ErrorPage } from './error-page.component';

@NgModule({
	imports: [ SharedAnonymousModule ],
	declarations: [
		BadRequestComponent,
		ForbbidenComponent,
		InternalServerErrorComponent,
		PageNotFoundComponent,
		ErrorPage
	],
	exports: [ BadRequestComponent, ForbbidenComponent, InternalServerErrorComponent, PageNotFoundComponent ]
})
export class ErrorsModule {}
