import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDestroyable } from '@core/base-destroyable';
import { EventBusTesterComponent } from './event-bus-tester/event-bus-tester.component';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AppConfigService } from 'app/services/appConfig/appConfigService';


@Component({
	selector: 'internal-debug',
	templateUrl: './internal-debug.component.html',
	styleUrls: ['./internal-debug.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		EventBusTesterComponent
	],
	providers: [],
})

export class InternalDebugComponent
	extends BaseDestroyable
	implements OnInit, OnDestroy {
	debug = false;
	isMinimized = true;

	constructor(private route: ActivatedRoute,
		private appConfigService: AppConfigService) {
		super();
	}

	closeDebugTool = () => {
		this.debug = false;
	};

	toggleMinimizeDebugTool = (event: Event) => {
		event?.stopImmediatePropagation();
		this.isMinimized = !this.isMinimized;
	};

	ngOnInit(): void {
		this.route.queryParams
			.pipe(takeUntil(this.componentAlive$))
			.subscribe((params) => {
				const { debug } = params;
				const isProd = this.appConfigService?.appSettings?.isProduction;
				this.debug = debug === 'true' && !isProd;
			});
	}
}

