import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService } from '@shared/settings.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { ExportSettings } from '@shared/generalInterfaces';
import { combineLatest, Subscription } from 'rxjs';
import { DownloadNotificationService } from '@shared/downloadNotification/downloadNotification.service';
import { Order } from '../../doctors/orders/orders.service';
import { DownloadFileInfo, ExportInfoDto, CreateOrthodonticsExportFileRequestDto, CreateRestorativeExportFileRequestDto, CreateExportFilesRequestDto } from '@shared/downloadNotification/fileInfo.model';
import { ISettingsData } from '../../interfaces/ISettingsData';
import { ILabCompanySettingsDto } from '../../interfaces/ILabCompanySettingsDto';
import { SpinnerService } from '@core/spinner/spinner.service';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { SoftwareOptionsForCompany } from '@shared/enums';
import { Utils } from '../utils.service';
import {  ExportTypeFormatEnum } from '../enums';
@Component({
	selector: 'eup-labExport',
	templateUrl: './labExport.component.html',
	styleUrls: [ './labExport.component.scss' ]
})
export class LabExportModal {
	private shouldSaveAsDefaultValues: boolean;

	@ViewChild('labExportModal') private labExportModal: ModalDirective;

	@Output() public downloadClicked: EventEmitter<any> = new EventEmitter();

	defaultValuesAreSet: boolean;
	isInitialize = true;
	cadCamSystemTypes: any[];
	dataFormatOptions: any[];
	exportTypeIrecordOptions: any[];
	fileTypeOptions: any[];
	hidePatientName: boolean;
	disablePatientDetailsBySWOpiont: boolean;

	orthodonticsOrders: Order[];
	restorativeOrders: Order[];
	numOfOrders = 0;
	numOfOrthodonticsCases = 0;
	numOfRestorativeCases = 0;

	defaultExportSettings: ExportSettings = {
		exportTypeIrecord: ExportTypeFormatEnum.None,
		dataFormat: 0,
		fileType: 0,
		cadCamSystemTypeId: 0
	};

	exportSettings: ExportSettings = {
		exportTypeIrecord: ExportTypeFormatEnum.None,
		dataFormat: 0,
		fileType: 0,
		cadCamSystemTypeId: 0
	};

	exportSubscription: Subscription;
	exportWoSubscription: Subscription;
	defaultLabCompanySetting: ILabCompanySettingsDto;
	tab = -1;

	private changedExportSettings: { [key: string]: boolean } = {};

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private downloadNotificationService: DownloadNotificationService,
		private spinnerService: SpinnerService,
		private softwareOptionsService: SoftwareOptionsService,
		private utils: Utils
	) {}

	private subscribeExportSettings(settingsRes: ISettingsData) {
		// init options
		this.exportTypeIrecordOptions = settingsRes.exportTypeIRecordOptions;
		this.dataFormatOptions = settingsRes.dataFormatOptions;
		this.fileTypeOptions = settingsRes.fileTypeOptions;
		this.cadCamSystemTypes = settingsRes.cadCamSystemTypes;
	}

	private subscribeCompanySettingsData(labCompanySettingsDto: ILabCompanySettingsDto) {
		this.exportSettings.exportTypeIrecord = this.setDefaultValue(labCompanySettingsDto.orthoExportSettings.iRecord);
		this.exportSettings.dataFormat = this.setDefaultValue(labCompanySettingsDto.orthoExportSettings.dataFormat);
		this.exportSettings.fileType = this.setDefaultValue(labCompanySettingsDto.orthoExportSettings.fileType);
		this.exportSettings.cadCamSystemTypeId = this.setDefaultValue(
			labCompanySettingsDto.restoExportSettings.cadCamSystemType
		);
		this.hidePatientName = labCompanySettingsDto.patientAnonymization;
		this.defaultLabCompanySetting = labCompanySettingsDto;
	}

	show(orders: Order[]): void {
		this.spinnerService.start();
		this.disablePatientDetailsBySWOpiont = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.shouldDisableHidePatientDetails, this.globalSettings.get().companySoftwareOptions);
		const combined = combineLatest(
			this.settingsService.getSettings(this.globalSettings.get().selectedCompanyId, true, false),
			this.settingsService.getLabCompanySettings(true, false)
		);

		combined.subscribe((response: [ISettingsData, ILabCompanySettingsDto]) => {
			this.subscribeExportSettings(response[0]);
			this.subscribeCompanySettingsData(response[1]);

			this.defaultValuesAreSet = true;
			this.shouldSaveAsDefaultValues = false;
			this.orthodonticsOrders = orders.filter((o) => o.isOrthoOrder);
			this.restorativeOrders = orders.filter((o) => !o.isOrthoOrder);
			this.numOfOrthodonticsCases = this.orthodonticsOrders.length;
			this.numOfRestorativeCases = this.restorativeOrders.length;
			this.numOfOrders = this.numOfOrthodonticsCases + this.numOfRestorativeCases;

			this.tab = this.numOfRestorativeCases > 0 ? 1 : 2;
			this.labExportModal.show();
			this.spinnerService.stop();
		});
	}

	hide() {
		this.labExportModal.hide();
		this.tab = -1;
	}

	saveDefaultValues() {
		if (this.restorativeOrders.length) {
			this.defaultLabCompanySetting.restoExportSettings.cadCamSystemType = this.exportSettings.cadCamSystemTypeId;
		}

		if (this.orthodonticsOrders.length) {
			this.defaultLabCompanySetting.orthoExportSettings.dataFormat = this.exportSettings.dataFormat;
			this.defaultLabCompanySetting.orthoExportSettings.fileType = this.exportSettings.fileType;
			this.defaultLabCompanySetting.orthoExportSettings.iRecord = this.exportSettings.exportTypeIrecord;
		}

		this.settingsService.setLabCompanySettings(this.defaultLabCompanySetting).subscribe(() => {});
	}

	export = () => {
		const settings = this.globalSettings.get();
		const clientId = settings.contactId;

		const requestData: CreateExportFilesRequestDto = {};

		const restorative = this.restorativeOrders.map((o) => { return this.createRestoRequestData(clientId, o) });
		if (restorative.length > 0) {
			requestData.restorativeExportRequest = { dtos: restorative };
		}

		const orthodontics = this.orthodonticsOrders.map((o) => { return this.createOrthoRequestData(clientId, o) });
		if (orthodontics.length > 0) {
			requestData.orthodonticsExportRequest = { dtos: orthodontics };
		}

		this.subscribeToExportInfoBulk(requestData);

		if (!this.defaultValuesAreSet && this.shouldSaveAsDefaultValues) {
			this.saveDefaultValues();
		}

		const result = [...this.orthodonticsOrders, ...this.restorativeOrders].map(item => item.id);
		this.downloadClicked.emit(result);
		this.hide();
	}

	private createRestoRequestData(clientId: number, order: Order): CreateRestorativeExportFileRequestDto {
		var request = new CreateRestorativeExportFileRequestDto();
		request.clientId = clientId;
		request.orderId = order.id;
		request.orderDetailsId = order.lastDetailsId;
		request.cadCamSystemTypeId = this.exportSettings.cadCamSystemTypeId;
		request.patientAnonymization = this.disablePatientDetailsBySWOpiont ? false : this.hidePatientName;
		var today = new Date();
		request.creationTime = today.toUTCString();
		return request;
	}

	private createOrthoRequestData(clientId: number, order: Order): CreateOrthodonticsExportFileRequestDto {
		var request = new CreateOrthodonticsExportFileRequestDto();
		request.clientId = clientId;
		request.orderId = order.id;
		request.orderDetailsId = order.lastDetailsId;
		request.fileFormat = this.exportSettings.dataFormat;
		request.fileType = this.exportSettings.fileType;
		request.typeFormat = this.exportSettings.exportTypeIrecord;
		request.patientAnonymization = this.disablePatientDetailsBySWOpiont ? false : this.hidePatientName;
		var today = new Date();
		request.creationTime = today.toUTCString();
		return request;
	}

	private addFilesToDownload(requestData: CreateExportFilesRequestDto) {
		if (requestData.orthodonticsExportRequest && requestData.orthodonticsExportRequest.dtos.length > 0){
			requestData.orthodonticsExportRequest.dtos.forEach((req) => {
				this.downloadNotificationService.addFile(req);
			});
		}
		
		if (requestData.restorativeExportRequest && requestData.restorativeExportRequest.dtos.length > 0)
		{
			requestData.restorativeExportRequest.dtos.forEach((req) => {
				this.downloadNotificationService.addFile(req);
			});
		}
	}

	private subscribeToExportInfoBulk(requestData: CreateExportFilesRequestDto) {
		// check if download file ready by ideOrder work order
		this.exportWoSubscription = this.downloadNotificationService
			.getExportInfoBulkByWorkOrder(requestData)
			.subscribe((res: ExportInfoDto[]) => {
				if (!res) {
					return;
				}

				const directDownloadItems = res.filter((t) => t.workOrderId > 0);
				directDownloadItems.forEach((info) => {
					var isOrtho = requestData.orthodonticsExportRequest && 
						requestData.orthodonticsExportRequest.dtos.some(o => o.orderId === info.orderId);
					if (isOrtho) {
						info.requestData = requestData.orthodonticsExportRequest.dtos.filter(o => o.orderId === info.orderId)[0];
					} else {
						info.requestData = requestData.restorativeExportRequest.dtos.filter(r => r.orderId === info.orderId)[0];
					}
				});

				if (requestData.orthodonticsExportRequest) {
					requestData.orthodonticsExportRequest.dtos = requestData.orthodonticsExportRequest.dtos.filter(
						(dto) => !directDownloadItems.some((x) => +x.orderId === dto.orderId)
					);
				}
				if (requestData.restorativeExportRequest) {
					requestData.restorativeExportRequest.dtos = requestData.restorativeExportRequest.dtos.filter(
						(dto) => !directDownloadItems.some((x) => +x.orderId === dto.orderId)
					);
				}

				directDownloadItems.forEach((info) => {
					const fileInfo: DownloadFileInfo = {
						workOrderId: info.workOrderId,
						orderId: info.orderId,
						orderDetailsId: -1,
						downloaded: true,
						requestData: info.requestData
					};
					this.downloadNotificationService.downloadFile(fileInfo);
				});

				this.addFilesToDownload(requestData);
			});
	}

	openTab(tab: number) {
		this.tab = tab;
	}

	onSelectExportTypeIrecord(item: any) {
		this.exportSettings.exportTypeIrecord = item.key;
		this.setDefaultValuesFlag('exportTypeIrecord', item.key !== this.defaultLabCompanySetting.orthoExportSettings.iRecord);
	}

	onSelectDataFormat(item: any) {
		this.exportSettings.dataFormat = item.key;
		this.setDefaultValuesFlag('dataFormat', item.key !== this.defaultLabCompanySetting.orthoExportSettings.dataFormat);
	}

	onSelectFileTypeOptions(item: any) {
		this.exportSettings.fileType = item.key;
		this.setDefaultValuesFlag('fileType', item.key !== this.defaultLabCompanySetting.orthoExportSettings.fileType);
	}

	onSelectCadCamSystemTypes(item: any) {
		this.exportSettings.cadCamSystemTypeId = item.key;
		this.setDefaultValuesFlag('cadCamSystemTypeId', item.key !== this.defaultLabCompanySetting.restoExportSettings.cadCamSystemType);
	}

	setDefaultValue(value: number) {
		return value === undefined || value === null ? 0 : value;
	}

	saveAsDefaultValues(evt: boolean): void {
		this.shouldSaveAsDefaultValues = evt;
	}

	setHidePatientName(event: boolean): void {
		this.hidePatientName = event;
	}

	private setDefaultValuesFlag(value: string, changed: boolean) {
		this.changedExportSettings[value] = changed;

		let hasChangedItem = false;
		for (const key in this.changedExportSettings) {
			if (this.changedExportSettings.hasOwnProperty(key)) {
				hasChangedItem = this.changedExportSettings[key];
				if (hasChangedItem) {
					break;
				}
			}
		}

		this.defaultValuesAreSet = !hasChangedItem;
	}
}
