import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, concatMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Consts } from '@shared/consts';
import { SessionInfo } from '../authentication/auth.service';
import { Location } from '@angular/common';
import { GlobalSettings } from '@core/globalSettings.service';
import { RoutingData } from '@core/eupRoutes.service';
import { TokenRefreshService } from '../authentication/token-refresh.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	private iTeroWebAuthApiUrl = '';

	constructor(private location: Location,
				private tokenRefreshService: TokenRefreshService
	) {}

	private get isLoginPage() {
		return this.location.path().includes('/login');
	}

	private get isSctAuthorizationEnabled() {
		const sessionInfo = this.getSessionInfo();
		return this.hasSessionInfo(sessionInfo);
	}

	private get authApiUrl() {
		if (this.iTeroWebAuthApiUrl && this.iTeroWebAuthApiUrl.trim() !== '') {
			return this.iTeroWebAuthApiUrl;
		}

		const routes = JSON.parse(localStorage.getItem(Consts.Storage.Routes)) as RoutingData;
		this.iTeroWebAuthApiUrl = routes.iTeroWebAuthApiUrl;
		return this.iTeroWebAuthApiUrl;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isSctAuthorizationEnabled) {
			return next.handle(request);
		}
		const sessionInfo = this.getSessionInfo();
		const finalRequest = this.setSessionHeaders(request, sessionInfo);
		return next.handle(finalRequest).pipe(
			catchError(error => {
				if (this.performRefreshForUnauthorizedAccessError(error) && !this.isLoginPage && !this.isAuthRequest(finalRequest) && !this.isLogoutRequest(request)) {
					return this.handleUnauthorizedAccessError({ request: finalRequest, sessionInfo, next });
				}
				return throwError(() => error);
			})
		);
	}

	private setSessionHeaders(request: HttpRequest<any>, sessionInfo: SessionInfo) {
		const settings = this.getSessionSettings();

		request = this.setHeader(request, Consts.HeaderKeys.Authorization, `Bearer ${sessionInfo.accessToken}`);

		if (this.hasContactId(settings)) {
			request = this.setHeader(request, Consts.HeaderKeys.LogonAsId, settings.contactId);
		}
		if (this.hasSelectedCompany(settings)) {
			request = this.setHeader(request, Consts.HeaderKeys.SelectedCompanyId, settings.selectedCompanyId);
		}

		if (this.hasSelectedDoctor(settings)) {
			request = this.setHeader(request, Consts.HeaderKeys.SelectedDoctorId, settings.selectedDoctorId);
		}

		return request;
	}

	private handleUnauthorizedAccessError({ request, sessionInfo, next }: { request: HttpRequest<any>; sessionInfo: SessionInfo; next: HttpHandler }) {
		return this.tokenRefreshService.refreshToken(sessionInfo).pipe(
			concatMap((updatedToken) => {
				return next.handle(this.setHeader(request, Consts.HeaderKeys.Authorization, `Bearer ${updatedToken}`));
			}),
			catchError(error => {
				if (error.refreshRequest) {
					throw error;
				}

				const shouldRelogin = this.isUnauthorizedAccessError(error);

				if (shouldRelogin) {
					this.tokenRefreshService.dispatchExpiredTokenEvent(sessionInfo.sessionId);
				}

				return throwError({...error, skipEupHttpErrorHandler: shouldRelogin});
			}),
		);
	}

	private isUnauthorizedAccessError(error) {
		return error instanceof HttpErrorResponse && error.status === 401;
	}

	private performRefreshForUnauthorizedAccessError(error) {
		return error instanceof HttpErrorResponse && error.status === 401 && !error.error?.ExceptionMessage?.includes('should logout');
	}

	private setHeader(request: HttpRequest<any>, headerKey: string, headerValue: any) {
		const headers = request.headers.set(headerKey, headerValue.toString());
		return request.clone({ headers });
	}

	private hasSessionInfo(sessionInfo: SessionInfo) {
		return sessionInfo.accessToken && sessionInfo.accessToken.trim() !== '' && sessionInfo.sessionId && sessionInfo.sessionId.trim() !== '';
	}

	private hasContactId(settings: GlobalSettings) {
		return settings.contactId && settings.contactId > 0;
	}

	private hasSelectedCompany(settings: GlobalSettings) {
		return settings.selectedCompanyId && settings.selectedCompanyId > 0;
	}

	private hasSelectedDoctor(settings: GlobalSettings) {
		return settings.selectedDoctorId && settings.selectedDoctorId > 0;
	}

	private getSessionInfo() {
		const sessionInfo = localStorage.getItem(Consts.Storage.SessionInfo);
		const emptyResponse = {} as SessionInfo;
		try {
			return JSON.parse(sessionInfo) as SessionInfo || emptyResponse;
		} catch {
			return emptyResponse;
		}
	}

	private getSessionSettings() {
		const settings = localStorage.getItem(Consts.Storage.Settings);
		const emptySettings = new GlobalSettings();
		try {
			return JSON.parse(settings) as GlobalSettings || emptySettings;
		} catch {
			return emptySettings;
		}
	}

	private isAuthRequest(request: HttpRequest<any>) {
		return request.url.includes(`${this.authApiUrl}session`);
	}

	private isLogoutRequest(request: HttpRequest<any>) {
		return request.url.includes(`/logout`);
	}
}
