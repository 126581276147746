import {IShellEvent} from './IShellEvent';
import {ShellCommunicationEvents} from '@shared/generalInterfaces';
import {take} from 'rxjs/operators';
import {ShellContextService} from '../../shell-context/shell-context.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ShellCompanyChangedEvent implements IShellEvent {

	constructor(private shellContextService: ShellContextService) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.CompanyChanged;
	}

	handleCustomEvent(event): void {

	}

	handlePostMessage(event, callback): void {
		this.shellContextService.getContext().pipe(take(1))
			.subscribe(context => {
				const message = {
					eventName: ShellCommunicationEvents.CompanyChanged,
					appName: 'shell',
					payload: event.payload
				};

				callback(event.appName, message);
			});
	}
}
