import { OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { AppenderType, EventType, LogLevel } from '@itero/timber/enums';
import { SessionInfo } from 'app/services/authentication/auth.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { environment } from 'environments/environment';
import { LocalStorageService } from '@core/localStorage.service';
import { BiEvent } from '@logging/interfaces/bi.events';
import { Consts } from '@shared/consts';

export abstract class BaseTimber extends BaseDestroyable implements OnDestroy {

	protected loggerTimber: Timber;
	protected biTimber: Timber;
	loggerTimberInitialized: boolean = false;

	constructor(protected appConfigService: AppConfigService,
		protected localStorageService: LocalStorageService) {
		super();
	}

	protected createLogger(loggedInContactId: number, selectedCompanyId: number): void {
		const config: IRemoteConfig = {
			appId: 'myitero.com',
			appenderTypes: [AppenderType.Remote, AppenderType.ConsoleNative],
			requiredFields: ['appId', 'module'],
			eventType: EventType.Log,
			url: this.appConfigService.appSettings.loggingEndpoint,
			userId: loggedInContactId,
			companyId: selectedCompanyId,
			minLogLevel: environment.production ? LogLevel[this.appConfigService.appSettings.minLogLevel] : LogLevel.Off
		};
		this.loggerTimber = new Timber(config);
		this.loggerTimberInitialized = true;
		console.log('Logger created with config:', config);
	}

	protected createBi(loggedInContactId: number, selectedCompanyId: number): void {
		if (this.appConfigService.isValidConfig('biEndpoint')) {
			const config: IRemoteConfig = {
				appId: 'myitero.com',
				appenderTypes: [AppenderType.BIRemote],
				url: this.appConfigService.appSettings.biEndpoint,
				userId: loggedInContactId,
				companyId: selectedCompanyId,
				minLogLevel: LogLevel.All
			};
			this.biTimber = new Timber(config);
			console.log('BI created with config:', config);
		} else {
			this.biTimber = null;
			console.error('BI object was not created');
		}
	}

	protected sendBiEvent(biEvent: BiEvent) {
		const stringEvent = JSON.stringify(biEvent.event);
		const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);
		if (sessionInfo && sessionInfo.sessionId) {
			this.biTimber?.biLog(LogLevel.All, {}, {
				extendedParameters: {
					sessionId: sessionInfo.sessionId,
					sessionType: Consts.SessionType,
					component: 'midc',
					type: biEvent.type,
					additionalFields: JSON.parse(stringEvent)
				}
			});
		}
	}

	public setUserConfiguration(contactId: number) {
		this.loggerTimber.setConfig({ userId: contactId });
	}

	public setCompanyConfiguration(companyId: number) {
		this.loggerTimber.setConfig({ companyId: companyId });
	}

	public clearUserConfiguration() {
		this.loggerTimber.setConfig({ userId: undefined, companyId: undefined });
	}
}