<div bsModal #accountPairingSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <ng-container *ngIf="!showQrCode">
                <div class="modal-header">
                    <h3 class="modal-title">
                        <button id="btn-hide-settings" type="button" class="close close-sign" (click)="accountPairingSettingsModal.hide()">
                            <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                        </button>
                        {{'Settings.Account_Pairing' | translate }}
                    </h3>
                </div>
                <div class="modal-body">
                    <div class="spinner" *ngIf="!pairingStatusLoaded"></div>
                    <eup-account-pairing
                        class="account-pairing"
                        *ngIf="hasSoftwareOptions && pairingStatusLoaded"
                        [pairType]="weChatPairType"
                        [pairingStatus]="pairingStatus"
                        (pair)="toggleExternalPairing($event)"
                    ></eup-account-pairing>            
                </div>
            </ng-container>

            <ng-container *ngIf="showQrCode">
                <div class="modal-header">
                    <h3 class="modal-title">
                        <button type="button" class="close close-sign" (click)="closeQrCode()">
                            <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                        </button>
                        {{'AccountPairing.PairingTitle' | translate:{'account': externalPairing.name} }}
                    </h3>
                </div>
                <div class="modal-body">

                    <div *ngIf="messengerType && companyId && doctorId">
                        <custom-pairing-ui
                            [messengerType]="messengerType"
                            [companyId]="companyId"
                            [doctorId]="doctorId"
                            [serverUrl]="serverUrl"
                            [languageCode]="translateService.currentLang"
                            [loggerAppenderUrl]="loggerAppenderUrl"
                        ></custom-pairing-ui>
                    </div>

                </div>
            </ng-container>

        </div>
    </div>
</div>