<div bsModal #downloadLinkModal="bs-modal" class="modal fade" (onShown)="onShown()" (onHidden)="onHidden()">
    <div class="modal-dialog modal-sm" [class.modal-extended-width]="customWidth === 'extended'">
        <div class="modal-content">
            <div class="modal-header">
                <button id="btn-hide-modal" type="button" class="close" (click)="downloadLinkModal.hide()">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
                <h4 id="modal-title" class="modal-title">{{ softwareName }}</h4>
            </div>
            <div id="modal-body" class="modal-body">
                {{ messageTextKey | translate }}<a href="{{ softwareDownloadLink }}" target="_blank">&nbsp;{{ linkTextKey | translate }}</a>
            </div>
            <div *ngIf="disclaimer !== ''">
                <div id="disclaimer-container" class="disclaimer-container">
                    {{ disclaimer | translate }}
                </div>
                <div id="regulation-icons-container" class="regulation-icons-container">
                    <div class="manufacturer-icon-container">
                        <div class="regulation-icon manufacturer-icon"></div>
                        <div class="manufacturer-title-text">{{ 'Orders.MfgTitle' | translate }}</div>
                        <div class="manufacturer-text">{{ 'Orders.MfgStreetAddress' | translate }}</div>
                        <div class="manufacturer-text">{{ 'Orders.MfgCityAddress' | translate }}</div>
                        <div class="manufacturer-text">{{ 'Orders.MfgTel' | translate }}</div>
                        <div class="manufacturer-text">{{ 'Orders.MfgFax' | translate }}</div>
                    </div>
                    <div class="ec-rep-icon-container">
                        <div class="regulation-icon ec-rep-icon"></div>
                        <div class="ac-rep-title-text">{{ 'Orders.EcTitle' | translate }}</div>
                        <div class="ac-rep-text">{{ 'Orders.EcSubTitle' | translate }}</div>
                        <div class="ac-rep-text">{{ 'Orders.EcStreetAddress' | translate }}</div>
                        <div class="ac-rep-text">{{ 'Orders.EcCityAddress' | translate }}</div>
                        <div class="ac-rep-text">{{ 'Orders.EcCountryAddress' | translate }}</div>
                    </div>
                    <div class="regulation-icon ce-icon"></div>
                    <div class="regulation-icon md-icon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
