import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
	storage: Storage;

	constructor() {}

    get(cookieName: string): string {
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArr = decodedCookie.split(';');
        for(var i = 0; i < cookieArr.length; i++) {
            var cookie = cookieArr[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }

    check(cookieName: string): boolean {
        return !!this.get(cookieName);
    }

    set(cookieName: string, value: string, expiredDays: number): void {
        var date = new Date();
        date.setTime(date.getTime() + (expiredDays * 24 * 60 * 60 * 1000));
        var expires = 'expires='+ date.toUTCString();
        document.cookie = cookieName + '=' + value + ';' + expires + ';path=/';
    }
}
