import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';
import { AppConfigService } from 'app/services/appConfig/appConfigService';

@Injectable()
export class FeaturesToggleSettingsGuard  {
	constructor(
        private router: Router,
        private appConfigService: AppConfigService
    ) {}
    
    canLoad(): boolean {
        return this.canShowFeatureToggleSettings();
    }
 
    canActivate(): boolean {
        return this.canShowFeatureToggleSettings();
    }

    private canShowFeatureToggleSettings(): boolean {
        // Disable in production
        const result = !this.appConfigService.appSettings.isProduction;
        if (!result) {
			this.router.navigate(['/pagenotfound']);
        }
        return result;
    }
}
