import { Component, Input, AfterContentInit, Host } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '../../shared/utils.service';
import { GlobalSettingsService } from '../globalSettings.service';
import { PasswordPolicyConfig } from '../../shared/generalInterfaces';
import { EupRoutesService } from '../eupRoutes.service';
import { LoginSettingsModal } from '../../settings/login/loginSettings.component';
import { INotificationComponent } from '../../shared/notification/notification.service';
import { DomService } from '../dom.service';
import { NotificationComponent } from '../../shared/notification/notification.component';
import { ModalButton } from '../../shared/notification/notification.service';

@Component({
	selector: 'eup-password-policy-popup',
	templateUrl: './passwordPolicyPopup.component.html',
	styleUrls: [ './passwordPolicyPopup.component.scss' ]
})
export class PasswordPolicyPopupComponent implements INotificationComponent, AfterContentInit {
	@Input() data: any;

	public expirationDate: string;
	public showButtonText: string;
	public passwordPolicyPageUrl: string;
	public ppc: PasswordPolicyConfig;
	public needsImmediatePasswordChange: boolean;

	constructor(
		private domService: DomService,
		private notificationComponent: NotificationComponent,
		private eupRoutesService: EupRoutesService
	) {}

	ngAfterContentInit(): void {
		this.ppc = this.data.passwordPolicyConfig;
		this.needsImmediatePasswordChange = this.ppc.mustChangePasswordNow || this.ppc.isTemporaryPassword;
		if (this.ppc) {
			this.showButtonText = 'Edit Login Details';
			if (!(this.needsImmediatePasswordChange)) {
				this.expirationDate = formatDate(this.ppc.mustChangePasswordLastDate, undefined, this.data.dateFormat);
			}
		}
		this.passwordPolicyPageUrl = this.eupRoutesService.userSettings.matPasswordPolicyPageUrl.replace(
			'{0}',
			this.data.currentLang
		);
	}

	openPasswordPolicyPage() {
		window.open(this.passwordPolicyPageUrl, '_blank');
	}

	showLoginSettingsModal() {
		const componentRef = this.domService.appendComponentToBody(LoginSettingsModal);
		const instance = <LoginSettingsModal>componentRef.instance;
		instance.updateSecurityQuestionsData();
		instance.onHide.subscribe(() => {
			this.domService.removeComponent(componentRef);
		});
		setTimeout(() => {
			this.notificationComponent.hide(ModalButton.Ok);
			instance.show();
		}, 0);
	}
}
