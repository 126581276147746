import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mapper'
})
export class MapperPipe implements PipeTransform {
	transform(value: any, callback?: Function, ...args: any[]): any {
		return (callback && typeof callback === 'function') ? callback(value, ...args) : value;
	}
}
