import {
	Component,
	ViewChild,
	OnInit,
	OnDestroy,
	ComponentFactoryResolver,
	ViewContainerRef,
	Type,
	ElementRef
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationService } from './notification.service';
import {
	ModalOptions,
	ModalContext,
	ModalTemplateContext,
	DialogResult,
	ModalKeys,
	ModalButton,
	ModalIcon
} from './notification.service';

import { INotificationComponent } from './notification.service';
@Component({
	selector: 'eup-notification',
	templateUrl: './notification.component.html',
	styleUrls: [ './notification.component.scss' ]
})
export class NotificationComponent implements OnInit, OnDestroy {
	@ViewChild('modalContent') modalContent: ModalDirective;
	@ViewChild('templateBody', { read: ViewContainerRef })
	templateBody: ViewContainerRef;

	defaultClass = 'modal fade notification';
	notificationClass = this.defaultClass;
	title: string;
	content: string;
	options: ModalOptions;
	customConfirmMessage: string;

	okEnabled = false;
	cancelEnabled = false;
	yesEnabled = false;
	noEnabled = false;

	iconClass: string;
	iconImg: string;

	okType: ModalButton = ModalButton.Ok;
	cancelType: ModalButton = ModalButton.Cancel;
	yesType: ModalButton = ModalButton.Yes;
	noType: ModalButton = ModalButton.No;

	constructor(
		private notificationService: NotificationService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	showTemplate(template: Type<any>, title: string, data: any, options: ModalOptions) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(template);
		const templateBodyRef = this.templateBody;
		templateBodyRef.clear();
		const componentRef = templateBodyRef.createComponent(componentFactory);
		const instance = <INotificationComponent>componentRef.instance;
		instance.data = data;

		this.title = title;
		this.configureDialog(options);
		this.modalContent.show();
	}

	show(title: string, content: string, options?: ModalOptions, customConfirmMessage?: string) {
		this.title = title;
		this.content = content;
		this.customConfirmMessage = customConfirmMessage;
		this.configureDialog(options);
		this.modalContent.show();
	}

	private configureDialog(options?: ModalOptions) {
		if (options) {
			this.options = options;
		} else {
			this.options = {
				buttonCombination: ModalKeys.OkCancel,
				icon: ModalIcon.Info
			};
		}

		this.setButtons(this.options);
		this.setIcon(this.options);
		if(this.options.isAlwaysOnTop){
			this.notificationClass += ' alwaysFirst';
		}
	}

	hide(keys: ModalButton) {
		if (this.templateBody) {
			this.templateBody.detach();
		}
		if (this.content) {
			this.content = '';
		}
		if (this.title) {
			this.title = '';
		}
		this.notificationClass = this.defaultClass;

		this.modalContent.hide();
		this.notificationService.hideNotification.next(<DialogResult>{
			buttonPressed: keys
		});
	}

	ngOnInit(): void {
		this.notificationService.showNotification.subscribe((context: ModalContext) => {
			this.show(context.title, context.content, context.options, context.customConfirmMessage);
		});

		this.notificationService.showTemplateNotification.subscribe((context: ModalTemplateContext) => {
			this.showTemplate(context.template, context.title, context.data, context.options);
		});
	}

	ngOnDestroy(): void {
		this.notificationService.showNotification.unsubscribe();
		this.notificationService.showTemplateNotification.unsubscribe();
		this.notificationService.hideNotification.unsubscribe();
	}

	private setIcon(options: ModalOptions) {
		switch (options.icon) {
			case ModalIcon.Info:
				this.iconClass = 'info-icon';
				this.iconImg = '/assets/images/InformationIcon.png';
				break;
			case ModalIcon.Error:
				this.iconClass = 'alert-icon';
				this.iconImg = '/assets/images/AlertIcon.svg';
				break;
			case ModalIcon.Question:
				this.iconClass = 'question-icon ';
				this.iconImg = '/assets/images/QuestionIcon.png';
				break;
			default:
		}
	}

	private setButtons(options: ModalOptions): void {
		this.okEnabled = false;
		this.cancelEnabled = false;
		this.yesEnabled = false;
		this.noEnabled = false;

		switch (options.buttonCombination) {
			case ModalKeys.Ok:
				this.okEnabled = true;
				break;
			case ModalKeys.OkCancel:
				this.okEnabled = true;
				this.cancelEnabled = true;
				this.cancelEnabled = true;
				break;
			case ModalKeys.YesNo:
				this.noEnabled = true;
				this.yesEnabled = true;
				break;
			case ModalKeys.YesNoCancel:
				this.noEnabled = true;
				this.yesEnabled = true;
				this.cancelEnabled = true;
				break;
			default:
				this.okEnabled = false;
				this.cancelEnabled = false;
				this.yesEnabled = false;
				this.noEnabled = false;
				break;
		}
	}
}
