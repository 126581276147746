<div class="account-pairing">
    <div class="account-pairing__left-pane">
        <div class="account-pairing__icon-title-box">
            <div class="account-pairing__icon" [ngClass]="pairType.iconClass" [ngStyle]="iconStyles"></div>
            <span class="account-pairing__service-name" *ngIf="pairType.iconText">{{pairType.iconText}}</span>
        </div>
    </div>
    <div class="account-pairing__right-pane"
         [ngSwitch]="pairingStatus">
         
        <div *ngSwitchCase="AccountPairingStatus.NotPaired" >
            <div class="account-pairing__pairing-status-text">{{pairType.unpairedText}}</div>
            <eup-button class="account-pairing__pairing-btn"
                        size="big"
                        [buttonText]="'AccountPairing.PairAccount' | translate"
                        [clickHandler]="emitPairing"
            ></eup-button>
        </div>

        <div *ngSwitchCase="AccountPairingStatus.Paired">
            <div class="account-pairing__pairing-status-text">{{pairType.pairedText}}</div>
            <eup-button class="account-pairing__pairing-btn"
                        size="big"
                        [buttonText]="'AccountPairing.UnPairAccount' | translate"
                        [clickHandler]="emitUnpairing"
            ></eup-button>
        </div>

        <div *ngSwitchDefault>
            <div class="account-pairing__pairing-status-text">
                {{pairType.statusUnknownText}}
            </div>
        </div>
    </div>
</div>