<div bsModal #localizationSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button id="btn-hide-settings" type="button" class="close close-sign" (click)="localizationSettingsModal.hide()">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Localization' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.Language' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container" *ngIf="selectedLanguage">
                                <a dropdownToggle>{{selectedLanguage.englishName}} <span *ngIf="selectedLanguage.description">-</span> {{selectedLanguage.description}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let rowData of languages" (click)="setLanguage(rowData.code)">{{rowData.name}} <span *ngIf="rowData.localizedName">-</span> {{rowData.localizedName}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.DateFormat' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a dropdownToggle class="text-uppercase">{{selectedFormat}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let rowData of dateFormats" class="text-uppercase" (click)="setDateFormat(rowData)">{{rowData}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>	
            </div>
        </div>
    </div>
</div>