import { Component, Input } from '@angular/core';
import { IKeyValue } from 'app/interfaces/IKeyValue';

@Component({
	selector: 'eup-practice-information-block',
	templateUrl: './practice-information-block.component.html',
	styleUrls: ['./practice-information-block.component.scss']
})
export class PracticeInformationBlockComponent {
	@Input() caption: string;
	@Input() items: IKeyValue[] = [];
}
