import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'trim'
})
export class TrimPipe implements PipeTransform {
	transform(value: string, args: string[]): string {
		if (typeof value === 'string') {
			return value.trim();
		} else {
			throw 'TrimPipe error: Invalid input type, type must be string';
		}
	}
}
