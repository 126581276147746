import { IteroEventBus } from '@itero/itero-client-communication';
import { AppConfigService } from '../appConfig/appConfigService';
import { TimberService } from '@logging/timber.service';
import { Injectable } from '@angular/core';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';

@Injectable({ providedIn: 'root' })
export class CommunicationService {
    private eventBus: IteroEventBus;

    constructor(private appConfigService: AppConfigService, private timberService: TimberService) {
        var loggingEndpoint = this.appConfigService.appSettings.loggingEndpoint;
        this.eventBus = new IteroEventBus(loggingEndpoint);
    }

    async publishEvent(channelName: string, publisherAppId: string, eventName: string, targetApplications: Array<string>, eventPayload?: any): Promise<boolean> {
        if (this.canPublishEvent(eventName)) {
            var eventId = this.eventBus.generateEventId();
            return await this.eventBus.emit(channelName, publisherAppId, eventName, eventId, targetApplications, eventPayload).then(res => { 
                if (!res) {
                    this.timberService.error(`Failed to publish event ${eventName} to channel ${channelName} by application ${publisherAppId}`, { module: 'CommunicationService' });
                } else {
                    this.timberService.info(`Event ${eventName} published successfully by application ${publisherAppId} to channel ${channelName}`, { module: 'CommunicationService' });
                }
    
                return res;
            }).catch(err => {
                console.error('publishEvent returned error', err);
                this.timberService.error(JSON.stringify(err), { module: 'CommunicationService' });
                return false;
            });
        } else {
            console.error('publishEvent failed due to missing system fields.');
            this.timberService.error(`Failed to publish event ${eventName} to channel ${channelName} by application ${publisherAppId}, missing region or session id.`, { module: 'CommunicationService' });
            return false;
        }
	}

    subscribeToChannel(channelName: string, callbackAppId: string, callbackFunc: Function) : boolean {
		var subscribedSuccessfully =  this.eventBus.on(channelName, callbackAppId, callbackFunc);
        if (!subscribedSuccessfully) {
            this.timberService.error(`Application ${callbackAppId} failed to subscribe to channel ${channelName}`, { module: 'CommunicationService' });
        } else {
            this.timberService.info(`Application ${callbackAppId} subscribed successfully to channel ${channelName}`, { module: 'CommunicationService' });
        }

        return subscribedSuccessfully;
	}

    unsubscribeFromChannel(channelName: string, appIdToUnsubscribe: string) : void {
		this.eventBus.unsubscribe(channelName, appIdToUnsubscribe);
	}

    setRegion(region: string) {
        this.eventBus.setRegion(region);
    }

    setSessionInfo(sessionId: string) {
        this.eventBus.setSessionId(sessionId);
    }

    private canPublishEvent(eventName: string): boolean{
        return eventName === PlatformCommunicationEvents.InitContext ||
            (this.eventBus.getRegion() !== undefined && this.eventBus.getRegion() !== '' && 
            this.eventBus.getSessionId() !== undefined && this.eventBus.getSessionId() !== '')
    }
}