import { Injectable } from '@angular/core';
import { IOSimSimulationInfoStore } from './iosim-simulation-status-progress.store';
import { IOSimSimulationInfo } from './iosim-simulation-status-progress-state.model';
import { SimulationStatusEnum } from '@shared/iosim-plus/models/simulationStatus';

@Injectable({ providedIn: 'root' })
export class IOSimSimulationStatusProgressService {
	constructor(private simulationStatusProgressStore: IOSimSimulationInfoStore) {}

	addOrUpdateSimulationStatusProgress(simulationStatusProgress: Partial<IOSimSimulationInfo>) {
		const isQualifiedForIoSimPlus = simulationStatusProgress.isQualifiedForIoSimPlus ?? true;
		const notSupportedScanDate = simulationStatusProgress.notSupportedScanDate ?? false;
		const needChangeToFailed = !isQualifiedForIoSimPlus || notSupportedScanDate;
		const orderId = simulationStatusProgress.orderId;
		this.simulationStatusProgressStore.upsert(
			orderId,
			(oldState: IOSimSimulationInfo) => ({
				...oldState,
				...simulationStatusProgress,
				progress: {
					...oldState.progress,
					...simulationStatusProgress.progress,
					simulationStatus: needChangeToFailed ? SimulationStatusEnum.Fail : simulationStatusProgress.progress?.simulationStatus,
				},
			}),
			(orderId, newState) => ({ orderId,...newState })
		);
	}
}
