import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[referralMask]',
})
export class ReferralMaskDirective {
	private elementRef: ElementRef;
	constructor(public ngControl: NgControl, elementRef: ElementRef) {
		this.elementRef = elementRef;
	}

	@HostListener('ngModelChange', ['$event'])
	onModelChange(event) {
		this.onInputChange(event, false);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		this.onInputChange(event.target.value, true);
	}

	onInputChange(event, backspace) {
		let newVal = event.replace(/\D/g, '');
		const { selectionStart, selectionEnd } = this.elementRef.nativeElement;
		let carretPosition = selectionEnd === 4 || selectionEnd === 8 ? selectionEnd + 1 : selectionEnd;

		if (backspace) {
			newVal = `${event.substring(0, selectionStart)}${event.substring(selectionEnd, event.length)}`.replace(/\D/g, '');
		}

		if (newVal.length === 0) {
			newVal = '';
		} else if (newVal.length <= 3) {
			newVal = `${newVal.replace(/^(\d{0,3})/, '$1').substring(0, 3)}`;
		} else if (newVal.length <= 6) {
			newVal = `${newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2')}`;
		} else if (newVal.length <= 10) {
			newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
			carretPosition = 12;
		} else {
			newVal = newVal.substring(0, 10);
			newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
			carretPosition = 12;
		}

		this.ngControl.valueAccessor.writeValue(newVal);
		this.elementRef.nativeElement.selectionStart = carretPosition;
		this.elementRef.nativeElement.selectionEnd = carretPosition;
	}
}
