import { Injectable } from '@angular/core';
import { RxNote } from './lib/notes.model';
import { NotesParser } from './lib/notes.parser';

@Injectable({
	providedIn: 'root',
})
export class RxNotesParserService {
	notesParser = new NotesParser();

	parse(text: string): RxNote[] {
		return this.notesParser.parseToFormattedNotes(text);
	}
}
