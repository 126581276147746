import { Directive, ElementRef, Host, OnInit, Input } from '@angular/core';
import { TableComponent } from './table/table.component';

@Directive({
	selector: '[table-focus-element]'
})
export class TableFocusElementDirective implements OnInit {
	@Input() rowId: string;
	@Input() rowHeight: number;

	public constructor(private el: ElementRef, @Host() private table: TableComponent) {}

	ngOnInit(): void {
		const row = this.table.getInnerTableDiv().nativeElement.querySelector(`[id='${this.rowId}']`);
		const innerTableBottom = this.table.getInnerTableDiv().nativeElement.getBoundingClientRect().bottom;
		const rowBottom = row.getBoundingClientRect().bottom + this.rowHeight;

		const scroll = rowBottom > innerTableBottom;
		if (scroll) {
			row.scrollIntoView();
		}
	}
}
