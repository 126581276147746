import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { ShellContextService } from '../../../services/shell-context/shell-context.service';
import { Consts } from '@shared/consts';
import { SessionInfo } from '../../../services/authentication/auth.service';

@Injectable({providedIn: 'root'})
export class TokenRefreshed implements ICommunicationEvent {
	constructor(private shellContextService: ShellContextService) {	}
	
	get eventName(): string {
		return PlatformCommunicationEvents.TokenRefreshed;
	}

	async handleEvent(event: any) {
		if (event.eventHeader.publisher != 'platform') {			
			this.updateLocalStorage(event.data.accessToken, event.systemHeader.sessionId);
			this.shellContextService.updateContext(context => {
				context.security.accessToken = event.data.accessToken;
			});
		}
	}

	private updateLocalStorage(accessToken: string, sessionId: string) {
		const sessionInfo = JSON.parse(localStorage.getItem(Consts.Storage.SessionInfo)) as SessionInfo;
		sessionInfo.accessToken = accessToken;
		sessionInfo.sessionId = sessionId; 
		localStorage.setItem(Consts.Storage.SessionInfo, JSON.stringify(sessionInfo));
	}
}