import { Injectable, OnDestroy} from '@angular/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { Subscription, Subject } from 'rxjs';
import {  map, tap, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { BusinessPartnerTypes, FeatureToggle, RoleTypeEnum, SoftwareOptionsForCompany } from '@shared/enums';
import { Company } from '@shared/generalInterfaces';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { Utils } from '@shared/utils.service';


@Injectable({
	providedIn: 'root',
})
export class UserSettingsService extends BaseDestroyable  implements OnDestroy  {
	isNotificationsEnabled = new Subject<boolean>();
	isNotificationsSwoEnabled: boolean;
	isMatNotificationsFfExists: boolean;
	isMidcNotificationsFfExists: boolean;
	notificationsEnabled: boolean;
	ftSubscription: Subscription;

	constructor(
		private globalSettingsService: GlobalSettingsService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private utils: Utils
	) {
		super();
		this.updateSoftwareOptions(this.globalSettingsService.get()?.companySoftwareOptions);
		this.trackSoftwareOptionsChanges();
		this.updateFeaturesToggle();
	}
	updateNotificationsSettingsVisibility() {
		var isDoctor = this.globalSettingsService.get()?.roleType === RoleTypeEnum.Doctor;
		
		this.notificationsEnabled = isDoctor && this.isDentalClinic() && this.isMidcNotificationsFfExists && 
		(this.isMatNotificationsFfExists || this.isNotificationsSwoEnabled);
		this.isNotificationsEnabled.next(this.notificationsEnabled);
	}
	public isDentalClinic(): Boolean {
		const companyInfo = this.getCompanyById(this.globalSettingsService.get()?.selectedCompanyId);
		if(companyInfo?.isDentalClinic) {
			return true;
		}
		const businessPartnerTypeNames =  companyInfo?.businessPartnerTypeNames?.split(',').map(value => value.trim());
		return businessPartnerTypeNames?.includes(BusinessPartnerTypes.DentalOffice);
	}
	private getCompanyById(id: number): Company {
		return this.globalSettingsService.get().companies.filter((c: Company) => c.id === id)[0];
	}
	updateSoftwareOptions(softwareOptions: number[]): void {
		if(!softwareOptions) { 
			this.isNotificationsSwoEnabled = false;
		} else {
			this.isNotificationsSwoEnabled = softwareOptions
				.some(so => so === SoftwareOptionsForCompany.EmailNotifications);
		}
		this.updateNotificationsSettingsVisibility();
	}
	updateFeaturesToggle(): void {
		this.ftSubscription = this.featuresToggleSettingsService.getFeaturesToggleSettings()
			.pipe(
				tap(() => {
				this.isMatNotificationsFfExists = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.EmailNotifications, false);
				this.isMidcNotificationsFfExists = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.DisplayNotificationsTab, false);
				this.updateNotificationsSettingsVisibility();
			})).subscribe();
	}
	trackSoftwareOptionsChanges(): void{
		this.globalSettingsService.contextChanged
			.pipe(
				takeUntil(this.componentAlive$),
				map(c => c.softwareOptions),
				filter(so => !!so),
				distinctUntilChanged(),
				tap(so => this.updateSoftwareOptions(so))
			).subscribe();
	}
	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
	}
}
