import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { EupToastService } from '@core/toastr/eupToast.service';

@Injectable({providedIn: 'root'})
export class HandleError implements ICommunicationEvent {
	constructor(private eupToastService: EupToastService) {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.HandleError;
	}

	async handleEvent(event: any) {
		this.eupToastService.error(event.data.errorDescription, event.data.errorHeader, true, null, true);
	}
}