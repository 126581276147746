import { Directive, HostListener } from '@angular/core';
import { EupObserver } from '@core/eupObserver.service';
import * as AT from '@shared/actionTypes';

@Directive({
	selector: '[activity-monitor]'
})
export class ActivityMonitorDirective {

	constructor(private eupObserver: EupObserver) {
	}

	@HostListener('document:click', ['$event'])
	userActivityRaised() {
		this.eupObserver.emit({
			action: AT.LAB_ACTION_RAISED,
			payload: null
		});
	}
}
