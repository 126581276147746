import {Injectable} from '@angular/core';
import {RxService} from '../../../rx/services/rx.service';
import {IRxUILabNote} from '../../../interfaces/IEUPIteroNote';
import {BehaviorSubject} from 'rxjs';
import {GlobalSettings, GlobalSettingsService} from '../../../core/globalSettings.service';
import { RxVersionService } from 'app/rx/services/rx-version.service';

@Injectable({
	providedIn: 'root'
})
export class OrderNotesService {
	public newNote$: BehaviorSubject<IRxUILabNote[]>;

	constructor(private rxService: RxService, private globalSettingService: GlobalSettingsService,
		 private rxVersionService: RxVersionService) {
		this.initLabNotesService();
	}

	private initLabNotesService(): void {
		this.newNote$ = this.rxService.newNoteObservable$;
	}

	public addLabRxNote(noteBody: string): any {
		const loggedUser = this.globalSettingService.get().username;
		this.rxVersionService.postNoteToRx({ noteBody, loggedUser });
	}
}
