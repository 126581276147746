import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';

@Injectable({providedIn: 'root'})
export class AppsConfigResult implements ICommunicationEvent {
	constructor() {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.ApplicationsConfigurationResult;
	}

	async handleEvent(event: any) { 
        // this event is published by the platform and not handled by him
    }
}