import { AuthInfo } from 'app/rx/models/rx-app-input-parameters';
import { filter, map, Observable } from 'rxjs';
import { ExocadBusinessContext, ExocaduiContext } from './exocadMiniAppOptions';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ExocadMiniAppModalService {
	constructor(private globalSettings: GlobalSettingsService, private shellContextService: ShellContextService) {}

	public context$: Observable<[AuthInfo, ExocaduiContext, ExocadBusinessContext]> = this.shellContextService.getContext().pipe(
		filter((context) => !!context.session.sessionId && !!context.security.accessToken),
		map((context) => {
			const companyInfo = this.globalSettings.getSelectedCompany();
			const username = this.globalSettings.get().username;
			const authInfo = {
				sessionId: context.session.sessionId,
				accessToken: context.security.accessToken,
				sessionType: context.session.sessionType,
			} as AuthInfo;
			const businessContext = {
				companyId: context.bizCtx.companyId,
				companyName: companyInfo?.name,
				countryId: companyInfo?.countryCode,
				contactId: context.bizCtx.contactId,
				region: context.bizCtx.region,
			} as ExocadBusinessContext;

			const uiContext = {
				language: context.UI.language,
				username: username,
			};
			return [authInfo, uiContext, businessContext];
		})
	);
}
