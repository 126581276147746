import { isNiriIndicationOn } from './../../../../assets/consts';
import { RxService } from 'app/rx/services/rx.service';
import { Injectable } from '@angular/core';
import md5 from 'md5';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TimberService } from '@logging/timber.service';
import { ModalIcon, ModalKeys, NotificationService } from '@shared/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { KnownComponentsNames } from '@core/components-names';
import { StickyHeaderService } from 'app/services/stickyHeaderService/stickyHeader.service';
import { takeWhile } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CaptureSupportService {
	private readonly captureDataKey = 'FULL_SCREEN';

	constructor(
		private rxService: RxService,
		private timberService: TimberService,
		private translateService: TranslateService,
		private notificationService: NotificationService,
		private stickyHeaderService: StickyHeaderService,
	) {}

	saveScreenShot(screenshots: object, orderId: string) {
		return this.rxService.uploadCaptureIOSimFile(orderId, this.removeMD5KeysAndSetBase64(screenshots)).pipe(
			catchError((err) => {
				this.timberService.error(err, {
					module: 'IosimPlusWrapperComponent',
					extendedParameters: {
						screenshotsData: JSON.stringify(screenshots),
					},
				});
				return of(null);
			})
		);
	}

	saveScreenShotNotIosim(screenshots: object, orderId: string) {
		return this.rxService
			.uploadScreenshotFile(orderId, this.removeMD5KeysAndSetBase64(screenshots))
			.pipe(
				tap(() => this.stickyHeaderService.downloadScreenshotsEnabled$.next(true))
			);
	}

	isScreenShotsDataValid(screenshots: object) {
		let validData = true;
		for (const key in screenshots) {
			if (key !== isNiriIndicationOn && !key.includes('MD5')) {
				const md5Value = md5(screenshots[key]);
				const md5Key = `${key}_MD5`;

				if (md5Value !== screenshots[md5Key]) {
					validData = false;
					break;
				}
			}
		}
		return validData;
	}

	markInvalidImagesForLogging(screenshots: object) {
		const badImagesToLog = {};
		Object.keys(screenshots)
			.filter((key) => key !== isNiriIndicationOn && key.includes('MD5'))
			.forEach((key) => {
				if (md5(screenshots[key]) !== screenshots[`${key}_MD5`]) {
					badImagesToLog[key] = screenshots[key];
				}
			});

		return badImagesToLog;
	}

	showError(data?: any, page: KnownComponentsNames = KnownComponentsNames.IosimPlus) {
		return of(data).pipe(
			tap((_) => {
				this.notificationService.show(
					this.translateService.instant('Errors.Error_download_file_title'),
					this.translateService.instant('Orders.Capture_Upload_Failed'),
					{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
				);
			}),
			tap((data) => {
				let extendedParameters = {};
				if (typeof data === 'string') {
					extendedParameters = { data };
				} else {
					const screenshotsData = JSON.stringify(this.markInvalidImagesForLogging(data));
					extendedParameters = { screenshotsData };
				}

				this.timberService.error(`Invalid images from ${page}`, {
					module: page.toString(),
					extendedParameters,
				});
			})
		);
	}

	getFullScreenKey(data) {
		return Object.keys(data).find((key) => key.includes(this.captureDataKey));
	}

	private removeMD5KeysAndSetBase64(captureData: object) {
		const screenshots = {};
		Object.entries(captureData).forEach(([key, value]) => {
			if (!key.includes('_MD5')) {
				const stringToReplace = /data:image\/(png|jpeg);base64,/gi;
				screenshots[key] = key !== isNiriIndicationOn ? value.replace(stringToReplace, '') : value;
			}
		});

		return screenshots;
	}
}
