import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';
import { BiLocationObject } from '@logging/interfaces/bi.enums';
import { TimberService } from '@logging/timber.service';
import { ButtonName } from '@shared/enums';

@Component({
  selector: 'eup-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrl: './notification-card.component.scss'
})
export class NotificationCardComponent {
  constructor(private router: Router,
    private biFeatureWrapperService: BiFeatureWrapperService,
    private timberService: TimberService,
  ) {
  }

  onButtonClick() {
    const { userAction } = this.biFeatureWrapperService.LogButtonClicked(BiLocationObject.Home, ButtonName.ViewOrders, null, BiLocationObject.Orders);
		this.timberService.userActionEvent(userAction);
    
    this.router.navigate(['/doctors/orders']);
  }

}
