import { IShellEvent } from './IShellEvent';
import { ShellCommunicationEvents } from '@shared/generalInterfaces';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PrepareToCloseEvent implements IShellEvent {

	constructor() { }

	get eventName(): string {
		return ShellCommunicationEvents.PrepareToClose;
	}

	async handleCustomEvent(event: any) {
        // this event is published by the app-shell and not handled by him
	}

	handlePostMessage(event): void {
        // this event is published by the app-shell and not handled by him
	}
}
