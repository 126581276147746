import { AfterContentInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { RESIZE_OBSERVER_CTOR } from '@core/injectionTokens';

@Component({
	selector: 'eup-check-overflow',
	template: '<ng-content></ng-content>',
	styles: [`:host {
			display: flex;
			flex-direction: column;
	}`]
})
export class CheckOverflowComponent implements AfterContentInit, OnDestroy {
	private changes: ResizeObserver;

	emitter = new EventEmitter();

	@Output()
	overflowHeight = this.emitter.pipe(
		debounceTime(300),
		map(() => this.checkOverflowHeight()),
	);


	constructor(
		private elementRef: ElementRef<HTMLElement>,
		@Inject(RESIZE_OBSERVER_CTOR) public ResizeObserverCtor: typeof ResizeObserver
	) {
	}

	ngAfterContentInit() {
		this.changes = new this.ResizeObserverCtor(entries => {
			entries.forEach(() => this.emitter.emit());
		});

		this.changes.observe(this.elementRef.nativeElement);
		this.changes.observe(this.elementRef.nativeElement.parentElement);
	}

	checkOverflowHeight() {
		const parentHeight = this.elementRef.nativeElement.parentElement.clientHeight;
		const childHeight = this.elementRef.nativeElement.offsetHeight;

		return parentHeight > 0 && childHeight > parentHeight;
	}

	ngOnDestroy() {
		this.changes.disconnect();
	}
}
