import { IOSimPlusIntegrationService } from 'app/services/iosim-plus-integration/iosim-plus-integration.service';
import { IOSimSimulationInfo } from '../../../../services/iosim-simulation.store/iosim-simulation-status-progress-state.model';
import { BaseDestroyable } from '@core/base-destroyable';
import { IOSimSimulationInfoQuery } from 'app/services/iosim-simulation.store/iosim-simulation-status-progress.query';
import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SimulationStatusEnum } from '../../models/simulationStatus';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'eup-status-info-modal',
	templateUrl: './status-info-modal.component.html',
	styleUrls: ['./status-info-modal.component.scss'],
})
export class StatusInfoModalComponent extends BaseDestroyable implements OnChanges {
	private readonly contentError = 'IOSimPlus.MessageFailStatus';
	private readonly contentLowQualityScan = 'IOSimPlus.MessageLowQualityScan';
	private readonly contentNotSupportDateScan = 'IOSimPlus.MessageNotSupportDateScan';

	@ViewChild('statusInfoModal') statusInfoModal: ModalDirective;
	@Input() orderId: string;
	@Input() orderCode: string;
	@Input() viewedScreen: string;
	@Input() isQualifiedForIoSimPlus: boolean;


	isRetryable: boolean;
	warningContent: string;

	constructor(private simulationStatusProgressQuery: IOSimSimulationInfoQuery,
				private integrationService: IOSimPlusIntegrationService) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes?.orderId && changes.orderId.previousValue !== changes.orderId.currentValue) {
			this.subscribeToStore();
		}
	}

	show(): void {
		this.statusInfoModal.show();
	}

	hide(): void {
		this.statusInfoModal.hide();
	}

	async retry() {
		this.statusInfoModal.hide();
		await this.integrationService.startSimulation(this.orderId, this.orderCode, this.viewedScreen);
	}

	private subscribeToStore() {
		this.simulationStatusProgressQuery.selectInfo(this.orderId).pipe(
			tap(state => this.setWarningContent(state)),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	private setWarningContent(simulationInfo: IOSimSimulationInfo) {
		if (simulationInfo?.notSupportedScanDate) {
			this.warningContent = this.contentNotSupportDateScan;
			this.isRetryable = false;
			return;
		}
		if (!this.isQualifiedForIoSimPlus) {
			this.warningContent = this.contentLowQualityScan;
			this.isRetryable = false;
			return;
		}
		switch (simulationInfo.progress.simulationStatus) {
			case SimulationStatusEnum.Fail:
				this.warningContent = this.contentError;
				this.isRetryable = true;
				break;
			case SimulationStatusEnum.LowQualityScan:
				this.warningContent = this.contentLowQualityScan;
				this.isRetryable = false;
				break;
		}
	}
}
