import { Component, Input, OnInit } from '@angular/core';
import { IEUPIteroNote } from '../../interfaces/IEUPIteroNote';

@Component({
	selector: 'eup-itero-note',
	templateUrl: './iteroNote.component.html',
	styleUrls: ['./iteroNote.component.scss'],
})
export class IteroNoteComponent implements OnInit {
	public noteTime: string;

	@Input()
	public note: IEUPIteroNote;

	private convertToZeroPrefix(strNum: number): string {
		return strNum < 10 ? `0${strNum}` : `${strNum}`;
	}

	private formatDate(dateToFormat: Date): string {
		const day = this.convertToZeroPrefix(dateToFormat.getDate());
		const month = this.convertToZeroPrefix(dateToFormat.getMonth() + 1);
		const hour = this.convertToZeroPrefix(dateToFormat.getHours());
		const minutes = this.convertToZeroPrefix(dateToFormat.getMinutes());

		const noteDate = `${day}.${month}.${dateToFormat.getFullYear()}`;
		const noteTime = `${hour}:${minutes}`;

		return `${noteDate}, ${noteTime}`;
	}

	constructor() {}

	ngOnInit() {
		// "as Date" is used for not getting a type error, even if the data is a Date object
		this.noteTime = typeof this.note.date === 'string' ? '' : this.formatDate(this.note.date as Date);
	}
}
