import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalSettingsService } from "@core/globalSettings.service";
import { TimberService } from "@logging/timber.service";
import { NavigationInfo } from "@shared/generalInterfaces";
import { Subscription, map, take, tap, of } from "rxjs";
import { timer } from "rxjs/internal/observable/timer";
import { Consts } from '@shared/consts';
import { OrdersService } from "app/doctors/orders/orders.service";
import { getCopy } from "app/extensions/map-extensions";

@Injectable({ providedIn: 'root' })
export class ApplicationNavigationService {
    private intervalForClosingApp = 10000;
    private closingAppSubscriptions = new Map<number, Subscription>();
    private topTransitionId: number = 1;

    constructor(private router: Router,
		private globalSettingsService: GlobalSettingsService,
        private timberService: TimberService,
        private ordersService: OrdersService) {
            let transitionsStack = sessionStorage.getItem(Consts.Storage.TransitionsStack);
            if (!transitionsStack)
                sessionStorage.setItem(Consts.Storage.TransitionsStack, JSON.stringify([] as NavigationInfo[]));
        }

    pushNavigationTransition(transition: NavigationInfo) {
        transition.transitionId = this.topTransitionId;
        this.topTransitionId++;
        let transitionsStack = JSON.parse(sessionStorage.getItem(Consts.Storage.TransitionsStack)) as NavigationInfo[];
        transitionsStack.push(transition);
        sessionStorage.setItem(Consts.Storage.TransitionsStack, JSON.stringify(transitionsStack));
    }

    resetTransitionStack() {
        sessionStorage.setItem(Consts.Storage.TransitionsStack, JSON.stringify([]));
    }

    popNavigationTransition() {
        let transitionsStack = JSON.parse(sessionStorage.getItem(Consts.Storage.TransitionsStack)) as NavigationInfo[];
        if (transitionsStack.length === 0)
            return null;

        this.topTransitionId--;
        let navigationInfo = transitionsStack.pop();
        sessionStorage.setItem(Consts.Storage.TransitionsStack, JSON.stringify(transitionsStack));
        return navigationInfo;
    }

    peekNavigationTransition() {
        let transitionsStack = JSON.parse(sessionStorage.getItem(Consts.Storage.TransitionsStack)) as NavigationInfo[];
        if (transitionsStack.length === 0)
            return null;

        return transitionsStack[transitionsStack.length - 1];
    }

    stackLength() {
        let transitionsStack = JSON.parse(sessionStorage.getItem(Consts.Storage.TransitionsStack)) as NavigationInfo[];
        return transitionsStack.length;
    }

    startClosingAppTimer(transitionId: number) {
        let subscription = timer(this.intervalForClosingApp).pipe(take(1),
            map(_ => {
                let transitionsStack = JSON.parse(sessionStorage.getItem(Consts.Storage.TransitionsStack)) as NavigationInfo[];
                return transitionsStack.find(x => x.transitionId === transitionId).urlFrom
            }),
            tap(_ => this.closingAppSubscriptions.delete(transitionId))
		).subscribe(stringUrl => {
            const url = new URL(stringUrl);
            this.router.navigateByUrl(url.pathname);
        });
        this.closingAppSubscriptions.set(transitionId, subscription);
    }

    stopClosingAppTimer(transitionId: number) {
        this.closingAppSubscriptions.get(transitionId)?.unsubscribe();
        this.closingAppSubscriptions.delete(transitionId);
    }

    navigateToHomePageWhenTransitionNotFound(backFromAppId: string) {
        this.timberService.error(`Navigation back from ${backFromAppId} failed.`, { module: 'ApplicationNavigationService' });
        this.navigateToHomePage();
    }

    navigateToHomePageWhenStackIsEmpty() {
        this.timberService.error(`Navigation stack is empty when user clicked back button.`, { module: 'ApplicationNavigationService' });
        this.navigateToHomePage();
    }

    navigate(transition: NavigationInfo) {
        let navigationCommands = Consts.NavigationCommands[getCopy](transition.navigatedToAppId);
        this.addCommands(transition.navigatedToAppId, navigationCommands, transition.appMetadata).pipe(
            tap(commands => {
                let queryParams = this.getQueryParams(transition);
                if (queryParams) {
                    this.router.navigate(commands, { queryParams: queryParams });
                }
                else
                    this.router.navigate(commands);
            }), take(1)).subscribe();
    }

    navigateBack(backFromAppId: string) {
        let transition = this.popNavigationTransition();
        if (transition) {
            this.stopClosingAppTimer(transition.transitionId);
			const url = new URL(transition.urlFrom);
            this.router.navigateByUrl(url.pathname+url.search);
        } else {
            this.navigateToHomePageWhenTransitionNotFound(backFromAppId);
        }
	}

    private navigateToHomePage(){
        const roleType = this.globalSettingsService.rolePath();
        this.router.navigate([`${roleType}/home`]);
    }

    private addCommands(appId: string, commands: any, stringAppMetadata: string) {
        switch (appId) {
			case 'iosim': {
                let appMetadata = JSON.parse(stringAppMetadata);
                return this.ordersService.getOrderCode(appMetadata.orderId)
                    .pipe(take(1),
                        map(orderCode => {
                            commands.push(orderCode);
                            commands.push(appMetadata.orderId);
                            return commands;
                        }));
            }
			default:
				return of(commands);
		}
    }

    private getQueryParams(transition: NavigationInfo) {
        switch (transition.navigatedToAppId) {
			case 'iosim': {
                return { returnUrl: transition.urlFrom };
            }
			default:
				return { };
		}
    }
}