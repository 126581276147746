<div>
    <div *ngIf="caption" class="row block-caption">
        <span>{{ caption | translate }}</span>
    </div>
    <div *ngFor="let item of items" class="row item">
        <div class="col-xs-4 item-name">
            <span>{{ item.key | translate }}:</span>
        </div>
        <div class="col-xs-8 item-value">
            <span>{{ item.value }}</span>
        </div>
    </div>
</div>
