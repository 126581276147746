export const contextScannerMock = {
  session: {
    sessionId: 'c35f743d-772b-4da7-adf5-4b33bb09664d',
    sessionType: 'Desktop',
  },
  user: {
    loggedInUserId: 887888,
    roleType: 0,
  },
  device: {
    scannerBaseUnitType: 'ElementPanelPC',
    systemType: 'Evx',
    scannerVersion: 'HBB3.1.15.17',
    scannerSerial: 'YUCJCVSW01A011',
    scannerResGuid: '048d3807-f76c-4a16-b861-12419a83d8a1',
    wandSerial: 'WSN244646702960',
  },
  bizCtx: {
    contactId: 887888,
    companyId: 129601,
    region: 'US',
    patientId: 'ba5799b6-7e23-4359-9d89-90b22aa3ddea',
    orderId: 103356588,
    rxId: '66a79696-d55d-412b-848f-fe4539737767',
  },
  UI: {
    dateFormat: 'DD/MM/YYYY',
    language: 'en-US',
  },
  security: {
    accessToken:
      'eyJ4NXQiOiJPREE1TldGa09EaGxNV1ptTURWbFlUVXpNakUxT0RZM1ltRmlOREk1WlRjeVpqUXpNbVpqWWciLCJraWQiOiJPREppTldNNU56VTFaamszWXpWaVpEazBOR05sTldVNFpERmxOREU1TldRMU1ESm1aREE0TmprellqRXhZalV4TURNM1ltVTJZekF3TWpjNFpqWTROZ19SUzI1NiIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJqY0Bkb2MucHByIiwiYXV0IjoiQVBQTElDQVRJT05fVVNFUiIsInJvbGUiOlsiR3Vlc3QiLCJPcnRob2RvbnRpc3QiLCJFeHRlcm5hbCBVc2VyIDEiLCJJbnRlcm5hbFwvZXZlcnlvbmUiXSwiaXNzIjoiaHR0cHM6XC9cL2lkZW50aXR5LWN1cy5wcHIuYWxpZ250ZWNoLmNvbSIsImV4dGVybmFsX2lkIjoiODg3ODg4IiwidXNlcl9kYXRhIjoiQ29udGFjdElEOlM6MDo2OiIsImF1ZCI6WyJMWkwyYXZtZmJ4NWNuSFBnRnZyYzV1cEJBTW9hIiwiaHR0cHM6XC9cL2lkZW50aXR5LWN1cy5wcHIuYWxpZ250ZWNoLmNvbVwvb2F1dGgyXC90b2tlbiIsIkFwcGxpY2F0aW9uTmFtZTppdGVyb193ZWJfYXV0aF9tc191c2Vyfml0ZXJvX3dlYl9hdXRoX21zIl0sIm5iZiI6MTcwNTA2NTI4NiwiYXpwIjoiTFpMMmF2bWZieDVjbkhQZ0Z2cmM1dXBCQU1vYSIsInNjb3BlIjoiZGVmYXVsdCBvcGVuaWQiLCJleHAiOjE3MDUwNjc2ODYsImlhdCI6MTcwNTA2NTI4NiwianRpIjoiYWMwYjU0MGEtYTEzYy00OGNhLWE0YjEtMzViODQzZGMwMjM2In0.BbslI77jTkyYA2NXL1YXzvbO8QmMe1_fbKFDO44xhestR0mBs3tsBC8c7ZgfjGtInIHQpgZf5w7Uobv24hg6H1Em_7VoMW4Zq8Ref5-g1RgNlSgGlDl21GLE-zND6cQ3NqaUypjjbitT_Cv2vVVSlBDMSlXnG7yafDlL042q4qKoBdQBbjBomkxhBNQfmOnD2-7R443fGgFUd4QHSRZJ3wrTpMFTu4BB1VnjoMyVFEwalsDVusc8pH58CjUXGkroAi6D8iKLTfK0jrAweP3e8RCWEZBoGP4bgwE2WozpXiBCifedWkYGDRzrH590qSo6nl5tpmG5TUudvSCjTX0d1g',
  },
  platformServices: [
    {
      name: 'SessionManagementRefresh',
      url: 'https://sessionmng-us-west-1-ppr.iterocloud.com/api/v1/auth/session/refresh',
    },
  ],
};
