import { Component } from '@angular/core';
import { ScreenshotToastService, ScreenshotMessage } from './screenshotToast.service';

@Component({
	selector: 'screenshot-toast',
	templateUrl: './screenshotToast.component.html',
	styleUrls: [ './screenshotToast.component.scss' ]
})
export class ScreenshotToastComponent {

	messages: ScreenshotMessage[];
	constructor(screenshotToastService: ScreenshotToastService) {
		this.messages = screenshotToastService.messages;
	}

	removeMessage(message) {
		const index = this.messages.indexOf(message);
		if (index > -1) {
			this.messages.splice(index, 1);
		}
	}
}
