import { Injectable } from '@angular/core';
import { EupRoutesService } from '../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { HttpHeaders } from '@angular/common/http';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';

@Injectable()
export class MessagesService {


	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService
		) {}

	getAllMessages(scannerVersion: string, scannerId: string, token: string = '', companyId: number = 0): Observable<Message[]> {
		const getMessagesListUrl = this.eupRoutesService.messages.getMessagesListUrl(companyId, scannerVersion, scannerId);
		return this.http.get(getMessagesListUrl, { headers: this.getMessageHeaders(true, token) })
		.pipe(
			map((res: Message[]) => {
				const arr = res;
				arr.forEach((msg) => {
					msg.publishDate = new Date(msg.publishDate);
					msg.dateCreated = new Date(msg.dateCreated);
					msg.expirationDate = new Date(msg.expirationDate);
				});
				return arr;
			})
		);
	}

	getMessagesCount(companyId: number, scannerVersion: string = '', scannerId: string = '', token: string = ''): Observable<number> {
		const newMessagesCountUrl = this.eupRoutesService.messages.newMessagesCountUrl(companyId, scannerVersion, scannerId);
		return this.http.get(newMessagesCountUrl, { headers: this.getMessageHeaders(true, token) }, true, false);
	}

	setMessageViewState(messageId: number, isViewed: boolean, token: string = ''): Observable<any> {
		const url = isViewed
			? this.eupRoutesService.messages.markAsReadUrl
			: this.eupRoutesService.messages.markAsUnreadUrl;
		return this.http.post(url, { messageId }, { headers: this.getMessageHeaders(true, token) }, true, false);
	}

	getMessageHeaders(isJSON: boolean, token: string = ''): HttpHeaders {
		let headers = new HttpHeaders();
		if (isJSON) {
			headers = headers.append('Content-Type', 'application/json');
		}
		if (token) {
			headers = headers.append('Token', token.toUpperCase());
		}
		return headers;
	}
}

export class Message {
	id: number;
	title: string;
	content: string;
	plainText: string;
	shortContent: string;
	messageType: number;
	publishDate: Date;
	dateCreated: Date;
	expirationDate: Date;
	isViewed: boolean;
}
