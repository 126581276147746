export enum RoleTypeEnum {
	Doctor = 0,
	Lab = 1,
}

export interface RxNote {
	id?: number;
	dateCreated?: string;
	content: string;
	role?: RoleTypeEnum;
	createdByName?: string;
	createdById?: number;
}

export interface RxUILabNote {
	name: string;
	canEdit: boolean;
	content: string;
	dateCreated: string;
	_id?: number;
}

export const UserRoleMapper: { [id: number]: string } = {
	[RoleTypeEnum.Doctor]: 'D',
	[RoleTypeEnum.Lab]: 'L',
};
