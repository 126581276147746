<div bsModal #orthoCadExportModal="bs-modal" class="eup-modal modal fade top" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.ExportType' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a dropdownToggle>{{selectedExportTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let option of exportTypeOptions" (click)="setField('ExportType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.DataFormat' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a dropdownToggle>{{selectedDataFormatOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let option of dataFormatOptions" (click)="setField('DataFormat', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Rx.FileType' | translate }}</label>
                        <div class="col-sm-6">
                            <div dropdown class="dropdown-container">
                                <a dropdownToggle>{{selectedFileTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let option of fileTypeOptions" (click)="setField('FileType', option.key, option.value)">{{option.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!disablePatientDetailsBySWOpiont" class="hide-patient-name-container">
                        <eup-generic-checkbox
                            [checked]="hidePatientName"
                            [disabled]="hidePatientName === undefined"
                            (checkBoxStateEmitter)="onHidePatientNameChanged($event)"
                        ></eup-generic-checkbox>
                        <span class="hide-patient-name-label">{{'Settings.HidePatientName' | translate }}</span>
                    </div>

                    <div class="text-right">
                        <button id="btn-cancel" type="button" class="btn btn-default orthoCadExport-btn" (click)="orthoCadExportModal.hide()">
                            {{'Rx.Cancel' | translate }}
                        </button>

                        <button id="btn-export" type="button" class="btn btn-primary orthoCadExport-btn" (click)="export()" [disabled]="!canExport()">
                            {{'Rx.Export' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>