import { produce } from 'immer';
import { Store, StoreConfig } from '@datorama/akita';
import { IShellState } from './IShellState';
import { Injectable } from '@angular/core';
import { Consts } from '@shared/consts';

export function createInitialState(): IShellState {
	return {
		context: {
			session: {
				sessionId: null,
				sessionType: Consts.SessionType
			},
			user: {
				loggedInUserId: null,
				roleType: null
			},
			bizCtx: {
				companyId: null,
				contactId: null,
				csmMessageId: null,
				orderId: null,
				patientId: null,
				region: null,
				rxId: null
			},
			UI:{
				dateFormat: null,
				language: null
			},
			security: {
				accessToken: null,
			},
			platformServices: []
		},
		order: null
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: Consts.ShellStoreName, producerFn: produce })
export class ShellStore extends Store<IShellState> {
	constructor() {
		super(createInitialState());
	}
}
