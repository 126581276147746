import {IShellEvent} from './IShellEvent';
import {Injectable} from '@angular/core';
import {EupToastService} from '@core/toastr/eupToast.service';
import {ShellCommunicationEvents} from '@shared/generalInterfaces';

@Injectable({providedIn: 'root'})
export class ShellErrorEvent implements IShellEvent {
	constructor(private eupToastService: EupToastService) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.HandleError;
	}

	handleCustomEvent(event: CustomEvent): void {
		this.handle(event.detail);
	}

	handlePostMessage(event): void {
		this.handle(event.payload);
	}

	private handle(errorDetail) {
		this.eupToastService.error(errorDetail?.errorDescription ?? null, errorDetail?.errorHeader ?? null, true, null, true);
	}
}
