import { OnInit, Input, Directive } from '@angular/core';
import { GlobalSettingsService, GlobalSettings } from '../../core/globalSettings.service';
import { Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BaseDestroyable } from '@core/base-destroyable';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggle, SoftwareOptionsForCompany } from '@shared/enums';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { AccessibilityService } from '@shared/accessibility.service';
import { TimberService } from '@logging/timber.service';

@Directive({
	selector: 'ada-chat-bot'
})
export class AdaChatBotDirective extends BaseDestroyable implements OnInit {

	window: any;
	@Input() routeHasLogin = true;
	isChatBotFFEnabled = false;

	constructor(
		public globalSettingsService: GlobalSettingsService,
		public renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		protected appConfigService: AppConfigService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService,
		public softwareOptionsService: SoftwareOptionsService,
		private accessibilityService: AccessibilityService,
		private timberService: TimberService
	) {
		super();
		this.window = <any>this.document.defaultView;
	}

	get username(): string {
		const selectedCompany = this.settings.companies.filter(company => company.id === this.settings.selectedCompanyId)[0];
		const selectedContact = selectedCompany?.contacts ? selectedCompany.contacts.filter(contact => contact.contactId === this.settings.selectedDoctorId)[0].contactName : this.settings.username;
		return selectedContact;
	}

	get doctoId(): number {
		return this.settings.selectedDoctorId;
	}

	get companyId(): number {
		return this.settings.selectedCompanyId;
	}

	private get settings(): GlobalSettings {
		return this.globalSettingsService.get();
	}

	ngOnInit(): void {
		let contextChanged = false;
		this.initializeOrUpdateAda(contextChanged);

		this.globalSettingsService.contextChanged
			.pipe(
				takeUntil(this.componentAlive$),
				debounceTime(500),
				map(context => {
					return {companyId: context.companyId, doctorId: context.doctorId, companySwo: context.softwareOptions};
				}),
				filter(contextParams => !!contextParams),
				distinctUntilChanged(),
				tap(async (contextParams) => {
					const isAdaFFOrSWOEnabled = this.isAdaChatbotFFOrSWOEnabled(contextParams.companySwo);
					if (!isAdaFFOrSWOEnabled) {
						let adaChatBotVisible = document.querySelector("#ada-entry");
						if (!!adaChatBotVisible) {
							await this.window.adaEmbed.stop();
							this.routeHasLogin = true;
						}
						return false;
					}
					else {
						this.routeHasLogin = false;
						contextChanged = true;
						await this.initializeOrUpdateAda(contextChanged);
					}
					this.routeHasLogin = true;
				})
			)
			.subscribe();
	}
	
	async initializeOrUpdateAda(contextChanged): Promise<void> {
		const adaScriptUrl = !!this.appConfigService.appSettings.adaChatBot ? this.appConfigService.appSettings.adaChatBot.adaScriptUrl : null;
		if (!adaScriptUrl) {
			return;
		}
        let isAdaScriptLoaded = await this.isAdaScriptLoaded(adaScriptUrl);

		if (isAdaScriptLoaded) {
			await this.startAdaAndApplyVisibility(contextChanged);
		}

		else 
		{
			if (this.accessibilityService.getPathName() !== '/doctors/home') {
				return;
			}

			this.routeHasLogin = false;
			let scriptElement = this.renderer.createElement('script');
			scriptElement.type = 'text/javascript';
			scriptElement.src = adaScriptUrl;
			scriptElement.cluster = "eu";
			scriptElement.id = "__ada";
			scriptElement.setAttribute('data-handle',this.appConfigService.appSettings.adaChatBot.adaDataHandle);
			this.renderer.appendChild(this.document.head, scriptElement)

			scriptElement.onload = async () => {
				this.initializeAdaSettings();
				this.timberService.info('Ada Loaded Succesfully', { module: 'AdaChatBotDirective' });
			}

			scriptElement.onerror = () => {
				this.timberService.error('error while loading ada chatbot', { module: 'AdaChatBotDirective' });
			}
		}
	}

	private isAdaChatbotFFOrSWOEnabled(companySWO?): boolean {
		let softwareOptions;
		this.isChatBotFFEnabled = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.ChatBot, false);
		
		if (companySWO) {
			softwareOptions = companySWO
		}
		else {
			softwareOptions	= this.settings.companySoftwareOptions;
		}
		const chatBotSoftwareOptions = [SoftwareOptionsForCompany.ChatBot];
		const chatbotSwoEnabled = this.softwareOptionsService.areSoftwareOptionExist(chatBotSoftwareOptions, softwareOptions);
		return this.isChatBotFFEnabled || chatbotSwoEnabled;
	}

	private async isAdaScriptLoaded(adaScriptUrl): Promise<boolean> {
		let scripts = await document.getElementsByTagName( 'script' );
		for (let i=0; i < scripts.length; i++) {
			let scriptElement = scripts[i];
			let adaUrlOrigin;
	
			const scriptSourcePath = await scriptElement.getAttribute('src');
			try {
				adaUrlOrigin = new URL(adaScriptUrl).origin;
			}
			catch(error) {
				this.timberService.error('invalid or missing ada chatbot url', { module: 'AdaChatBotDirective', error: error });
			}
			const scriptHost = adaUrlOrigin;
			if (scriptSourcePath) {
				if (scriptSourcePath.includes(scriptHost)) {
					return true;
				}
			}
		}
		return false;
	}

	private async startAdaAndApplyVisibility(contextChanged): Promise<void> {
		if (this.routeHasLogin && !contextChanged) {
			this.routeHasLogin = false;
			this.startAda();
		}
		else {
			let adaChatBotVisible = document.querySelector("#ada-entry");
			if (!!adaChatBotVisible) {
				await this.window.adaEmbed.reset();
				await this.window.adaEmbed.stop();
			}
			await this.startAda();
		}
		this.showHideAdaChatBot();
	}
	
	private async initializeAdaSettings(): Promise<void> {
		await this.window.adaEmbed.setMetaFields({
			username: this.username,
			companyId: this.companyId,
			matId: this.doctoId
		});
	}

	private async startAda(): Promise<void> {
		await this.window.adaEmbed.start({
			handle: "align-itero",
			language: "en"
		 });
		 
		 this.initializeAdaSettings();
	}

	private showHideAdaChatBot(): void {
		const isAdaChatbotIconVisible = document.querySelector("#ada-entry");
		if (!isAdaChatbotIconVisible) {
			return;
		}
		if (this.accessibilityService.getPathName() === '/doctors/home') {
			isAdaChatbotIconVisible.setAttribute("style", "display:contents");
		}
		else {
			isAdaChatbotIconVisible.setAttribute("style", "display:none");
		}
	}
}