import { Injectable, OnDestroy } from '@angular/core';
import { AppConfigService } from '../appConfig/appConfigService';
import { LogParameters } from './interfaces/log.parameters';
import { BaseTimber } from '@core/base-classes/base-timber';
import { LocalStorageService } from '@core/localStorage.service';
import { SessionInfo } from 'app/services/authentication/auth.service';
import { Consts } from '@shared/consts';
import { BiEvent, IApiReportEvent, IErrorReportEvent, ISessionStartEvent, ISessionTagsEvent, ITags, IUiNavigationEvent, IUserActionEvent } from './interfaces/bi.events';
import { BiEventType } from './interfaces/bi.enums';
import { RoleTypeEnum } from '@shared/enums';

@Injectable({ providedIn: 'root' })
export class TimberService extends BaseTimber implements OnDestroy {
	constructor(protected appConfigService: AppConfigService,
							protected localStorageService: LocalStorageService
							) {
		super(appConfigService, localStorageService);
	}

	initialize(loggedInContactId: number, selectedCompanyId: number) {
		super.createLogger(loggedInContactId, selectedCompanyId);
		super.createBi(loggedInContactId, selectedCompanyId);
	}

	///write to log
	trace(message: string, parameters: LogParameters): void { this.loggerTimber.trace(message, this.getLogParameters(parameters)); }

	debug(message: string, parameters: LogParameters): void { this.loggerTimber.debug(message, this.getLogParameters(parameters)); }

	info(message: string, parameters: LogParameters): void { this.loggerTimber.info(message, this.getLogParameters(parameters)); }

	warn(message: string, parameters: LogParameters): void { this.loggerTimber.warn(message, this.getLogParameters(parameters)); }

	error(message: string, parameters: LogParameters): void { this.loggerTimber.error(message, this.getLogParameters(parameters)); }

	fatal(message: string, parameters: LogParameters): void { this.loggerTimber.fatal(message, this.getLogParameters(parameters)); }
	
	getLogParameters = (parameters: LogParameters): LogParameters => {
		const sessionId = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo).sessionId || 'EMPTY';
		const sessionType = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo).sessionType || 'EMPTY';
		return {
			...parameters, extendedParameters: {
				sessionId, sessionType
			}
		}
	}

	logUserActionToTimber({ params , module, traceTitle, contactId, companyId } : { params: object , module: string, traceTitle: string, contactId: number, companyId: number  }): void {
		const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);
		this.info(traceTitle , {
			module,
			extendedParameters: {
			    ...params,
				userId: `${contactId}`,
				companyId: `${companyId}`,
				sessionId: sessionInfo?.sessionId,
				sessionType: Consts.SessionType,
			},
		});
	}

   //Bi methods
	sessionStartEvent(user: number, companyId: number, roleType: RoleTypeEnum): void {
		this.sendBiEvent({
			event: {
				externalSessionId: '',
				tags: {
					userId: user.toString(),
					companyId: companyId.toString(),
					midcMode: this.getMidcMode(roleType),
				} as ITags
			} as ISessionStartEvent,
			type: BiEventType.SessionStart
		});
	}
	
	userActionEvent(event: IUserActionEvent) : void {
		this.sendBiEvent({
			event,
			type: BiEventType.UserAction
		} as BiEvent);
	}
	
	uiNavigationEvent(event: IUiNavigationEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.UiNavigation
		} as BiEvent);
	}
	
	errorReportEvent(event: IErrorReportEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.ErrorReport
		} as BiEvent);
	}
	
	apiReportEvent(event: IApiReportEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.APIReport
		} as BiEvent);
	}
	
	sessionTagsEvent(event: ISessionTagsEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.SessionTags
		} as BiEvent);
	}
	
	LogUserActionAndNavigate(userActionEvent: IUserActionEvent, uiNavigationEvent: IUiNavigationEvent): void {
		this.userActionEvent(userActionEvent);
		this.uiNavigationEvent(uiNavigationEvent);
	}
	
	private getMidcMode(roleType: RoleTypeEnum): string {
		switch (roleType) {
			case RoleTypeEnum.Doctor:
				return 'doctor';
			case RoleTypeEnum.Lab:
				return 'lab';
			default:
				return null;
		}
	}
}