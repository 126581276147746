import { Injectable } from '@angular/core';
import { BiAction, BiLocationObject, BiObjectType } from './interfaces/bi.enums';
import { IUiNavigationEvent, IUserActionEvent } from './interfaces/bi.events';
import { Iframes } from 'app/rx/enums/Iframes';

@Injectable({
  providedIn: 'root'
})
export class BiFeatureWrapperService {

  constructor() { }

  LogExocadButtonClickedAndNavigate(parent: BiLocationObject): { userAction: IUserActionEvent, uiNavigation: IUiNavigationEvent} {
    return {
      userAction: {
        locationParentObject: parent,
        objectName: 'ExocadViewerButton',
        action: BiAction.Clicked,
        objectType: BiObjectType.Button
      },
      uiNavigation: {
        sourceName: parent,
        targetName: this.convertNames(Iframes.Exocad3dViewer)
      }
    };
  }

  LogMyDesignAppButtonClicked(parent: BiLocationObject, orderId?: number): { userAction: IUserActionEvent} {
    return {
        userAction: {
        locationParentObject: parent,
        objectName: 'MyDesignButton',
        action: BiAction.Clicked,
        objectType: BiObjectType.Button,
        selectedValue: `orderId: ${orderId.toString()}`
      }
    };
  }

  LogDownloadInstallMyDesignAppClicked(parent: BiLocationObject, orderId?: number): { userAction: IUserActionEvent} {
    return {
        userAction: {
        locationParentObject: parent,
        objectName: 'DownloadInstallMyDesignApp',
        action: BiAction.Clicked,
        objectType: BiObjectType.Link,
        selectedValue: `orderId: ${orderId.toString()}`
      }
    };
  }

  LogButtonClicked(parent: BiLocationObject, buttonName: string, orderId?: number, targetName?: string, automationId:string = ''): { userAction: IUserActionEvent, uiNavigation: IUiNavigationEvent } {
    return {
        userAction: {
        locationParentObject: parent,
        objectName: buttonName,
        action: BiAction.Clicked,
        objectType: BiObjectType.Button,
        selectedValue: orderId ? `orderId: ${orderId.toString()}` : null,
        automationId
      },
      uiNavigation: {
        sourceName: parent,
        targetName: targetName,
      }
    };
  }
	convertNames(page: Iframes | string): string {
		switch (page) {
			case Iframes.Rx:
				return 'RxViewer';
			case Iframes.Viewer:
				return 'WebViewer';
			case Iframes.Exocad3dViewer:
				return 'ExocadViewer';
			default:
				return page;
		}
	}

	getPage(parentPage: string): string {
		switch (parentPage) {
			case 'patients':
				return 'patientsOrders';
			default:
				return parentPage;
		}
	}
}
