import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService } from '../../shared/settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { Language, LocaleData, UserLanguage } from '../../shared/generalInterfaces';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';

@Component({
	selector: 'eup-localizationSettings',
	templateUrl: './localizationSettings.component.html',
	styleUrls: [ './localizationSettings.component.scss' ]
})
export class LocalizationSettingsModal implements OnInit {
	@ViewChild('localizationSettingsModal') localizationSettingsModal: ModalDirective;
	private dateFormats: string[];
	selectedFormat: string;
	languages: Language[];
	public selectedLanguage: UserLanguage;

	constructor(
		private globalSettingsService: GlobalSettingsService,
		private settingsService: SettingsService,
		private shellContextService: ShellContextService
	) {}

	show(): void {
		this.localizationSettingsModal.show();
	}

	ngOnInit(): void {}

	Initialize(): void {

		this.settingsService.getLocalizationSettingsByRoleType().subscribe((res: LocaleData) => {
			this.languages = res.languages;			
			this.dateFormats = res.dateFormats;

			const settings = this.globalSettingsService.get();
			if (!settings.selectedLanguage) {
				this.setSelectedLanguage(this.languages[0]);
			} else {
				this.selectedLanguage = settings.selectedLanguage;
			}

			if (!settings.dateFormat) {
				this.selectedFormat = this.dateFormats[0];
			} else {
				this.selectedFormat = settings.dateFormat;
			}
		});
	}

	setLanguage(code: string) {
		let selectedLanguage = this.languages.filter((l) => l.code === code)[0];
		this.setSelectedLanguage(selectedLanguage);

		// update user settings language
		this.settingsService.setSettings('SelectedLanguage', JSON.stringify(this.selectedLanguage)).subscribe(() => {
			this.globalSettingsService.setLanguage(this.selectedLanguage);
			this.shellContextService.updateContext(context => {
				context.UI.language = this.selectedLanguage.code;
			});
		});
	}

	setSelectedLanguage(language: Language) {
		this.selectedLanguage = {
			code: language.code,
			description: language.localizedName,
			englishName: language.name
		};
	}

	setDateFormat(format: string) {
		this.selectedFormat = format;
		this.settingsService.setSettings('DateFormat', JSON.stringify(format)).subscribe(() => {
			this.globalSettingsService.setDateFormat(format);
			this.shellContextService.updateContext(context => {
				context.UI.dateFormat = this.selectedFormat;
			});
		});
	}
}
