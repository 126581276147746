import {Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'item-adder',
	templateUrl: './itemAdder.component.html',
	styleUrls: ['./itemAdder.component.scss']
})
export class ItemAdderComponent implements OnInit {
	private valueDebouncer = new Subject<number>();
	private pFieldNumber = 0;
	private pDisabled: boolean;

	public minValueAllowed = 0;

	@Input()
	public set disabled(disabledStatus: boolean) {
		this.pDisabled = disabledStatus;
	}

	public get disabled(): boolean {
		return this.pDisabled;
	}

	@Input()
	public set fieldNumber(num: number) {
		if (this.pFieldNumber === num) { return; } // This is so that the value isn't fired if it's the same

		this.pFieldNumber = (num) ? num : 0;
		this.valueDebouncer.next(this.pFieldNumber);
	}

	public get fieldNumber(): number {
		return this.pFieldNumber;
	}

	@Input()
	public debouncePeriod: number = 0;

	@Output()
	public emittedNumber: EventEmitter<number> = new EventEmitter();

	public checkMinValue(num: number): void {
		this.fieldNumber = (num < this.minValueAllowed) ? this.minValueAllowed : num;
	}

	public decreaseNumber(): void {
		this.checkMinValue(this.fieldNumber - 1);
	}

	public increaseNumber(): void {
		this.fieldNumber++;
	}

	ngOnInit(): void {
		this.valueDebouncer
			.pipe( debounceTime(this.debouncePeriod) )
			.subscribe( (num: number) => this.emittedNumber.emit(num) );
	}
}
