import { IOSimSimulationInfo } from './iosim-simulation-status-progress-state.model';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { IOSimSimulationInfoState } from './iosim-simulation-status-progress-state.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'iosim-simulation-info', resettable: true })
export class IOSimSimulationInfoStore extends EntityStore<IOSimSimulationInfoState, IOSimSimulationInfo> {
	constructor() {
		super();
	}
}
