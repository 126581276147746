import { Subject} from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { TimberService } from '../../logging/timber.service';

enum ConnectionState {
	Disconnected,
	Connecting,
	Connected,
	Disconnecting,
}

export class BaseSignalR<T> {
	private readonly connectionRestartingDelay = 5000;
	private messageBroker: Subject<T> = new Subject<T>();
	private hubConnection: signalR.HubConnection;
	private connectionState: ConnectionState = ConnectionState.Disconnected;
	protected stopNotifications$: Subject<void> = new Subject<void>();
	protected messageBroker$ = this.messageBroker.asObservable();

	protected constructor(protected timberService: TimberService) {}

	async stopNotifications(): Promise<any> {
		this.stopNotifications$.next();
		await this.closeConnection();
	}
	
	protected async initMessages(hubUrl: string, channelName: string, accessToken: string): Promise<any> {
		if (this.connectionState !== ConnectionState.Disconnected) {
			return;
		}

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(hubUrl, {
				accessTokenFactory: () => accessToken,
			})
			.build();

		this.hubConnection.on(channelName, (message: T) => {
			this.messageBroker.next(message);
		});

		this.hubConnection.onclose(async () => {
			const previousState = this.connectionState;

			this.timberService.trace(`Connection closed.`, { module: 'signalR.service' });
			this.connectionState = ConnectionState.Disconnected;

			if (previousState !== ConnectionState.Disconnecting) {
				await this.startConnection();
			}
		});

		await this.startConnection();
	}

	private async closeConnection(): Promise<any> {
		if (!this.hubConnection) {
			return;	
		}
		const connectionId = this.hubConnection.connectionId;
		const isHubConnected = this.hubConnection.state === signalR.HubConnectionState.Connected;

		if (isHubConnected) {
			this.connectionState = ConnectionState.Disconnecting;
			await this.hubConnection.stop();
			this.timberService.debug(`Connection ${connectionId} is stopping`, { module: 'signalR.service' })
		} else {
			this.connectionState = ConnectionState.Disconnected;
			this.timberService.trace(`Connection ${connectionId} has already stopped`, { module: 'signalR.service' });
		}
	}

	private async startConnection(): Promise<any> {
		this.connectionState = ConnectionState.Connecting;
		await this.startHub();
	}

	private async startHub(): Promise<any> {
		if (this.connectionState !== ConnectionState.Connecting) {
			return;
		}

		try {
			await this.hubConnection.start();
		} catch (_) {
			this.timberService.warn(`Unexpected connection fail. Trying to reconnect in ${this.connectionRestartingDelay} ms...`, {
				module: 'signalR.service',
			});
			this.startHubDeferred(this.connectionRestartingDelay);

			return;
		}

		this.connectionState = ConnectionState.Connected;
		this.timberService.debug(`Connection ${this.hubConnection.connectionId} established.`, { module: 'signalR.service' });
	}

	private startHubDeferred(delayInMilliseconds: number) {
		setTimeout(async () => await this.startHub(), delayInMilliseconds);
	}
}
