import { Injectable } from '@angular/core';
import { AccountPairingService } from './account-pairing.service';
import { AccountPairingStatus, MessengerType } from '@shared/enums';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { TimberService } from '@logging/timber.service';

@Injectable()
export class WeChatPairingService implements AccountPairingService {
	constructor(private http: EupHttpHandler,
				private eupRoutesService: EupRoutesService,
				private timberService: TimberService) {
	}

	messengerType: MessengerType = MessengerType.WeChat;

	getPairingStatus(doctorId: number, companyId: number): Observable<AccountPairingStatus> {
		const url = this.eupRoutesService.accountPairing.getPairingStatus(this.messengerType, doctorId, companyId);
		return this.http.get(url, undefined, false, false).pipe(
			catchError(error => {
				this.timberService.error('error while retrieving pairing status', { module: 'WeChatPairingService', error: error });
				return of(AccountPairingStatus.Unknown);
			})
		);
	}

	unpairAccount(doctorId: number, companyId: number): Observable<boolean> {
		const url = this.eupRoutesService.accountPairing.unpairAccount(this.messengerType, companyId);
		return this.http.post(url, {
			messengerType: this.messengerType,
			doctorId: doctorId,
			companyId: companyId
		}, {}, false, false).pipe(
			map(_ => true),
			catchError(error => {
				this.timberService.error('error while unpairing account', { module: 'WeChatPairingService', error: error });
				return of(false);
			})
		);
	}
}
