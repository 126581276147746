<div class="event-bus-tester">
    <div class="debug-row">
      <div class="debug-column">
        <label>{{
          selectedMethod.value === "emit" ? "Publisher App ID:" : "Callback App ID:"
        }}</label>
        <div class="debug-input">
          <input
            id="publisher-app-id"
            matInput
            [formControl]="publisherAppId"
          />
        </div>    
       
        @if (selectedMethod.value === "emit"){
        <label>Target Applications:</label>
        <div class="debug-input">
          <input id="target-applications" [formControl]="targetApplications"/>
        </div>
     
        
        <label>Events:</label>
        <div class="debug-input">          
          <select [formControl]="selectedEvent" (change)="handleEventChange()">
            @for (group of eventGroups; track group) {
            <optgroup [label]="group.name">
              @for (e of group.events; track e) {
              <option [value]="e.eventName">{{ e.displayName }}</option>
              }
            </optgroup>
            }
          </select>
        </div>
      
        <label for="eventPayload">Event Payload:</label>
        <div class="event-payload-container">         
          <textarea
            id="event-payload"
            matInput
            [formControl]="eventPayload"
          ></textarea>
        </div>      
      }

        <label>Channels:</label>
        <div class="debug-input">  
          <select [formControl]="selectedChannel" (change)="handleEventChange()">
            @for (c of channels; track c) {
            <option [value]="c.channelName">{{ c.displayName }}</option>
            }
          </select>
        </div>
        <label>Methods:</label>
        <div class="debug-input">  
          <select [formControl]="selectedMethod" (change)="handleEventChange()" >
            @for (m of methods; track m) {
            <option [value]="m.methodName">{{ m.displayName }}</option>
            }
          </select>
        </div>
      </div>
      <div class="debug-column">
        <label for="eventToBeRun">Event to be run:</label>
        <div class="event-to-be-run-container">        
          <textarea
            id="event-to-be-run"
            [readonly]="true"
            matInput
            [formControl]="eventToBeRun"
          ></textarea>
        </div>
       <br/>
       <br/>
        <button
          id="check-before-event"
          mat-raised-button
          color="secondary"
          (click)="handleEventChange()"
        >
          {{ selectedMethod.value === "emit" ? "Force Values Before Test Event" : "Force Values Before Test Subscription" }}
        </button>
        <br/>
        <button
          id="test-event"
          mat-raised-button
          color="accent"
          (click)="testEvent()"
        >
          Test {{ selectedMethod.value === "emit" ? "Event" : "Subscription" }}
        </button>
       
        <hr/>
        <div class="test-event-result-container">
          <label for="eventToBeRun">Test Event Result:</label>
          <textarea
            id="test-event-result"
            [readonly]="true"
            matInput
            [formControl]="testEventResult"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  