<div bsModal #scannerSettingsModal="bs-modal" class="eup-modal modal fade" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button id="btn-hide-settings" type="button" class="close close-sign" (click)="hide()">
                        <svg class="svg-backButton32 svg-backButton32-dims"></svg>
                    </button>
                    {{'Settings.Scanners_Title' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <dl *ngFor="let rowData of scannerSettings">
                    <dt>{{ rowData.description }}</dt>
                    <dd><span>{{'Settings.Scanners_SoftwareVersion' | translate }}:</span> {{ rowData.softwareVersion }}</dd>
                    <dd><span>{{'Settings.Scanners_BaseSerail' | translate }}:</span> {{ rowData.baseSerialNumber }}</dd>
                    <dd><span>{{'Settings.Scanners_WandSerail' | translate }}:</span> {{ rowData.wandSerial }}</dd>
                    <dd><span>{{'Settings.Scanners_CompanyId' | translate }}: </span>{{ rowData.companyId }}</dd>
                    <dd><span>{{'Settings.Scanners_IOSIMVersion' | translate }}: </span>{{ rowData.iOsimVersion }} </dd>
                </dl>
            </div>
        </div>
    </div>
</div>