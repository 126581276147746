import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { LocalStorageService } from '@core/localStorage.service';
import { IOrderInformation } from '@interfaces/IOrderInformation';
import { Consts } from '@shared/consts';
import { RxiFrameEvents } from '@shared/generalInterfaces';
import { SettingsService } from '@shared/settings.service';
import { Observable, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { RxAppService } from './rx-app.service';
import { RxService } from './rx.service';
import { ISettingsData } from 'app/interfaces/ISettingsData';
import { RxEnabledData } from '@interfaces/IRxEnabledData';

@Injectable({ providedIn: 'root' })
export class RxVersionService {
	private storage: Storage;
	constructor(
		private rxAppService: RxAppService,
		private rxService: RxService,
		private globalSettingsService: GlobalSettingsService,
		private settingsService: SettingsService,
		private localStorageService: LocalStorageService,
	) {
	 	this.storage = this.localStorageService.storage;
	}

	isRxAppEnabled(): Observable<boolean> {
		const doctorId = this.globalSettingsService.get().selectedDoctorId;
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		return this.settingsService.isRxAppEnable(doctorId, companyId);
	}

	isRxAppEnabledForLab(): Observable<boolean> {
		const contactId = this.globalSettingsService.get().contactId;
		const companyId = this.globalSettingsService.get().selectedCompanyId;
		return this.settingsService.isRxAppEnable(contactId, companyId);
	}

	postNoteToRx(noteData: { noteBody, loggedUser }): void {
		this.rxAppService.addNote({ content: noteData.noteBody, createdByName: noteData.loggedUser });
	}

	proceedOrderToModeling(orderInformation: IOrderInformation): void {
		this.rxAppService.proceedOrderToModeling(orderInformation);
	}

	proceedOrderToMilling(orderInformation: IOrderInformation): void {
		this.rxAppService.proceedOrderToMilling(orderInformation);
	}

	rescanOrder(rescanInformation: { emailAddress: string, rejectionReason: number, rejectionNote: string }): void {
		this.rxAppService.rescanOrder(rescanInformation);
	}

	getRxEnabledFlag(): RxEnabledData {
		const {selectedDoctorId: doctorId, selectedCompanyId: companyId} = this.globalSettingsService.get();

		const rxEnabledData = this.storage.getItem(Consts.Storage.RxEnabledData);
		if (rxEnabledData) {
			const data = JSON.parse(rxEnabledData) as RxEnabledData[];
			return data.find(d => d.companyId === companyId && d.doctorId === doctorId);
		}
		return null;
	}

	setRxEnabledFlag(rxEnabled: boolean) {
		const {selectedDoctorId: doctorId, selectedCompanyId: companyId} = this.globalSettingsService.get();

		const rxEnabledData = this.storage.getItem(Consts.Storage.RxEnabledData);

 		if (rxEnabledData) {
			const data = JSON.parse(rxEnabledData) as RxEnabledData[];
			data.push({
				doctorId,
				companyId,
				rxEnabled
			});
			this.storage.setItem(Consts.Storage.RxEnabledData, JSON.stringify(data));
		}
	}
}
