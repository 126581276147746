import { TimberService } from '../../services/logging/timber.service';
import { Injectable } from '@angular/core';

export interface ICSharpEventListener {
	eventRaised(eventName: string, eventArgs: string): void;
}

// ReSharper disable once InconsistentNaming
declare var CSharpEventListener: ICSharpEventListener;

export class BaseEventArgs {
	Message: string;
	Title: string;

	constructor(message: string, title: string = undefined) {
		this.Message = message;
		this.Title = title;
	}
}

@Injectable()
export class BaseEventsIntegrationService {
	constructor(private timberService: TimberService){}

	public raiseEvent(eventName: string, eventArgs: BaseEventArgs): void {
		this.timberService.info(`BaseEventsIntegration: raiseEvent ${eventName}`,
								{ module: 'BaseEventsIntegration' });
		if (this.propagateEventsUp()) {
			CSharpEventListener.eventRaised(eventName, JSON.stringify(eventArgs));
		}
	}

	public propagateEventsUp(): boolean {
		return typeof CSharpEventListener != 'undefined';
	}
	
	public raiseElementTouched(): void {
		if (this.propagateEventsUp()) {
			this.raiseEvent('ElementTouched', new BaseEventArgs('',''));
		}
	}

	public elementTouched(): void {
		this.raiseElementTouched();
	}
}
