import { DrowingToolMesageKeyType } from '../enums/drawing-tool-message-key-type';
import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EupRoutesService } from '@core/eupRoutes.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { PostMessageService } from '@shared/post-message.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalButton, ModalIcon, ModalKeys, NotificationService } from '@shared/notification/notification.service';
import { iif, Subject } from 'rxjs';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { CaptureSupportService } from '../../services/capture-support.service';
import { of } from 'rxjs';
import { DrawingToolConfig } from '../interfaces/drawing-tool-config';
import { DrawingToolConfigType } from '../enums/drawing-tool-config-type';

@Component({
	selector: 'eup-drawing-tool',
	templateUrl: './drawing-tool.component.html',
	styleUrls: ['./drawing-tool.component.scss'],
	providers: [PostMessageService],
})
export class DrawingToolComponent extends BaseDestroyable implements OnInit {
	private uploadIframe = new Subject<void>();

	@Input() orderId: string;
	@Input() capturedImageData: object;

	@Output() imageLoaded = new EventEmitter<object>();

	@ViewChild('drawingToolIframe', { static: false })
	drawingToolIframe: ElementRef<HTMLIFrameElement>;

	drawingToolUrl: string;
	constructor(
		private eupRoutesService: EupRoutesService,
		private postMessageService: PostMessageService,
		private translateService: TranslateService,
		private notificationService: NotificationService,
		private globalSettingsService: GlobalSettingsService,
		private captureSupportService: CaptureSupportService
	) {
		super();
	}

	ngOnInit() {
		this.drawingToolUrl = this.eupRoutesService.drawingToolUrl;
		this.subscribeIframeUpload();
	}

	uploadDrawingToolIframe() {
		if (this.drawingToolIframe) {
			this.uploadIframe.next();
		}
	}

	private subscribeIframeUpload() {
		this.uploadIframe
			.pipe(
				take(1),
				tap(() => this.postMessageService.sendToIFrame(this.drawingToolIframe.nativeElement, this.buildDrawingToolMessge())),
				switchMap(() => this.getMessagesFromDrawingToolIframe()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private buildDrawingToolMessge() {
		const fullScreenDataKey = this.captureSupportService.getFullScreenKey(this.capturedImageData);
		const fullScreenDataUrl = this.capturedImageData[fullScreenDataKey];
		const baseMessage = new Array<DrawingToolConfig<any>>(
			{
				type: DrawingToolConfigType.ToolbarHeight,
				data: 81,
			},
			{
				type: DrawingToolConfigType.Opacity,
				data: 0.5,
			},
			{
				type: DrawingToolConfigType.Language,
				data: this.globalSettingsService.get().selectedLanguage.code,
			},
			{
				type: DrawingToolConfigType.ImageSrc,
				data: fullScreenDataUrl,
			}
		);

		return baseMessage;
	}

	private getMessagesFromDrawingToolIframe() {
		return this.postMessageService
			.getMessages$<any>(this.drawingToolIframe.nativeElement.id, this.eupRoutesService.drawingToolUrl)
			.pipe(switchMap((data) => this.handleMessagesFromDrawingToolIframe(data)));
	}

	private handleMessagesFromDrawingToolIframe(data) {
		switch (data.key) {
			case DrowingToolMesageKeyType.DiscardChanges:
				return this.discardChanges();
			case DrowingToolMesageKeyType.UploadImage:
				return this.uploadImage(data.data);
			default:
				return of(null);
		}
	}
	private uploadImage(data: any) {
		const saveImage$ = of({}).pipe(
			map((_) => {
				const capturedImage = { ...this.capturedImageData };
				const fullScreenKey = this.captureSupportService.getFullScreenKey(capturedImage);
				capturedImage[fullScreenKey] = data.image;
				return capturedImage;
			}),
			tap((capturedImage) => this.imageLoaded.next(capturedImage))
		);

		return iif(() => this.captureSupportService.isScreenShotsDataValid(data), saveImage$, this.captureSupportService.showError(data));
	}

	private discardChanges() {
		return this.notificationService
			.show(
				this.translateService.instant('DrawingTool.DiscardChangesTitle'),
				this.translateService.instant('DrawingTool.DiscardChangesText'),
				{
					buttonCombination: ModalKeys.OkCancel,
					icon: ModalIcon.Error,
				}
			)
			.pipe(
				take(1),
				filter((response) => response.buttonPressed === ModalButton.Ok),
				tap((_) => this.imageLoaded.next({ ...this.capturedImageData }))
			);
	}
}
