import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { ErrorsModule } from './errors/errors.module';
import { NotificationComponent } from './shared/notification/notification.component';
import { NotificationService } from './shared/notification/notification.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCaseUrlSerializerService } from './core/lowerCaseUrlSerializer.service';
import { NanobarService } from './core/nanobar.service';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { DownloadFileService } from './shared/downloadFile.service';
import { SoftwareOptionsService } from './core/softwareOptions.service';
import { RxService } from './rx/services/rx.service';
import { ModalIframeService } from './services/ModalIframe/modalIframe.service';
import { LabRxExtensionService } from './rx/labRxExtension/labRxExtension.service';
import { OrdersService } from './doctors/orders/orders.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EupToastService } from './core/toastr/eupToast.service';
import { FeaturesToggleSettingsService } from './featuresToggleSettings/service/featuresToggleSettings.service';
import { FeaturesToggleSettingsGuard } from './featuresToggleSettings/featuresToggleSettingsGuard.service';
import { CacheManagementService } from './cacheManagement/service/cacheManagement.service';
import { CacheManagementGuard } from './cacheManagement/cacheManagementGuard.service';
import { ErrorsDemoGuard } from './errors/errorsDemo/errorsDemoGuard.service';
import { AppConfigService } from './services/appConfig/appConfigService';
import { FeatureAvailabilityService } from './services/featureAvailability/featureAvailability.service';
import { RealTimeUpdateInitializerService } from './services/real-time-notifacations/real-time-update-initializer/real-time-update-initializer.service';
import { ErrorHandlerService } from './errors/errorHandler.service';
import { RecoverPasswordService } from './recoverPassword/services/recoverPassword.service';
import { ScreenshotToastComponent } from './core/screenshotToast/screenshotToast.component';
import { RxAppSdkService } from './rx/services/rx-app-sdk.service';
import { RxVersionService } from './rx/services/rx-version.service';
import { GooglePlaceModule } from '@gotruster/ngx-google-places-autocomplete';
import { FeatureToggleStore } from './featuresToggleSettings/state/feature-toggle-store';
import { FeatureToggleQuery } from './featuresToggleSettings/state/feature-toggle-query';
import { AuthInterceptorService } from './services/interceptor/auth.interceptor.service';
import { ShellContextService } from './services/shell-context/shell-context.service';
import { ShellQuery } from '@shared/store/shell.query';
import { ShellStore } from '@shared/store/shell.store';
import { ShellCommunicationService } from './services/shell-communication/shell-communication.service';
import {ShellErrorEvent} from './services/shell-communication/events/shell-error-event';
import {ShellGetContextEvent} from './services/shell-communication/events/shell-get-context-event';
import {ShellTokenExpiredEvent} from './services/shell-communication/events/shell-token-expired-event';
import {ShellLanguageChangedEvent} from "./services/shell-communication/events/shell-language-changed-event";
import {ShellCompanyChangedEvent} from "./services/shell-communication/events/shell-company-changed-event";
import { PlatformCommunicationService } from './platform/services/platform-communication/platform-communication.service';
import { GetAppsConfigEvent } from "@shared/communicationEvents/platformEvents/get-apps-config-event";
import { CommunicationService } from './services/client-communication/communication.service';
import { LoggingInitializerService } from '@logging/logging-initializer.service';
import { provideAuthAngularInterceptor, provideAuthAngularServices } from '@itero/auth-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppUpdater } from '@shared/appUpdater.service';
import { InternalDebugComponent } from './debug/internal-debug.component';

@NgModule({
	imports: [
		BrowserModule,
		RouterModule,
		CoreModule,
		ErrorsModule,
		AppRoutes,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		ProgressbarModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot(),
		TranslateModule,
		CommonModule,
		BrowserAnimationsModule,
		InternalDebugComponent,
		GooglePlaceModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: !isDevMode(),
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  })
	],
	declarations: [AppComponent, NotificationComponent, ScreenshotToastComponent],
	providers: [
		NotificationService,
		LabRxExtensionService,
		RxService,
		RxAppSdkService,
		ModalIframeService,
		DownloadNotificationService,
		DownloadFileService,
		SoftwareOptionsService,
		RecoverPasswordService,
		FeaturesToggleSettingsService,
		FeatureToggleStore,
		FeatureToggleQuery,
		FeaturesToggleSettingsGuard,
		CacheManagementService,
		CacheManagementGuard,
		ErrorsDemoGuard,
		EupToastService,
		RxVersionService,
		provideAuthAngularInterceptor,
		provideAuthAngularServices,
		ShellStore,
		ShellQuery,
		ShellContextService,
		ShellCommunicationService,
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializerService
		},
		OrdersService,
		NanobarService,
		FeatureAvailabilityService,
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService
		},
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [AppConfigService, Injector],
			useFactory: (appConfigService: AppConfigService, injector: Injector) => {
				return () => {
					return appConfigService
						.loadAppConfig()
						.then(() => {
							injector.get(RealTimeUpdateInitializerService).trackAuthentication();
						})
						.catch(console.error);
				};
			}
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		},
		ShellErrorEvent,
		ShellGetContextEvent,
		ShellTokenExpiredEvent,
		ShellLanguageChangedEvent,
		ShellCompanyChangedEvent,
		PlatformCommunicationService,
		GetAppsConfigEvent,
		CommunicationService,
		LoggingInitializerService,
		AppUpdater
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
