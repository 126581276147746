import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'eup-slide-toggle',
	templateUrl: './slideToggle.component.html',
	styleUrls: ['./slideToggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlideToggleComponent),
			multi: true
		}
	],
})
export class SlideToggleComponent implements ControlValueAccessor {

	@Input()
	set checked(value: boolean) {
		this._checked = value;
	}

	get checked(): boolean {
		return this._checked;
	}

	@Input()
	set disabled(value: boolean) {
		this._disabled = value;
	}

	get disabled() {
		return this._disabled;
	}

	@HostBinding('class.disabled') private _disabled = false;
	private _touched = false;
	@HostBinding('class.checked') private _checked = false;

	@Output()
	change = new EventEmitter<boolean>();

	clickHandler(): void {
		if (this._disabled) {
			return;
		}
		this.checked = !this.checked;

		this.markAsTouched();
		this.propagateChange(this.checked);
		this.change.emit(this.checked);
	}

	blurHandler(): void {
		if (this.disabled) {
			return;
		}

		this.markAsTouched();
	}

	markAsTouched(): void {
		if (!this._touched) {
			this.propagateTouched();
			this._touched = true;
		}
	}

	private propagateChange = (_: any) => {};
	private propagateTouched = () => {};

	writeValue(val: any): void {
		this.checked = val;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
