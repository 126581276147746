import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { WindowsApplicationState, createWindowsApplicationState } from './windows-application-state.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application-settings-state' })
export class WindowsApplicationStore extends Store<WindowsApplicationState> {
	constructor() {
		super(createWindowsApplicationState());
	}
}
