import { Component, Output, EventEmitter } from '@angular/core';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { Observable } from 'rxjs';
import { takeUntil, map, tap } from 'rxjs/operators';
import { FileInfo, FileInfoStatus } from '../downloadNotification/fileInfo.model';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { FeatureToggleSettings } from 'app/featuresToggleSettings/featuresToggleSettings.model';
import { FeatureToggle } from '@shared/enums';
import { BaseDestroyable } from '@core/base-destroyable';

@Component({
	selector: 'eup-export-downloads-progress-list',
	templateUrl: './exportDownloadsProgressList.component.html',
	styleUrls: [ './exportDownloadsProgressList.component.scss' ]
})
export class ExportDownloadsProgressListComponent extends BaseDestroyable {
	Status = FileInfoStatus;
	files$: Observable<FileInfo[]>;
	@Output() close: EventEmitter<any> = new EventEmitter();

	constructor(private notification: DownloadNotificationService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService) {
		
		super();
		this.featuresToggleSettingsService.getAll().pipe(
			takeUntil(this.componentAlive$),
			map((res: FeatureToggleSettings[]) => res.find(f => f.id === FeatureToggle.SubscribeExportFilesOnlyOnce)?.isActive === true),
			tap(subscribeExportFilesOnlyOnce => {
				if (subscribeExportFilesOnlyOnce) {
					this.files$ = notification.getFiles();
				} else {
					this.files$ = notification.getObservable();
				}
			})).subscribe();
	}

	cancel(file: FileInfo) {
		this.notification.removeFile(file);
	}

	onClose() {
		this.close.emit();
	}

	floor(num: number) {
		return Math.floor(num);
	}
}
