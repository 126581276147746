import { trigger, style, transition, animate, keyframes } from '@angular/animations';
import { SHOWANIMATIONNODRAWING, SHOWANIMATIONDRAWING } from './const';

export const ScreenShotAnimation = [
	trigger('screenShotAnimation', [
		transition(`* => ${SHOWANIMATIONNODRAWING}`, [
			animate(
				'0.15s ease-out',
				keyframes([
					style({ backgroundColor: 'rgba(255, 255, 255, 0.6)', offset: 0 }),
					style({ backgroundColor: 'rgba(255, 255, 255, 1)', offset: 1.0 }),
				])
			),
		]),
		transition(`* => ${SHOWANIMATIONDRAWING}`, [
			animate(
				'1s ease-out',
				keyframes([
					style({
						marginLeft: 0,
						marginBottom: 0,
						width: '100%',
						height: '100%',
						margin: 0,
						offset: 0,
					}),
					style({
						width: '294px',
						height: '153px',
						margin: '0 0 44px 40px',
						offset: 1.0,
					}),
				])
			),
		]),
	]),
	trigger('pencilIconAnimation', [transition(':enter', [style({ opacity: 0 }), animate('1.5s', style({ opacity: 1 }))])]),
	trigger('screenshotThumbnailAnimation', [
		transition(':enter', [
			animate(
				'1.2s',
				keyframes([
					style({
						opacity: 0,
						display: 'none',
						offset: 0,
					}),
					style({
						opacity: 0,
						offset: 0.2,
					}),
					style({
						opacity: 0.6,
						offset: 1.0,
					}),
				])
			),
		]),
	]),
];
