import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class RxAppiTeroServicesService {
    public proceedOrderToModelingSucceed: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public proceedOrderToMillingSucceed: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public rescanSucceed: Subject<any> = new Subject<any>();
}
