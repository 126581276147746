<div class="textarea-wrapper" [ngClass]="{'disabled': disabled}">
    <label class="textarea-label" *ngIf="label">{{label}}</label>
    <textarea class="textarea"
              type="text"
              [name]="inputName"
              [disabled]="disabled"
              [id]="id"
              [rows]="rows"
              [placeholder]="placeholder"
              [(ngModel)]="text"></textarea>
</div>