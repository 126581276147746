import {IShellEvent} from './IShellEvent';
import {Injectable} from '@angular/core';
import {NavigationInfo, ShellCommunicationEvents} from '@shared/generalInterfaces';
import { ApplicationNavigationService } from '../../../services/shell/application-navigation/application-navigation.service';
import { IOSimPlusIntegrationService } from 'app/services/iosim-plus-integration/iosim-plus-integration.service';
@Injectable({providedIn: 'root'})
export class OpenApplicationEvent implements IShellEvent {
	constructor(
		private applicationNavigationService: ApplicationNavigationService,
		private iOSimPlusIntegrationService: IOSimPlusIntegrationService
	) {
	}

	get eventName(): string {
		return ShellCommunicationEvents.OpenApplication;
	}

	handleCustomEvent(event: any): void {
		
		let transition = {
            navigatedFromAppId: event.data.navigatedFromAppId,
            urlFrom: event.data.urlFrom,
            navigatedToAppId: event.data.navigatedToAppId,
			appMetadata: event.data.appMetadata
        } as NavigationInfo;

		if (event.data.navigatedToAppId === 'iosim') {
			this.iOSimPlusIntegrationService.iosimPlusTransition(transition);
		} else {
			this.applicationNavigationService.pushNavigationTransition(transition);
			this.applicationNavigationService.navigate(transition);
		}
	}

	handlePostMessage(event): void {
	}
}
