import {IShellEvent} from './IShellEvent';
import {NavigationInfo, ShellCommunicationEvents} from '@shared/generalInterfaces';
import {Injectable} from '@angular/core';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';

@Injectable({providedIn: 'root'})
export class PushNavigationStateEvent implements IShellEvent {

	constructor(private applicationNavigationService: ApplicationNavigationService) { }

	get eventName(): string {
		return ShellCommunicationEvents.PushNavigationState;
	}

	async handleCustomEvent(event: any) {
        let transition = {
            navigatedFromAppId: event.data.navigatedFromAppId,
            urlFrom: event.data.urlFrom,
            navigatedToAppId: event.data.navigatedToAppId
        } as NavigationInfo;
        this.applicationNavigationService.pushNavigationTransition(transition);
	}

	handlePostMessage(event): void {}
}
