import { SimulationStatusEnum, SimulationStatusProgress } from '@shared/iosim-plus/models/simulationStatus';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import { NowProvider } from '@shared/iosim-plus/iosim-plus-button/nowProvider';


export class ProgressState {
	private readonly baseMilliseconds = 1000;
	private readonly lowProgressThresholdInPercents = 10;
	private readonly upProgressThresholdInPercents = 90;
	private readonly fakeProgressDurationInMilliseconds = 1000;
	private readonly fullProgress = 100;
	private readonly calculationPeriod = 200;
	private readonly supportedState = SimulationStatusEnum.InProgress;

	private initialState: SimulationStatusProgress;
	private nowProvider: NowProvider;
	private tickTask: Subscription;

	private isOnShutdown = false;
	private shutdownTimeStamp: Moment = undefined;
	private progressOnShutdown: number = undefined;
	private startTime: Moment = undefined;

	private readonly progressChanged = new BehaviorSubject<number>(0);
	progressChanged$ = this.progressChanged.asObservable();

	private readonly release = new Subject<void>();
	release$ = this.release.asObservable();

	constructor(nowProvider: NowProvider) {
		this.nowProvider = nowProvider;
	}

	setProgress(initialState: SimulationStatusProgress) {
		if (initialState.simulationStatus !== this.supportedState) {
			throw new Error('Unsupported state');
		}
		this.initialState = initialState;
		if (!this.startTime) {
			this.startTime = this.nowProvider.convertFromString(this.initialState.startSimulationTime);
		}
	}

	startIfRequired() {
		if (this.tickTask) {
			return;
		}

		this.tickTask = interval(this.calculationPeriod).pipe(
			tap(_ => this.tick()),
			takeUntil(this.release$)
		).subscribe();
	}

	shutdown() {
		this.isOnShutdown = true;
		this.shutdownTimeStamp = this.nowProvider.now();
		this.progressOnShutdown = this.progressChanged.getValue();
	}

	reset() {
		this.release && this.release.next();
		this.isOnShutdown = false;
		this.shutdownTimeStamp = undefined;
		this.progressOnShutdown = undefined;
		this.tickTask = undefined;
		this.startTime = undefined;
	}

	private tick() {
		const progress = this.isOnShutdown ? this.getProgressByShutdown() : this.getNormalProgress();
		this.progressChanged.next(progress);

		if (progress >= this.fullProgress) {
			this.release.next();
		}
	}

	private getNormalProgress(): number {
		const now = this.nowProvider.now();
		const differenceInMilliseconds = now.diff(this.startTime, 'milliseconds');
		let progress: number;

		switch (true) {
			case (differenceInMilliseconds > this.baseMilliseconds * this.initialState.expectedDuration):
				progress = this.upProgressThresholdInPercents;
				break;
			case (differenceInMilliseconds < this.fakeProgressDurationInMilliseconds):
				progress = differenceInMilliseconds / this.fakeProgressDurationInMilliseconds * this.lowProgressThresholdInPercents;
				break;
			default:
				progress = this.lowProgressThresholdInPercents + (differenceInMilliseconds - this.fakeProgressDurationInMilliseconds)
					/ (this.initialState.expectedDuration * this.baseMilliseconds) * 100;
		}

		return Math.min(this.upProgressThresholdInPercents, Math.round(progress));
	}

	private getProgressByShutdown(): number {
		const now = this.nowProvider.now();

		const differenceInMilliseconds = now.diff(this.shutdownTimeStamp, 'milliseconds');
		const passedPartOfTime = differenceInMilliseconds / this.fakeProgressDurationInMilliseconds;

		const progress = this.progressOnShutdown + (this.fullProgress - this.progressOnShutdown) * passedPartOfTime;

		return Math.min(Math.round(progress), this.fullProgress);
	}
}
