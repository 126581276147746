import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable()
export class ModalIframeService {
	private currentModal: BsModalRef = null;
	constructor(
		private $uibModal: BsModalService,
		private location: PlatformLocation
		) {
		this.location.onPopState(() => this.closeModal());
	}

	openModal(template: any, initialState: any, windowClass: string): void {
		const config: ModalOptions =  {
			keyboard: false,
			backdrop  : 'static',
			class: windowClass,
			initialState: initialState
		};

		if (this.currentModal === null) {
			this.currentModal = this.$uibModal.show(template, config);
		}
	}

	closeModal(): void {
		if (!this.currentModal) { return; }
		this.currentModal.hide();
		this.currentModal = null;
	}
}
