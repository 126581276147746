import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// The main purpose of this class - provide approach to autmated unsubscribe Rx pipes when component or service destroys.
// F.E. you can use takeUntil(this.componentAlive$) operator in pipe.
// TODO: Add Angular decorator.
@Directive()
export class BaseDestroyable implements OnDestroy {

	private componentAlive = new Subject<void>();
	protected componentAlive$ = this.componentAlive.asObservable();

	ngOnDestroy(): void {
		this.componentAlive.next();
		this.componentAlive.complete();
	}
}
