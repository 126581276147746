import { Injectable } from '@angular/core';
import { Consts } from '@shared/consts';
import { ChangeTitlePayload } from '@shared/generalInterfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StickyHeaderService {
	titleChanged$!: BehaviorSubject<ChangeTitlePayload>;
	screenshotsEnabled$!: BehaviorSubject<boolean>;
	downloadScreenshotsEnabled$!: BehaviorSubject<boolean>;
	screenshotClicked$!: BehaviorSubject<boolean>;
	screenshotsResult$!: BehaviorSubject<any>;    

  constructor() {
    this.initSubjects();
  }

	initSubjects() {
		this.titleChanged$ = new BehaviorSubject<ChangeTitlePayload>({ title: '', logo: '', logoFallback: '' });
		this.screenshotsEnabled$ = new BehaviorSubject<boolean>(false);
		this.downloadScreenshotsEnabled$ = new BehaviorSubject<boolean>(false);
		this.screenshotClicked$ = new BehaviorSubject<boolean>(false);
		this.screenshotsResult$ = new BehaviorSubject<any>(null);    

	}

	triggerScreenshot() {
		this.screenshotClicked$.next(true);
	}

	resetLogo() {
		sessionStorage.setItem(Consts.Storage.Logo, JSON.stringify(''));
	}

	resetSubjects() {
		this.titleChanged$.next({ title: '', logo: '', logoFallback: '' });
		this.screenshotsEnabled$.next(false);
		this.downloadScreenshotsEnabled$.next(false);
		this.screenshotClicked$.next(false);
		this.screenshotsResult$.next(false);
	}

	callNext(propertyName: string, value: boolean) {
		if (this.hasOwnProperty(propertyName) && this[propertyName] instanceof BehaviorSubject) {
			(this[propertyName] as BehaviorSubject<boolean>).next(value);
		}
	}
}
