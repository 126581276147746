import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { ShellContextService } from '../../../services/shell-context/shell-context.service';
import { take } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ApplicationLoaded implements ICommunicationEvent {
	constructor(private shellContextService: ShellContextService) {}
	
	get eventName(): string {
		return PlatformCommunicationEvents.ApplicationLoaded;
	}

	async handleEvent(event: any, publishEventFunc: Function) {
		this.shellContextService.getContext().pipe(take(1))
			.subscribe(async context => {
				await publishEventFunc(PlatformCommunicationEvents.InitContext, 
					[event.eventHeader.publisher], context);
			});
	}
}