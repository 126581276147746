<div bsModal #changeCaseTypeModal="bs-modal" class="eup-modal modal fade top" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <button id="btn-hide-settings" type="button" class="close close-sign" (click)="hide(false)">
                        &times;
                    </button>
                    {{'Orders.ChangeCaseType' | translate }}
                </h3>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" *ngIf="availableCaseTypesListOptions">
                    <div class="form-group eup-dropdown">
                        <label class="col-sm-6 control-label">{{'Settings.Case_Type' | translate }}</label>
                        <div class="col-sm-6" *ngIf="availableCaseTypesListOptions.length === 1">
                            {{availableCaseTypesListOptions[0].value}}
                        </div>
                        <div class="col-sm-6" *ngIf="availableCaseTypesListOptions.length > 1">
                            <div dropdown class="dropdown-container">
                                <a dropdownToggle>{{selectedCaseTypeOption.value}}<span class="caret"></span></a>
                                <ul class="dropdown-menu" *dropdownMenu>
                                    <li *ngFor="let rowData of availableCaseTypesListOptions" (click)="updateSelectedCaseType(rowData.key)" value="{{rowData.key}}">{{rowData.value}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-lg btn-default" (click)="hide(false)">{{'Orders.Cancel' | translate }}</button>
                <button type="button" class="btn btn-lg btn-primary" (click)="changeCaseType()">{{'Orders.OK' | translate }}</button>
            </div>
        </div>
    </div>
</div>